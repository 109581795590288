.bg_col {
    background: #1A4790;
}
.img_1 img {
    max-width: 100%;
    margin-top: -101px;
}
.titl_1 h2 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 800;
    font-size: 76.11px;
    line-height: 85px;
    color: #F6C315;
}
.mod7_logo {
    background-color: #fff;
    width: 500px;
    height: 145px;
    position: relative;
    margin-bottom: 25px;
}
.mod7_logo img {
    margin: 0 auto;
    display: block;
    padding: 18px;
}
.mod7_logo::after {
    background: #ffffff;
    content: "";
    height: 145px;
    position: absolute;
    right: -41px;
    top: 0;
    transform: skew( -30deg);
    width: 81px;
}
.titl_1 p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 37.83px;
    line-height: 44px;
    color: #FFFFFF;
}
.road_def h2 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 800;
    font-size: 26.21px;
    line-height: 36px;
    color: #231F20;
    background-color: #fff;
    width: 400px;
    text-align: center;
    padding: 8px;
    margin: 20px 0;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
}
.road_def ul li {
    position: relative;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22.17px;
    line-height: 26px;
    color: #FFFFFF;
    padding: 0px 20px 28px 37px;
    list-style-type: none;
}
.road_def ul li:before{
    position: absolute;
    content: "";
    background-image: url("../../../../Images/module7/list_arrow.png");
    left: 0px;
    height: 20px;
    width: 20px;
    transform: rotate(
225deg);
    top: 5px;
}
.driver_beh_img img {
    max-width: 100%;
    padding: 80px 0;
}
.behav ul{
    list-style: none;
}
.behav ul li {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22.17px;
    line-height: 26px;
    color: #FFFFFF;
}
.behav ul li li.setUl {
    padding-left: 45px;
}
.behav ul li img {
    /* margin-top: 15px; */
    width: 50px;
}
.behav h2 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 800;
    font-size: 26.21px;
    line-height: 36px;
    color: #231F20;
    background-color: #fff;
    padding: 8px;
    margin: 20px 0;
    text-align: center;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
}
.behav ul li span {
    color: #F6C315;
}
.str ul li {
    position: relative;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22.17px;
    line-height: 31px;
    color: #414042;
    /* margin-bottom: 17px; */
    padding: 0px 20px 28px 37px;
    list-style-type: none;
}
.str ul li:before{
    position: absolute;
    content: "";
    background-image: url("../../../../Images/module7/list_arrow.png");
    left: 0px;
    height: 20px;
    width: 20px;
    transform: rotate(
225deg);
    top: 5px;
}
.str h2 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 800;
    font-size: 26.21px;
    line-height: 36px;
    color: #231F20;
    background: #F6C315;
    border-radius: 30px;
    text-align: center;
    padding: 9px;
    width: 500px;
    margin: -2rem auto 20px;
}
.agg_beh h2 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 800;
    font-size: 26.21px;
    line-height: 36px;
    color: #231F20;
    background: #F6C315;
    text-align: left;
    padding: 8px 8px 8px 35px;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    margin: 26px 0;
}
.agg_beh ul li {
    position: relative;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22.17px;
    line-height: 31px;
    color: #414042;
    /* margin-bottom: 17px; */
    padding: 0px 20px 28px 37px;
    list-style-type: none;
}
.agg_beh ul li:before{
    position: absolute;
    content: "";
    background-image: url("../../../../Images/module7/list_arrow.png");
    left: 0px;
    height: 20px;
    width: 20px;
    transform: rotate(
225deg);
    top: 5px;
}
.agg_beh img {
    max-width: 100%;
}
.sugge_sec_bg {
    background: #414042;
    padding: 26px 0;
    margin-top: 0px;
}
.sugge_sec_bg h2 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 800;
    font-size: 26.21px;
    line-height: 36px;
    color: #231F20;
    background: #F6C315;
    width: 500px;
    text-align: left;
    padding: 8px 8px 8px 35px;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    margin-bottom: 50px;
}
.sugge_sec_bg img {
    max-width: 100%;
    width: 110px;
    margin: 0 auto;
    display: block;
    transition-duration: 1s;
}
.law_traffic {
    transform: translatey(0px);
    animation: float 3s ease-in-out infinite;
  }
  @keyframes float {
    0% {
      transform: translatey(0px);
    }
    50% {
      transform: translatey(-15px);
    }
    100% {
      transform: translatey(0px);
    }
  }

.sugge_sec_bg p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22.17px;
    line-height: 31px;
    color: #FFFFFF;
    text-align: center;
    padding: 20px;
}
.penalties h2 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 800;
    font-size: 26.21px;
    line-height: 36px;
    color: #231F20;
    background: #F6C315;
    width: 500px;
    text-align: left;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    margin-top: 50px;
    padding: 8px 8px 8px 35px;
}
.penalties h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 50.21px;
    line-height: 59px;
    color: #414042;
}
.penalties img {
    max-width: 100%;
}
.penalties h4 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 26.21px;
    line-height: 31px;
    color: #414042;
    margin: 20px 0;
}
.penalties p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 43.51px;
    line-height: 51px;
    color: #414042;
}
.penalties h5 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 117.15px;
    line-height: 137px;
    color: #1A4790;
}

.penalties h6 {
    text-align: right;
}
.penalties {
    position: relative;
}
.penalties h6 {
    position: absolute;
    bottom: 0;
    right: 0;
}
.panel_law_img img {
    max-width: 100%;
}
.penal_law h2 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 800;
    font-size: 56.32px;
    line-height: 76px;
    color: #231F20;
    text-align: center;
    margin: 20px auto;
}
.penal_law_con h3 {
    font-family: Oswald;
    font-style: normal;
    font-weight: 800;
    font-size: 26.21px;
    line-height: 36px;
    color: #231F20;
    background: #F6C315;
    width: 400px;
    padding: 8px;
    text-align: center !important;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
}
.penal_law_con h5 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22.17px;
    line-height: 26px;
    color: #1A4790;
    padding: 20px 0;
}
.penal_law_con ul li {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22.17px;
    line-height: 31px;
    color: #414042;
    margin-bottom: 30px;
    list-style: none;
    position: relative;
}
.penal_law_con ul {
    padding: 0;
}
.timepara.card-body ul li::after {
    position: absolute;
    content: ".";
    background-image: url('../../../../Images/module7/list_arrow.png');
    background-repeat: no-repeat;
    color: transparent !important;
    background-size: contain;
    font-size: 50px;
    left: 0px;
    top: 0;
    margin-left: -1rem;
}
.penal_law_con .QuizCollapse .card-body {
    padding: 30px 30px 15px !important;
}
.penal_law_con .quizTabs.card-header h6 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22.17px;
    line-height: 26px;
    color: #1A4790;
}
.quizTabs.card-header {
    background-color: transparent;
    border: none;
}
.QuizCollapse.card {
    border: none;
}
.law_sec{
    background: #1A4790;
    padding: 20px 0;
}
.law_sec h2 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22.17px;
    line-height: 26px;
    color: #F6C315;
    text-align: left;
}
.fot_logo img {
    margin: 80px auto 30px;
    display: block;
    max-width: 100%;
}
.law_sec p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 22.17px;
    line-height: 26px;
    color: #FFFFFF;
}
.strategy_para_road{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20.17px;
    line-height: 31px;
    color: #414042;
}
.module7 .behaviour_rage{
    padding-left:20px
}
.module7 .behaviour_rage1{
    padding-left: 68px;
}

@media screen and (min-device-width: 50px) and (max-device-width: 767px) { 
    .titl_1 h2 {
        font-size: 32px;
        line-height: 45px;
    }
    .mod7_logo {
        width: 100%;
    }
    .mod7_logo::after {
        display: none;
    }
    .mod7_logo img {
        max-width: 100%;
        margin: 0;
    }
    .img_1 img{
        margin: 0;
    }
    .behav h2{
        font-size: 17px;
    }
    .road_def h2{
        width: 100%;
        font-size: 18px;
    }
    .behav ul {
        padding: 0;
    }
    li.setUl {
        padding-left: 45px;
    }
    .str h2{
        width: 100%;
        font-size: 21px;
    }
    .agg_beh h2{
        font-size: 21px;
    }
    .sugge_sec_bg{
        margin: 0;
    }
    .sugge_sec_bg h2{
        width: 100%;
        font-size: 21px;
    }
    .penalties h2{
        width: 100%;
        font-size: 21px;
    }
    .sugge_sec_bg img {
        width: 120px;
        margin: 0 auto;
        display: block;
    }
    .penalties h4 {
        font-size: 18px;
        text-align: center;
    }
    .penalties h3 {
        font-size: 28px;
        line-height: 40px;
    }
    .penalties p {
        font-size: 28px;
        line-height: 40px;
    }
    .penalties h5 {
        font-size: 55px;
        text-align: center;
    }
    .penal_law h2 {
        font-size: 28px;
        line-height: 40px;
    }
    .penal_law_con h3 {
        width: 100%;
        font-size: 21px;
    }
    .road_def ul {
        padding: 15px;
    }
    .road_def ul li {
        font-size: 16px;
        line-height: 31px;
    }
    .driver_beh_img img{
        padding: 0;
    }
    .behav ul li {
        font-size: 16px;
    }
    .str ul li {
        font-size: 16px;
        margin-bottom: 5px;
    }
    .agg_beh ul li {
        font-size: 16px;
    }
    .sugge_sec_bg p{
        font-size: 18px;
    }
    .law_sec p {
        font-size: 16px;
        line-height: 31px;
    }
    .penal_law_con .QuizCollapse .card-body {
        padding: 0 15px !important;
    }
    .penal_law_con .quizTabs.card-header h6 {
        font-size: 18px;
    }
    .penal_law_con ul li {
        font-size: 16px;
    }
}