@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rajdhani:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rajdhani:wght@400;500;600;700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Oswald:wght@200;300;400;500;600;700&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Rajdhani:wght@400;500;600;700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&display=swap");
.module3_course {
  background: #445771;
}

.module3_Cou .module3_intro {
  position: relative;
  background-image: url("../../../../Images/module3/1.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 363px;
}

.module3_Cou .module3_course .intro_img h3 {
  font-family: Oswald;
  font-style: normal;
  font-weight: 500;
  font-size: 69.14px;
  line-height: 102px;
  color: #ffffff;
  margin: 0;
}

.module3_Cou .module3_course .intro_img h2 {
  font-family: Oswald;
  font-style: normal;
  font-weight: 800;
  font-size: 93.34px;
  line-height: 127px;
  color: #f6c315;
  margin: 0;
}

.module3_Cou .module3_course .intro_img h4 {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  color: #ffffff;
  margin: 0;
  font-size: 44.35px;
  line-height: 51px;
}

.module3_Cou .module3_course .border_div2 {
  border-bottom: 1px solid #ffffff;
  z-index: 99;
  width: 250px;
}

.module3_Cou .common_head2 {
  width: 524px;
  height: 131px;
  background-color: #fff;
  padding: 11px 18px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  color: #414042;
  position: relative;
  margin: 15px 0;
  font-size: 44.87px;
  line-height: 52px;
}

.module3_Cou .common_head2:after {
  background: #fff;
  content: "";
  height: 131px;
  position: absolute;
  right: -41px;
  top: 0;
  transform: skew(30deg);
  width: 81px;
}

.module3_Cou .intro_img p {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 24.19px;
  line-height: 35px;
  color: #ffffff;
  width: 100%;
  max-width: 100%;
}

.module3_Cou .dri_con img {
  width: 100%;
}

.module3_Cou .dri_con h2 {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  line-height: 45px;
  color: #ffffff;
  margin: 0;
  text-align: right;
}

.module3_Cou .dri_con h3 {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 30.24px;
  line-height: 35px;
  color: #ffffff;
  padding: 16% 0;
}

.module3_Cou .OwTo_Sec h2 {
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 38.3px;
  line-height: 44px;
  color: #f6c315;
  text-align: center;
  padding: 7px;
  position: relative;
  margin-bottom: 31px;
  text-transform: capitalize;
}

.module3_Cou .OwTo_Sec h2::before {
  content: "";
  position: absolute;
  border-top: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  width: 300px;
  height: 15px;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.module3_Cou .OwTo_Sec h2::after {
  content: "";
  position: absolute;
  border-bottom: 1px solid white;
  border-left: 1px solid white;
  border-right: 1px solid white;
  width: 300px;
  height: 15px;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.module3_Cou .OwTo_Sec .car_img {
  width: 100%;
}

.module3_Cou .OwTo_Sec ul {
  list-style-image: url("../../../../Images/module3/dot.png");
  background-repeat: no-repeat;
}

.module3_Cou .OwTo_Sec ul li {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 50px;
  color: #ffffff;
}

.module3_Cou .module3_page2 {
  background-color: #fff;
  margin: 35px auto;
  /* padding: 35px 0; */
}

.module3_Cou .page2_con ul {
  list-style-image: url("../../../../Images/module3/dot.png");
  background-repeat: no-repeat;
  padding: 0;
}
.module3_Cou .common_head3 {
  width: 550px;
  height: 83px;
  background: #1a4790;
  color: #f6c315;
  padding: 15px 19px;
  font-family: Ubuntu;
  font-style: normal;
  position: relative;
  margin: 15px 0;
  font-weight: bold;
  font-size: 44.87px;
  line-height: 52px;
}
.module3_Cou .common_head3:after {
  background: #fff;
  content: "";
  height: 83px;
  position: absolute;
  right: -26px;
  top: 0;
  transform: skew(30deg);
  width: 55px;
}
.module3_Cou .page2_con img {
  width: 100%;
}
.module3_Cou .page2_con h2 {
  font-family: Ubuntu;
  font-style: normal;
  color: #1a4790;
  text-align: center;
  position: relative;
  font-weight: bold;
  font-size: 38.3px;
  line-height: 44px;
}
.module3_Cou .page2_con h2::before {
  content: "";
  position: absolute;
  border-top: 1px solid #414042;
  border-left: 1px solid #414042;
  border-right: 1px solid #414042;
  width: 150px;
  height: 15px;
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.module3_Cou .page2_con h2::after {
  content: "";
  position: absolute;
  border-bottom: 1px solid #414042;
  border-left: 1px solid #414042;
  border-right: 1px solid #414042;
  width: 150px;
  height: 15px;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.module3_Cou .page2_con span {
  font-family: Ubuntu;
  font-style: normal;
  color: #414042;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 24.19px;
  line-height: 35px;
}
.module3_Cou .page2_con ul li {
  font-family: Ubuntu;
  font-style: normal;
  color: #414042;
  margin-bottom: 15px;
  font-weight: 500;
  font-size: 24.19px;
  line-height: 35px;
}
.module3_Cou .common_head4 {
  width: 674px;
  height: 83px;
  background: #1a4790;
  color: #f6c315;
  padding: 15px 19px;
  font-family: Ubuntu;
  font-style: normal;
  position: relative;
  margin: 15px 0;
  font-weight: bold;
  font-size: 44.87px;
  line-height: 52px;
}
.module3_Cou .common_head4:after {
  background: #fff;
  content: "";
  height: 83px;
  position: absolute;
  right: -25px;
  top: 0;
  transform: skew(30deg);
  width: 53px;
}
.module3_Cou .page3_con h3 {
  font-family: Ubuntu;
  font-style: normal;
  color: #1a4790;
  margin: 19px auto;
  font-weight: bold;
  font-size: 28.22px;
  line-height: 32px;
}
.module3_Cou .page3_con p {
  /* background-color: yellow; */
  /* padding: 11px; */
  width: -moz-fit-content;
  width: fit-content;
  border-radius: 11px;
  font-family: Ubuntu;
  font-style: normal;
  color: #414042;
  margin-top: 0;
  font-weight: 500;
  font-size: 24.19px;
  line-height: 35px;
}
.module3_Cou .page3_con p.ays_wdth {
  width: 100%;
}
.module3_Cou .page3_con h4 {
  font-family: Ubuntu;
  font-style: normal;
  color: #414042;
  font-weight: 500;
  font-size: 24.19px;
  line-height: 35px;
}
.module3_Cou .dis_img img {
  max-width: 100%;
}

.module3_Cou .dis_con ul {
  list-style-image: url("../../../../Images/module3/dot.png");
  background-repeat: no-repeat;
  /* background-color: yellow; */
  padding: 25px 0px;
  border-radius: 31px;
  border-bottom-left-radius: 85px;
  border-top-left-radius: 85px;
  border-top-right-radius: 85px;
  border-bottom-right-radius: 85px;
}
.module3_Cou .dis_con ul li {
  font-family: Roboto;
  font-style: normal;
  color: #414042;
  font-weight: normal;
  font-size: 24.19px;
  line-height: 35px;
}
.module3_Cou .dis_con p {
  font-family: Ubuntu;
  font-style: normal;
  color: #414042;
  font-weight: 500;
  font-size: 24.19px;
  line-height: 35px;
}
.module3_Cou p.para {
  font-family: Ubuntu;
  font-style: normal;
  color: #414042;
  font-weight: 500;
  font-size: 24.19px;
  line-height: 28px;
}
.module3_Cou .module3_page4 {
  margin: 35px auto;
}

.module3_Cou .page4_bg {
  background-image: url("../../../../Images/module3/8.jpg");
  width: 100%;
  height: 589px;
  background-size: cover;
  background-repeat: no-repeat;
}
.module3_Cou .page4_bg p {
  font-family: Ubuntu;
  font-style: normal;
  color: #ffffff;
  padding: 25px;
  font-weight: 500;
  font-size: 24.19px;
  line-height: 35px;
}
.module3_Cou .dis_con.bg_t ul {
  background-color: transparent;
  padding: 0;
}
.module3_Cou .common_head5 {
  width: 875px;
  height: 83px;
  background: #1a4790;
  color: #f6c315;
  padding: 15px 19px;
  font-family: Ubuntu;
  font-style: normal;
  font-weight: bold;
  font-size: 44.87px;
  line-height: 52px;
  position: relative;
  margin: 15px 0;
}
.module3_Cou .common_head5:after {
  background: #fff;
  content: "";
  height: 83px;
  position: absolute;
  right: -25px;
  top: 0;
  transform: skew(30deg);
  width: 50px;
}
/* .module3_Cou .sec_bg {
    background-image: url('../../../../Images/module3/bg.png');
    width: 100%;
    height: 885px;
    background-repeat: no-repeat;
    background-size: cover;
} */
.sec_bg img {
  max-width: 100%;
  position: absolute;
  bottom: 0;
  width: 100%;
}
.module3_Cou .dis_con.bg_t {
  padding: 20px 25px;
}

.module3_Cou .main_img img {
  max-width: 95%;
}

.module3_Cou .law_traffic {
  transform: translatey(0px);
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-15px);
  }
  100% {
    transform: translatey(0px);
  }
}
.blind_spot_para {
  font-family: Ubuntu;
  font-style: normal;
  color: #414042;
  margin: 10px 0px 25px;
  font-weight: 500;
  font-size: 23.19px;
  line-height: 33px;
}

/* Media Quary Start */

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .sec_bg img {
    bottom: auto;
    top: 0;
  }
}

@media screen and (min-device-width: 50px) and (max-device-width: 767px) {
  .module3_Cou .module3_intro {
    height: 117px;
  }
  .module3_Cou .module3_intro img {
    display: none;
  }
  .module3_Cou .OwTo_Sec h2::before {
    width: 220px;
  }
  .module3_Cou .OwTo_Sec h2::after {
    width: 220px;
  }
  .module3_Cou .dri_con h2 {
    font-size: 19px;
    line-height: 19px;
  }
  .sec_bg img {
    position: inherit;
  }
  .module3_Cou .OwTo_Sec h2 {
    font-size: 19px;
    padding-top: 39px;
    line-height: 1.5;
  }
  .module3_Cou .OwTo_Sec ul {
    padding: 0;
  }
  .module3_Cou .OwTo_Sec {
    padding: 15px;
  }
  .module3_Cou .OwTo_Sec ul li {
    font-size: 19px;
    line-height: 31px;
  }
  .module3_Cou .page2_con ul li {
    font-size: 19px;
  }
  .module3_Cou .common_head5 {
    width: 320px;
    padding: 15px 15px;
    font-size: 19px;
    line-height: 31px;
  }
  .module3_Cou .common_head4 {
    width: 320px;
    padding: 15px 15px;
    font-size: 19px;
  }
  .module3_Cou .common_head3 {
    width: 320px;
    padding: 15px 15px;
    font-size: 19px;
  }
  /* .module3_Cou .dis_con ul {
        background-repeat: no-repeat;
        background-color: yellow;
        padding: 10px 30px;
        border-radius: 31px;
    } */
  .module3_Cou .page4_bg p {
    font-size: 14px;
    line-height: 20px;
    padding: 19px 0px;
  }
  .module3_Cou .sec_bg {
    background-image: none;
    background-color: yellow;
    width: 100%;
    height: auto;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .module3_Cou .module3_course .intro_img h3 {
    font-size: 35px;
    line-height: 50px;
  }
  .module3_Cou .module3_course .intro_img h2 {
    font-size: 55px;
    line-height: 70px;
  }
  .module3_Cou .common_head2 {
    width: 280px;
    height: 83px;
    font-size: 24px;
    line-height: 35px;
  }
  .module3_Cou .intro_img p {
    font-size: 18px;
    line-height: 28px;
  }
  .module3_Cou .dri_con h3 {
    font-size: 24px;
  }
  .module3_Cou .page3_con h3 {
    font-size: 18px;
    line-height: 25px;
  }
  .module3_Cou .page3_con p {
    font-size: 19px;
    line-height: 31px;
  }
  .module3_Cou .page3_con h4 {
    font-size: 19px;
    line-height: 31px;
  }
  .module3_Cou .dis_con ul li {
    font-size: 19px;
  }
  .module3_Cou .dis_con p {
    font-size: 19px;
    line-height: 31px;
  }
  .module3_Cou p.para {
    font-size: 19px;
  }
  .module3_Cou .page2_con h2 {
    font-size: 14px;
    line-height: 25px;
  }
  .module3_Cou .common_head2:after {
    display: none;
  }
}
@media screen and (min-device-width: 768px) and (max-device-width: 1023px) {
  .module3_Cou .module3_intro {
    height: 238px;
  }
  .module3_Cou .dri_con h2 {
    font-size: 25px;
  }
  .module3_Cou .sec_bg {
    height: auto;
    background-image: none;
    background-color: yellow;
  }
  .sec_bg img {
    position: relative;
  }
  .module3_Cou .common_head5 {
    width: 100%;
    height: 135px;
    font-size: 42.87px;
  }
  .module3_Cou .common_head5:after {
    height: 135px;
    right: -38px;
    width: 73px;
  }
}
