.forgotForm h1 {
  text-align: left;
  font: normal normal bold 31px/35px Source Sans Pro;
  letter-spacing: 0px;
  color: #001d92;
  opacity: 1;
}
.forgotForm p {
  text-align: left;
  font: normal normal normal 16px/20px Source Sans Pro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.forgotForm {
  margin: 23% auto 0px;
}
.forgotForm .login_btn a {
  color: #fff;
}
.forgotForm .form-control {
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  opacity: 1;
}
