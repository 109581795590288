.success_content{
    margin-top:100px;
}
.success_content.payment_container button {
    width: 329px;
}

/*survey modal*/
.course_complete.survey_modal .image img{
  opacity: 0.5;
  width: 34px;
  height: 34px;
  display: block;
  margin:auto;
}

.op-1{
  opacity: 1;
}

.course_complete.survey_modal .radio-img > input {
  display: none;
}
.course_complete.survey_modal .radio-img > .image {
  cursor: pointer;
}
.course_complete.survey_modal .radio-img > input:checked + .image span {
  color: #005cbf;
}
.course_complete.survey_modal .radio-img > input:checked + .image img {
  opacity: 1;
}
.course_complete.survey_modal .radio-img .survey img:hover {
  opacity: 1;
}

.course_complete.survey_modal p{
  font: normal normal normal 17px/22px Source Sans Pro;
  letter-spacing: 0px;
  color: #000000;
  margin-bottom: 0px;
}
.course_complete.survey_modal .form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin: 20px 13px;
  /* min-width: 50px; */
  text-align: center;
}
.course_complete.survey_modal .form-check .image span{
  font: normal normal normal 17px/23px Source Sans Pro;
  letter-spacing: 0px;
  color: #000000;
  text-align: center;
  display: block;
}
.course_complete.survey_modal .samecheckbox .custom-control{
    text-align: left;
}
.course_complete.survey_modal label{
    font: normal normal normal 17px/23px Source Sans Pro;
    letter-spacing: 0px;
    color: #000000;
    text-align: left;
    display: block;
  }