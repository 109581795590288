@font-face {
  font-family: Calibri;
  src: url("../../../../font/Calibri/Calibri.ttf");
}

.tabbmain_container {
  overflow-x: hidden;
}

.module9_page1 img {
  max-width: 100%;
}

.module9_page1 .some_pad {
  padding-top: 50px;
}

.module9_page1 .highway {
  background-color: #2a3740;
  padding: 30px;
}

.module9_page1 .highway h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 28px;
  color: #f6c315;
  margin-bottom: 20px;
}

.module9_page1 .highway h3 {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 27.77px;
  line-height: 34px;
  color: #ffffff;
  margin-bottom: 20px;
}

.module9_page1 .highway p {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 23.81px;
  line-height: 29px;
  color: #ffffff;
}

.module9_page1 .highway ul {
  padding: 0;
}

.module9_page1 .highway ul li {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 23.81px;
  line-height: 29px;
  color: #ffffff;
  margin-bottom: 30px;
}

.module9_page1 .youngest ul {
  padding: 0;
}

.module9_page1 .youngest p {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 24.08px;
  line-height: 29px;
  color: #414042;
}

.module9_page1 .youngest ul li {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 24.08px;
  line-height: 29px;
  color: #414042;
}

.module9_page1 .youngest h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 28px;
  color: #f6c315;
  margin: 30px 0;
}

.module9_page1 .youngest h3 {
  margin-bottom: 30px;
}

.module9_page1 .age_of_studt h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 28px;
  color: #1a4790;
  text-align: center;
}

.module9_page1 .age_of_studt img {
  max-width: 100%;
}

.module9_page1 .age_of_studt p {
  text-align: center;
}

.module9_page1 .right_of_way h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24.08px;
  line-height: 28px;
  color: #f6c315;
}

.module9_page1 .right_of_way h3 {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 28.09px;
  line-height: 34px;
  color: #414042;
}

.module9_page1 .right_of_way ul {
  padding: 0;
  margin: 30px 0;
}

.module9_page1 .right_of_way ul li {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 24.08px;
  line-height: 40px;
  color: #414042;
}

.module9_page1 .right_of_way img {
  max-width: 100%;
}

.module9_page1 .share_the_road h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 24.66px;
  line-height: 29px;
  color: #f6c315;
}

.module9_page1 .share_the_road img {
  max-width: 100%;
}

.module9_page1 .share_the_road p {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 24.66px;
  line-height: 31px;
  color: #414042;
}

.module9_page1 .fatalities img {
  margin-bottom: 30px;
}

.module9_page1 hr.hr_line {
  border: none;
  width: 100%;
  height: 2px;
  background-color: #fff;
  margin-bottom: 50px;
}

.module9_page1 .fatalities h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 29px;
  color: #f6c315;
}

.module9_page1 .fatalities p {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 24.66px;
  line-height: 30px;
  color: #414042;
}

.module9_page1 .fatalities ul li {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 24.66px;
  line-height: 30px;
  color: #414042;
  margin-bottom: 30px;
}

.module9_page1 .consequences h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 28px;
  color: #f6c315;
}

.module9_page1 .consequences p {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 29px;
  color: #414042;
}

.module9_page1 .consequences ul {
  padding: 0;
}

.module9_page1 .consequences ul li {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 23.75px;
  line-height: 29px;
  color: #414042;
  margin-bottom: 30px;
}

.module9_page1 .consequences img {
  width: 100%;
  max-width: 100%;
}

.module9_page1 .consequences h6 {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 17.81px;
  line-height: 22px;
  color: #ffffff;
  background: #6d6e71;
  padding: 9px;
}

.module9_page1 .vehicle_own p {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 23.75px;
  line-height: 29px;
  color: #414042;
}

.module9_page1 .vehicle_own ul li {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 23.75px;
  line-height: 29px;
  color: #414042;
  margin-bottom: 28px;
}

.module9_page1 .moto_safe_aw h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 28px;
  color: #f6c315;
}

.module9_page1 .moto_safe_aw p {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 23.89px;
  line-height: 29px;
  color: #414042;
}

.module9_page1 .moto_safe_aw {
  padding: 30px 0 0 0;
}

.module9_page1 .moto_safe_aw span {
  font-family: Calibri;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 32px;
  color: #414042;
}

.module9_page1 .moto_safe_aw h6 {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 26px;
  line-height: 32px;
  color: #414042;
  margin-top: 20px !important;
}

.module9_page1 .moto_safe_aw ul {
  margin-bottom: 30px;
}

.module9_page1 .moto_safe_aw ul li {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 23.89px;
  line-height: 29px;
  color: #414042;
  margin-bottom: 10px;
}

.module9_page1 img.bike_r {
  position: absolute;
  bottom: 0;
  z-index: 99999;
}

.module9_page1 .bg_bottom {
  background: #414042;
  width: 100%;
  height: 230px;
  margin-top: -30px;
  position: relative;
}

.module9_page1 .motor_cle {
  margin-top: 30px;
}

.module9_page1 .motor_cle ul {
  padding: 0;
}

.module9_page1 .motor_cle ul li {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 20.16px;
  line-height: 25px;
  color: #414042;
  margin-bottom: 25px;
}

.module9_page1 .motor_cle ul li span {
  font-weight: bold;
}

.module9_page1 .motor_cle img {
  max-width: 100%;
}

.module9_page1 .motor_cle2 {
  background: #414042;
  padding: 80px 30px;
  margin-top: -45px;
}

.module9_page1 .motor_cle2 ul li {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 24.19px;
  line-height: 30px;
  color: #ffffff;
  padding-bottom: 30px;
}

.module9_page1 .motor_cle2 ul li span {
  font-weight: bold;
}

.module9_page1 .dri_need p {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 24.08px;
  line-height: 29px;
  color: #414042;
}

.module9_page1 .dri_need img {
  max-width: 100%;
  padding-top: 105px;
}

.module9_page1 .dri_need h2 {
  font-family: Calibri;
  font-style: normal;
  font-weight: bold;
  font-size: 28.09px;
  line-height: 34px;
  color: #414042;
}

.module9_page1 .dri_need ul li {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 24.08px;
  line-height: 29px;
  color: #414042;
  padding-bottom: 28px;
}

.module9_page1 ul.ul_chg {
  list-style: none;
}

.module9_page1 ul.ul_chg li::before {
  content: "\2022";
  color: #0f273e;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.module9_page1 .rig_of_way {
  background: #414042;
  padding: 80px 50px;
  margin-top: -30px;
}

.module9_page1 .rig_of_way h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 42px;
  line-height: 50px;
  color: #f6c315;
  margin-bottom: 45px;
}

.module9_page1 .rig_of_way p {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 24.08px;
  line-height: 31px;
  color: #ffffff;
}

.module9_page1 .rig_of_way p span {
  font-weight: bold;
}

.module9_page1 .rig_of_way ul li {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 20.06px;
  line-height: 24px;
  color: #ffffff;
  padding-bottom: 25px;
}

.module9_page1 .rig_of_way h3 {
  font-family: Calibri;
  font-style: normal;
  font-weight: 600;
  font-size: 24.08px;
  line-height: 29px;
  color: #ffffff;
}

.module9_page1 .rig_of_way h6 {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 18.06px;
  line-height: 22px;
  color: #ffffff;
  background: #6d6e71;
  padding: 9px 45px;
  margin-bottom: 55px;
}

.module9_page1 .page9_con ul li {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
  list-style: none;
  margin-bottom: 27px;
}

.module9_page1 .page9_con p {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
}

.module9_page1 .page9_con ul {
  padding: 0;
}

.module9_page1 .page9_con p span {
  font-weight: bold;
}

.module9_page1 .page9_con ul li::before {
  content: "\2022";
  color: #f6c315;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.module9_page1 .page9_con h6 {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 25.12px;
  line-height: 31px;
  color: #ffffff;
  background: #6d6e71;
  padding: 5px 20px;
  margin: 30px 0;
}

.module9_page1 .page9_con h4 {
  font-weight: 600;
  font-size: 23.18px;
  margin-bottom: 20px;
}

.module9_page1 .train_stop {
  background-image: url("../../../../Images/Module9/train_stop.jpg");
  width: 100%;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: 460px;
}

.module9_page1 .page10_con.some_pad h2 {
  font-family: Calibri;
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  color: #414042;
}

.module9_page1 .page10_con.some_pad p {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 24.19px;
  line-height: 30px;
  color: #414042;
}

.module9_page1 .page10_con.some_pad ul li {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 24.19px;
  line-height: 30px;
  color: #414042;
  list-style: none;
  margin-bottom: 10px;
}

.module9_page1 .page10_con.some_pad ul li::before {
  content: "\2022";
  color: #f6c315;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.module9_page1 .page10_con.some_pad .box {
  border: 1px solid #231f20;
  padding: 10px 45px;
  border-radius: 35px;
  margin: 20px 0;
}

.module9_page1 .dis_driving {
  background: #1a4790;
  padding: 19px 0;
}

.module9_page1 .dis_driving h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  line-height: 28px;
  color: #f6c315;
  margin-bottom: 20px;
}

.module9_page1 .dis_driving h5 {
  font-family: Calibri;
  font-style: normal;
  font-weight: bold;
  font-size: 24.19px;
  line-height: 30px;
  color: #ffffff;
}

.module9_page1 .dis_driving p {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 21.19px;
  line-height: 30px;
  color: #ffffff;
}

.module9_page1 .dis_driving ul {
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
}

.module9_page1 .dis_driving ul li {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 24.19px;
  line-height: 30px;
  color: #ffffff;
  list-style: none;
}

.module9_page1 .dis_driving ul li::before {
  content: "\2022";
  color: #f6c315;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.module9_page1 .safe_tech_deve p {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 23.94px;
  line-height: 29px;
  color: #414042;
}

.module9_page1 .safe_tech_deve h2 {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 23.94px;
  line-height: 29px;
  color: #414042;
}

.module9_page1 .safe_tech_deve h3 {
  font-family: Calibri;
  font-style: normal;
  font-weight: bold;
  font-size: 28.09px;
  line-height: 29px;
  color: #414042;
}

.module9_page1 .safe_tech_deve h1 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 23.94px;
  line-height: 28px;
  color: #f6c315;
}

.module9_page1 .but_img img {
  max-width: 100%;
}

.module9_page1 .safe_tech_deve h2,
.module9_page1 .but_img h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 27px;
  color: #f6c315;
  margin-bottom: 30px;
  text-transform: uppercase;
}

.module9_page1 .but_img ul li {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 23.21px;
  line-height: 28px;
  color: #414042;
  margin-bottom: 30px;
}

.module9_page1 .higway_bg {
  background-image: url("../../../../Images/Module9/highway.jpg");
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 687px;
  background-position: 1px;
}

.module9_page1 .higway_bg h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 45.58px;
  line-height: 53px;
  color: #1a4790;
}

.module9_page1 .higway_bg h3 {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 41.31px;
  line-height: 50px;
  color: #414042;
}

.module9_page1 .higway_bg img {
  max-width: 100%;
  float: right;
}

.module9_page1 .higway_bg .law_traffic {
  transform: translatey(0px);
  animation: float 3s ease-in-out infinite;
}

.module9_page1 .safe_tech_deve.some_pad img {
  max-width: 100%;
}

.module9_page1 .train_img img {
  max-width: 100%;
  margin: 20px 0px;
}

.module9_page1 .be_aware h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 28px;
  color: #f6c315;
  text-transform: uppercase;
}

.module9_page1 .be_aware h3 {
  font-family: Calibri;
  font-style: normal;
  font-weight: bold;
  font-size: 28.09px;
  line-height: 34px;
  color: #414042;
}

.module9_page1 .be_aware ul li {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 23.89px;
  line-height: 29px;
  color: #414042;
  margin-bottom: 30px;
}

.module9_page1 .be_aware p {
  font-family: Calibri;
  font-style: normal;
  font-weight: normal;
  font-size: 23.89px;
  line-height: 29px;
  color: #414042;
}

.module9_page1 .be_aware span {
  font-family: Calibri;
  font-style: normal;
  font-weight: bold;
  font-size: 23.89px;
  line-height: 29px;
  color: #414042;
  text-transform: uppercase;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-15px);
  }

  100% {
    transform: translatey(0px);
  }
}

/* Media Quary Start */

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
  .SafetyTechnologySlider_h5p .slick-slide img {
    max-width: 100% !important;
  }
  .module9_page1 .higway_bg {
    height: 465px;
  }

  .module9_page1 .train_stop {
    height: 300px;
  }

  .highway_background {
    display: none;
  }
}

@media screen and (min-device-width: 50px) and (max-device-width: 767px) {
  .highway_background {
    display: none;
  }

  .module9_page1 .module3_Cou .module3_intro {
    height: 117px;
  }

  .module9_page1 .higway_bg h2 {
    font-size: 26px;
    line-height: 35px;
  }

  .module9_page1 .higway_bg h3 {
    font-size: 21px;
  }

  .module9_page1 .higway_bg {
    height: 450px;
  }

  .module9_page1 .highway h2 {
    font-size: 26px;
    line-height: 34px;
  }

  .module9_page1 .highway ul li {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .highway p {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .highway h3 {
    font-size: 21px;
    line-height: 25px;
  }

  .module9_page1 .youngest p {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .youngest ul li {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .youngest h2 {
    font-size: 26px;
    line-height: 31px;
  }

  .module9_page1 .youngest h3 {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .right_of_way h3 {
    font-size: 21px;
    line-height: 25px;
  }

  .module9_page1 .right_of_way ul li {
    font-size: 21px;
    line-height: 31px;
  }

  .module9_page1 .share_the_road p {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .fatalities h2 {
    font-size: 26px;
    line-height: 31px;
  }

  .module9_page1 .fatalities p {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .fatalities ul li {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .consequences p {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .consequences ul li {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .vehicle_own p {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .vehicle_own ul li {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .moto_safe_aw p {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .moto_safe_aw h2 {
    font-size: 26px;
    line-height: 31px;
  }

  .module9_page1 img.bike_r {
    position: inherit;
  }

  .module9_page1 .moto_safe_aw h6 {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .moto_safe_aw ul li {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .bg_bottom {
    display: none;
  }

  .module9_page1 .motor_cle ul li {
    font-size: 18px;
  }

  .module9_page1 .motor_cle2 ul {
    padding: 0;
  }

  .module9_page1 .motor_cle2 ul li {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .dri_need p {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .dri_need h2 {
    font-size: 26px;
    line-height: 31px;
  }

  .module9_page1 .dri_need ul li {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .rig_of_way {
    padding: 50px 0px;
  }

  .module9_page1 .rig_of_way h2 {
    font-size: 26px;
    line-height: 25px;
    margin-bottom: 15px;
  }

  .module9_page1 .rig_of_way p {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .rig_of_way h3 {
    font-size: 21px;
    line-height: 31px;
  }

  .module9_page1 .page9_con ul li {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .page9_con p {
    font-size: 23.18px;
    line-height: 28px;
  }

  .module9_page1 .page9_con h6 {
    font-size: 21px;
    line-height: 31px;
  }

  .module9_page1 .page10_con.some_pad h2 {
    font-size: 26px;
    line-height: 31px;
  }

  .module9_page1 .page10_con.some_pad p {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .page10_con.some_pad ul li {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .page10_con.some_pad .box {
    padding: 15px 15px;
  }

  .module9_page1 .dis_driving h3 {
    font-size: 21px;
  }

  .module9_page1 .dis_driving h5 {
    font-size: 21px;
  }

  .module9_page1 .dis_driving ul {
    gap: 1rem;
  }

  .module9_page1 .dis_driving ul li {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .dis_driving p {
    font-size: 18px;
    line-height: 30px;
  }

  .module9_page1 .safe_tech_deve p {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .safe_tech_deve h1 {
    font-size: 21px;
    line-height: 31px;
  }

  .module9_page1 .safe_tech_deve.some_pad h3 {
    font-size: 21px;
  }

  .module9_page1 .but_img h2 {
    font-size: 26px;
    line-height: 31px;
  }

  .module9_page1 .but_img ul li {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .train_stop {
    display: none;
  }

  .module9_page1 .page9_con ul {
    padding: revert;
  }

  .module9_page1 .be_aware h2 {
    font-size: 26px;
    line-height: 31px;
  }

  .module9_page1 .be_aware h3 {
    font-size: 21px;
  }

  .module9_page1 .be_aware ul li {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .be_aware p {
    font-size: 18px;
    line-height: 25px;
  }

  .module9_page1 .be_aware span {
    font-size: 18px;
    line-height: 25px;
  }
}

.h5p-iframe-module-9-part-1 iframe {
  width: 100%;
  height: 630px;
}

@media screen and (max-width: 425px) {
  .h5p-iframe-module-9-part-1 iframe {
    height: 270px;
  }
}

@media screen and (min-width: 426px) and (max-width: 460px) {
  .h5p-iframe-module-9-part-1 iframe {
    height: 1090px;
  }
}

@media screen and (min-width: 461px) and (max-width: 767px) {
  .h5p-iframe-module-9-part-1 iframe {
    height: 735px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .h5p-iframe-module-9-part-1 iframe {
    height: 420px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .h5p-iframe-module-9-part-1 iframe {
    height: 650px;
  }
}

.module9_page1 .safe_tech_deve h3 {
  line-height: 15px !important;
  margin-top: 40px !important;
}

@media screen and (max-width: 425px) {
  .SafetyTechnologySlider_h5p .slider-parent-buttons.slick-prev,
  .SafetyTechnologySlider_h5p.slider-parent-buttons.slick-next {
    top: 124% !important;
  }
}

.module-9.box {
  top: 0;
}

.SafetyTechnologySlider_h5p .slick-slide img {
  max-width: 100% !important;
}

.SafetyTechnologySlider_h5p {
  margin-bottom: 60px;
}
