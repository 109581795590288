
.gjs-one-bg{background-color:#153251}
.gjs-one-color{color:#153251}
.gjs-one-color-h:hover{color:#153251}
.gjs-two-bg{background-color:#f8f8f8}
.gjs-two-color{color:#f8f8f8}
.gjs-two-color-h:hover{color:#f8f8f8}
.gjs-three-bg{background-color:#153251}
.gjs-three-color{color:#153251}
.gjs-three-color-h:hover{color:#153251}
.gjs-four-bg{background-color:#4ba0f0}
.gjs-four-color{color:#4ba0f0}
.gjs-four-color-h:hover{color:#4ba0f0}
