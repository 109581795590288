.UserSecurityQuestion_container .custom-radio-wrap {
    /* padding: 20px; */
    margin-bottom: 20px;
}

.UserSecurityQuestion_container .custom-radio-wrap form .form-group {
	margin-bottom: 20px;
}

.UserSecurityQuestion_container .custom-radio-wrap form .form-group:last-child {
	margin-bottom: 0;
}

.UserSecurityQuestion_container .custom-radio-wrap form .form-group label {
	-webkit-appearance: none;
    background-color: #CBCBCB;
    border: none;
    box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px -15px 10px -12px rgba(0,0,0,0.05);
    padding: 8px;
    border-radius: 50px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
}

.UserSecurityQuestion_container .custom-radio-wrap form .form-group .label-text {
	vertical-align: middle;
	cursor: pointer;
    padding-left: 16px;
    margin-left: -5px;
    text-align: left;
    font: normal normal normal 20px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #7d7d7d !important;
    opacity: 1;


}
.UserSecurityQuestion_container .custom-radio-wrap{
    padding: 0 !important;
}

.UserSecurityQuestion_container .custom-radio-wrap form .form-group input {
	display: none;
	cursor: pointer;
}

.UserSecurityQuestion_container .custom-radio-wrap form .form-group input:checked + label {
    background-color: #255EAB;
    color: #255EAB;
    border: none; 
}
.custom-radio-wrap form .form-group input:checked ~ .label-text {
	text-align: left;
    font: normal normal normal 20px/25px Source Sans Pro;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.UserSecurityQuestion_container .custom-radio-wrap form .form-group input:checked + label:after {
	content: '';
  width: 14px;
  height: 14px;
  border-radius: 50px;
  position: absolute;
  top: 1px;
  left: 1px;
  background: #fff;
  box-shadow: inset 0px 0px 10px rgba(0,0,0,0.3);
  text-shadow: none;
  font-size: 32px;
  border: 1px solid #ccc;
}
.UserSecurityQuestion_container p{
    text-align: left;
font: normal normal normal 16px/23px Source Sans Pro;
letter-spacing: 0px;
color: #000000;
opacity: 0.6;
margin-bottom: 20px;
}
.UserSecurityQuestion_container h5{
    text-align: left;
font: normal normal 600 22px/28px Source Sans Pro;
letter-spacing: 0px;
color: #000000;
opacity: 1;
margin-top: 28px;
margin-bottom: 18px;
}