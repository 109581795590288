.forgotForm .otpPara {
  font: normal normal normal 14px/18px Source Sans Pro;
  letter-spacing: 0px;
  color: #00000080;
}
.forgotForm .otpPara span {
  font: normal normal bold 14px/18px Source Sans Pro;
  letter-spacing: 0px;
  color: #000000;
}
.forgotForm .resend {
  font: normal normal normal 14px/18px Source Sans Pro;
  letter-spacing: 0px;
  color: #484848;
}
.resend span {
  color: #4ba0f0;
  cursor: pointer;
}
.inputStyleOtp input {
  width: 50px !important;
  margin: 0 1rem 0 0;
  font-size: 2rem;
  height: 50px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cbcbcb;
  border-radius: 2px;
  opacity: 1;
}
