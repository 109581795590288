.tabbmain_container {
  overflow: hidden;
}

.mdu5_page1 h2 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 42.33px;
  line-height: 63px;
  color: #1a4790;
  text-align: center;
  margin: 0;
}

.mdu5_page1 h3 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 84.37px;
  line-height: 125px;
  color: #414042;
  text-align: center !important;
  margin: 0;
}

.mdu5_page1 img {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.mdu5_page1 h4 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 28.22px;
  line-height: 50px;
  color: #ffffff;
  background: #1a4790;
  width: 280px;
  height: 50px;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 15px;
}

.mdu5_page1 ul li {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 31px;
  color: #414042;
  padding-bottom: 27px;
}

.mdu5_page1 ul {
  padding: 0;
  list-style: none;
}

.mdu5_page1 ul li::before {
  content: "\2022";
  font-size: 31px;
  color: #1a4790;
  font-weight: bold;
  display: inline-block;
  width: 18px;
  height: 12px;
  margin-left: -18px;
  vertical-align: sub;
}

.mdu5_page2 h2 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 28.22px;
  line-height: 45px;
  color: #ffffff;
  text-align: center;
  background: #1a4790;
  width: 280px;
  height: 50px;
  margin: 0 auto;
  margin-top: 50px;
  margin-bottom: 8px;
}

.mdu5_page2 p {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 28.22px;
  line-height: 42px;
  color: #414042;
  text-align: center;
}

.mdu5_page2 h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 26.21px;
  line-height: 37px;
  color: #ffffff;
  background: #1a4790;
  width: 262px;
  height: 39px;
  text-align: center !important;
}

.mdu5_page2 img {
  max-width: 100%;
}

.mdu5_page2 .QuizCollapse.card {
  background-color: transparent;
  border: none;
}

.mdu5_page2 .quizTabs.card-header {
  background-color: transparent;
  border: none;
}

.mdu5_page2 .QuizCollapse.card h6 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 26px;
  color: #231f20;
}

.mdu5_page2 .QuizCollapse.card ul {
  padding: 0;
  list-style: none;
}

.mdu5_page2 .QuizCollapse.card ul li {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20.16px;
  line-height: 31px;
  color: #414042;
  padding-bottom: 9px;
}

.mdu5_page2 .QuizCollapse.card ul li::before {
  content: "\2022";
  font-size: 31px;
  color: #1a4790;
  font-weight: bold;
  display: inline-block;
  width: 18px;
  height: 12px;
  margin-left: -18px;
  vertical-align: sub;
}

.rem_sec {
  background-image: url("../../../../Images/module5/Rem_bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 679px;
}

.rem_sec h4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 26px;
  color: #f6c315;
  padding-top: 15px;
}

.rem_sec p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 31px;
  color: #ffffff;
  text-align: left;
}

.slide-item {
  max-width: 100%;
}

.mdu5_page3 img {
  max-width: 100%;
  margin-top: -150px;
}

.mdu5_page3 img.dri_exp {
  margin: 0;
}

.mdu5_page3 h2 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 28.22px;
  line-height: 45px;
  color: #ffffff;
  background: #1a4790;
  width: 420px;
  height: 50px;
  text-align: center;
  margin: 50px auto;
}

.ent_sec h4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 22.17px;
  margin-top: 15px;

  line-height: 26px;
  color: #231f20;
}

.ent_sec ul {
  padding: 0;
  list-style: none;
}

.ent_sec ul li {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 31px;
  color: #414042;
  margin-bottom: 25px;
}

.ent_sec ul li::before {
  content: "\2022";
  font-size: 31px;
  color: #1a4790;
  font-weight: bold;
  display: inline-block;
  width: 18px;
  height: 12px;
  margin-left: -18px;
  vertical-align: sub;
}

.way_con h3 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 28.22px;
  line-height: 45px;
  color: #ffffff;
  background: #1a4790;
  width: 420px;
  height: 50px;
  text-align: center !important;
  margin: 0 auto;
  margin-top: 85px;
  margin-bottom: 25px;
}

.back_up h3 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 28.22px;
  line-height: 45px;
  color: #ffffff;
  background: #1a4790;
  width: 560px;
  height: 50px;
  text-align: center !important;
  margin: 0 auto;
  margin-top: 55px;
  margin-bottom: 31px;
}

.way_con p {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 22.17px;
  line-height: 26px;
  color: #231f20;
  text-align: center;
  margin: 0;
  margin-bottom: 33px;
}

.way_con ul {
  list-style: none;
  padding: 0;
}

.way_con ul li::before {
  content: "\2022";
  font-size: 31px;
  color: #1a4790;
  font-weight: bold;
  display: inline-block;
  width: 18px;
  height: 12px;
  margin-left: -18px;
  vertical-align: sub;
}

.way_con ul li {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20.16px;
  line-height: 29px;
  color: #414042;
  margin-bottom: 20px;
}

.back_up ul {
  list-style: none;
  padding: 0;
}

.back_up ul li {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20.16px;
  line-height: 24px;
  color: #414042;
  margin-bottom: 20px;
}

.back_up ul li::before {
  content: "\2022";
  font-size: 31px;
  color: #1a4790;
  font-weight: bold;
  display: inline-block;
  width: 18px;
  height: 12px;
  margin-left: -18px;
  vertical-align: sub;
}

.back_up p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 29px;
  color: #ffffff;
  background: #414042;
  width: 100%;
  padding: 15px 30px;
  margin: 60px auto;
}

.mdu5_page4 h2 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 28.22px;
  line-height: 48px;
  color: #ffffff;
  background: #1a4790;
  width: 420px;
  height: 50px;
  text-align: center;
  margin: 35px auto;
}

.p4_box {
  background: #414042;
  color: #fff;
  width: 100%;
  height: 230px;
  margin-bottom: 23px;
}

.p4_box p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 26px;
  text-align: center;
  padding: 12px 22px;
}

.scl_bus img {
  max-width: 100%;
  margin: 0 auto;
}

.mdu5_page4 .timepara.card-body ul {
  padding: 0;
  list-style: none;
}

.mdu5_page4 .quizTabs.card-header h6 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 22.17px;
  line-height: 26px;
  color: #231f20;
}

.mdu5_page4 .timepara.card-body ul li {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20.16px;
  line-height: 31px;
  color: #414042;
  padding-bottom: 15px;
}

.mdu5_page4 .timepara.card-body ul li::before {
  content: "\2022";
  font-size: 31px;
  color: #1a4790;
  font-weight: bold;
  display: inline-block;
  width: 18px;
  height: 12px;
  margin-left: -18px;
  vertical-align: sub;
}

.mdu5_page4 .QuizCollapse.card {
  border: none;
}

.mdu5_page4 .quizTabs.card-header {
  background: transparent;
  border: none;
}

.scl_bus p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 31px;
  color: #414042;
  margin-top: 15px;
}

.rile_des h4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 26px;
  color: #f6c315;
}

.rile_des p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20.16px;
  line-height: 24px;
  color: #ffffff;
}

.rile_des {
  background: #414042;
  padding: 10px 20px;
}

.tra_light h2 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 28.22px;
  line-height: 50px;
  color: #ffffff;
  background: #1a4790;
  width: 280px;
  height: 50px;
  text-align: center;
  margin: 50px auto;
}

.tra_light p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 26px;
  color: #414042;
  margin-bottom: 20px;
}

.steady_red h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 26.21px;
  line-height: 41px;
  color: #414042;
  background: #f6c315;
  width: 200px;
  height: 40px;
  text-align: center;
}

.tra_lig_img img {
  max-width: 100%;
  margin-top: -166px;
  height: 348px;
  width: 100%;
  object-fit: cover;
}

.maintra_Div {
  background: #707175;
  width: 100%;
  height: auto;
  padding: 36px 0 70px 70px;
}

.steady_red p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 31px;
  color: #ffffff;
}

.tra_para p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 31px;
  color: #ffffff;
}

.tra_light .tra_signs {
  align-items: center;
}

.tra_light .tra_signs .tra_sign_block {
  align-items: center;
}

.tra_light .tra_signs .tra_sign_block .tra_sign_header {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 22px;
}

.tra_light .tra_signs .tra_sign_block .tra_sign_header span {
  color: #1a4790;
}

.tra_light .tra_signs .tra_sign_block p {
  padding: 1rem 0 2rem 0;
  border-bottom: 3px solid black;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20.17px;
  line-height: 26px;
}

.flashing_div {
  padding: 35px 0;
}

.flashing_div img {
  max-width: 100%;
  padding: 70px 0;
}

.flashing_div p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  color: #414042;
  margin-bottom: 30px;
}

.flashing_div p span {
  color: #1a4790;
  font-weight: 500;
}

.flashing_div p span.red {
  color: red;
}

.flashing_div p span.yellow {
  color: #eccb13;
}

.flashing_div p span.green {
  color: green;
}

.mdu5_page6 p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20.16px;
  line-height: 31px;
  color: #414042;
}

.mdu5_page6 h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 22.17px;
  line-height: 26px;
  color: #231f20;
}

.mdu5_page6 h4 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 22.17px;
  line-height: 26px;
  color: #1a4790;
}

.mdu5_page6 img {
  max-width: 100%;
}

.mdu5_page6 .tra_officer img {
  max-width: 100%;
}

.under_con img {
  max-width: 100%;
}

.mdu5_page7 h2.w_zone_tit {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 28.22px;
  line-height: 45px;
  color: #ffffff;
  background: #1a4790;
  width: 280px;
  height: 50px;
  margin: 50px auto;
  text-align: center;
}

.work_zone h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 22.17px;
  line-height: 26px;
  color: #231f20;
}

.work_zone p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20.16px;
  line-height: 31px;
  color: #414042;
}

.work_zone p span {
  color: #1a4790;
  font-weight: 500;
  font-size: 26px;
}

.safe_zone h2 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 28.22px;
  line-height: 45px;
  color: #ffffff;
  background: #1a4790;
  width: 400px;
  height: 50px;
  text-align: center;
  margin: 23px auto;
}

.safe_box {
  background: #58595b;
  padding: 10px 35px;
  margin-bottom: 17px;
}

.safe_box p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 19.16px;
  line-height: 25px;
  color: #ffffff;
}

.safe_box:first-child {
  height: 100px;
}

.safe_box:last-child {
  height: 120px;
}

.safe_zone p.more_w {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20.16px;
  line-height: 24px;
  color: #414042;
  text-align: center;
  margin-bottom: 23px;
}

.determine h2 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 28.22px;
  line-height: 50px;
  color: #ffffff;
  background: #1a4790;
  text-align: center;
  margin: 0 50px;
  margin-bottom: 32px;
}

.determine_box p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20.16px;
  line-height: 24px;
  color: #ffffff;
  padding: 19px 26px;
  height: 100px;
}

.speeding_con img {
  max-width: 100%;
}

.speeding_con h6 {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 22.17px;
  line-height: 31px;
  color: #231f20;
}

.speeding_con p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20.16px;
  line-height: 24px;
  color: #414042;
}

.determine_box {
  background: #58595b;
}

.twoSec_rule .quizTabs.card-header h6 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 26px;
  color: #231f20;
}

.twoSec_rule .timepara.card-body p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20.16px;
  line-height: 31px;
  color: #414042;
}

.twoSec_rule .quizTabs.card-header {
  border: none;
  background-color: transparent;
}

.twoSec_rule .QuizCollapse.card {
  border: none;
}

.way_user h2 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 28.22px;
  line-height: 42px;
  padding: 9px;
  text-align: center;
  color: #ffffff;
  background: #1a4790;
  margin: 50px auto;
}

.abillity {
  background: #414042;
  padding: 35px 80px;
}

.drowsiness h2 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 28.22px;
  line-height: 45px;
  color: #ffffff;
  /* background: #1a4790; */
  /* width: 470px; */
  /* padding: 10px; */
  /* height: 50px; */
  /* margin: 30px auto; */
  /* text-align: center; */
}

.drowsiness p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 31px;
  color: #414042;
}

.tips {
  margin-top: 35px;
}

.tips img {
  max-width: 100%;
}

.abillity h2 {
  font-family: Roboto;
  font-style: normal;
  font-weight: 600;
  font-size: 22.17px;
  line-height: 31px;
  color: #f6c315;
}

.abillity p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20.16px;
  line-height: 31px;
  color: #ffffff;
  margin-bottom: 25px;
  margin-left: 10px;
}

.tips h3 {
  font-family: Oswald;
  font-style: normal;
  font-weight: bold;
  font-size: 28.22px;
  line-height: 45px;
  color: #f6c315;
}

.tips h3 span {
  font-size: 22.17px;
  opacity: 1;
  color: #f6c315;
  font-weight: normal;
}

.officer_img img {
  width: 100%;
}

.mdu5_p10_con h2 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 28.22px;
  line-height: 42px;
  color: #231f20;
  margin-bottom: 17px;
}

.mdu5_p10_con h2 span {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 26px;
  color: #231f20;
}

.mdu5_p10_con p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20.16px;
  line-height: 31px;
  color: #414042;
  margin-bottom: 25px;
}

.mdu5_p10_con p span {
  color: #1a4790;
  font-weight: 500;
}

.m5_p10_sec2 h2 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 28.22px;
  line-height: 42px;
  color: #ffffff;
  background: #1a4790;
  text-align: center;
  padding: 10px;
}

.mdu5_p11_con h2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 28.22px;
  line-height: 42px;
  color: #231f20;
  margin-bottom: 17px;
}

.mdu5_p11_con p {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 20.16px;
  line-height: 31px;
  color: #414042;
  margin-bottom: 25px;
}

.mdu5_p11_con h6 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 16.13px;
  line-height: 19px;
  color: #414042;
}

.m5_p11_sec2 h2 {
  font-family: Oswald;
  font-style: normal;
  font-weight: normal;
  font-size: 28.22px;
  line-height: 42px;
  color: #ffffff;
  background: #1a4790;
  text-align: center;
  padding: 10px;
  margin: 50px auto;
}

.m5_p11_sec2 h3 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 28.22px;
  line-height: 42px;
  color: #231f20;
  margin-bottom: 17px;
}

.m5_p11_sec2 p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20.16px;
  line-height: 31px;
  color: #414042;
}

.m5_p11_sec2 img {
  max-width: 100%;
  margin-bottom: 50px;
}

.m5_p11_sec2 ul {
  padding: 0;
  list-style: none;
}

.m5_p11_sec2 ul li {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20.16px;
  line-height: 31px;
  color: #414042;
  padding-bottom: 25px;
}

.m5_p11_sec2 ul li::before {
  content: "\2022";
  font-size: 31px;
  color: #1a4790;
  font-weight: bold;
  display: inline-block;
  width: 18px;
  height: 12px;
  margin-left: -18px;
  vertical-align: sub;
}

.five_rea h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 26px;
  color: #231f20;
}

.five_rea h2 {
  background: #1a4790;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20.16px;
  line-height: 24px;
  color: #ffffff;
  padding: 15px;
}

.abo_safety {
  margin: 35px 0;
}

.abo_safety::before {
  content: "";
  background-image: url("../../../../Images/module5/seat_belt.png");
  background-size: 88%, 100%;
  background-repeat: no-repeat;
  position: absolute;
  width: 100%;
  height: 988px;
  margin: 0;
  background-position: bottom;
}

.abo_safety img {
  margin-top: 50px;
}

.abo_safety p span {
  color: #1a4790;
  font-weight: bold;
}

.abo_safety h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 26px;
  color: #231f20;
  margin-bottom: 31px;
}

.abo_safety p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20.16px;
  line-height: 31px;
  color: #414042;
}

.tra_light p.traffic_color_bg {
  background-color: #ffcc3d;
  text-align: center;
  width: 60%;
  margin: auto;
  padding: 20px;
  font-weight: bold !important;
}

.man_light_img img {
  max-width: 125%;
}

/* animation */

.flashing_div .law_traffic,
.under_con {
  transform: translatey(0px);
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-15px);
  }

  100% {
    transform: translatey(0px);
  }
}

.video_highway_para {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 31px;
  color: #414042;
}

/* Media Quary CSS */

@media screen and (min-device-width: 50px) and (max-device-width: 767px) {
  .mdu5_page1 h3 {
    font-size: 35px;
    line-height: 80px;
  }

  .mdu5_page1 ul li {
    font-size: 16px;
  }

  .mdu5_page1 h4 {
    font-size: 21px;
    width: 100%;
  }

  .mdu5_page2 .QuizCollapse.card h6 {
    font-size: 16px;
  }

  .ent_sec ul li {
    font-size: 16px;
  }

  .safe_box:last-child {
    height: auto;
  }

  .mdu5_page2 .QuizCollapse.card ul li {
    font-size: 16px;
  }

  .mdu5_page4 .timepara.card-body ul li {
    font-size: 16px;
  }

  .mdu5_page4 .timepara.card-body ul li {
    font-size: 16px;
  }

  .QuizCollapse .card-body {
    padding: 0;
  }

  .mdu5_page2 h2 {
    width: 100%;
    font-size: 21px;
  }

  .mdu5_page1 {
    padding: 0 15px;
  }

  .mdu5_page2 h3 {
    margin: 30px auto;
  }

  .rem_sec p {
    font-size: 14px;
    line-height: 21px;
  }

  .mdu5_page3 {
    padding: 0 15px;
  }

  .mdu5_page3 h2 {
    width: 100%;
    font-size: 22px;
    line-height: 50px;
    margin: 50px auto;
  }

  .mdu5_page3 img {
    margin: 0;
  }

  .way_con h3 {
    margin-top: 10px;
    margin-bottom: 30px;
    width: 100%;
    font-size: 21px;
  }

  .way_con p {
    text-align: left;
  }

  .back_up h3 {
    width: 100%;
    font-size: 21px;
  }

  .back_up p {
    font-size: 16px;
    margin: 30px auto;
  }

  .mdu5_page4 h2 {
    width: 100%;
    font-size: 21px;
  }

  .p4_box p {
    font-size: 16px;
  }

  .tra_lig_img img {
    margin: 0;
    height: auto;
  }

  .flashing_div p {
    font-size: 16px;
  }

  .mdu5_page6 p {
    font-size: 16px;
  }

  .safe_zone h2 {
    width: 100%;
    margin: 23px auto;
    font-size: 21px;
  }

  .determine_box p {
    height: auto !important;
    font-size: 16px;
  }

  .determine h2 {
    font-size: 21px;
    line-height: 31px;
    margin: 0 0px;
    margin-bottom: 32px;
  }

  .safe_box:first-child {
    height: auto;
  }

  .maintra_Div {
    padding: 36px 15px 40px 15px;
  }

  .mdu5_page4 .quizTabs.card-header h6 {
    font-size: 15px;
  }

  .quizTabs.card-header {
    padding: 12px 0;
  }

  .rile_des p {
    font-size: 16px;
    line-height: 31px;
  }

  .scl_bus p {
    font-size: 16px;
  }

  .tra_light h2 {
    font-size: 24px;
    width: 100%;
  }

  .tra_light p {
    font-size: 16px;
  }

  .steady_red h2 {
    font-size: 24px;
    width: 100%;
  }

  .steady_red p {
    font-size: 16px;
  }

  .tra_para p {
    font-size: 16px;
  }

  .mdu5_page7 h2.w_zone_tit {
    font-size: 21px;
    height: 50px;
  }

  .work_zone p span {
    font-size: 21px;
  }

  .work_zone p {
    font-size: 16px;
  }

  .safe_zone p.more_w {
    font-size: 16px;
  }

  .safe_box p {
    font-size: 21px;
  }

  .twoSec_rule .quizTabs.card-header h6 {
    font-size: 16px;
  }

  .speeding_con p {
    font-size: 16px;
  }

  .way_user h2 {
    font-size: 21px;
    line-height: 30px;
  }

  .drowsiness h2 {
    font-size: 21px;
    width: 100%;
  }

  .drowsiness p {
    font-size: 16px;
  }

  .abillity {
    padding: 15px 15px;
  }

  .mdu5_p10_con h2 {
    font-size: 21px;
  }

  .mdu5_p10_con p {
    font-size: 16px;
  }

  .m5_p10_sec2 h2 {
    font-size: 21px;
    line-height: 30px;
  }

  .mdu5_p11_con h2 {
    font-size: 20px;
    line-height: 30px;
  }

  .mdu5_p11_con p {
    font-size: 16px;
    line-height: 30px;
  }

  .mdu5_p11_con h6 {
    font-size: 13px;
  }

  .m5_p11_sec2 h2 {
    font-size: 21px;
    line-height: 30px;
  }

  .m5_p11_sec2 h3 {
    font-size: 21px;
    line-height: 30px;
  }

  .m5_p11_sec2 p {
    font-size: 16px;
  }

  .m5_p11_sec2 ul {
    padding: 15px;
  }

  .five_rea h3 {
    font-size: 21px;
  }

  .five_rea h2 {
    font-size: 16px;
  }

  .abo_safety::before {
    display: none;
  }

  .abo_safety p {
    font-size: 16px;
  }

  .tips h3 {
    font-size: 21px;
  }

  .tra_light p.traffic_color_bg {
    width: 100%;
  }
}

.h5p-iframe iframe {
  width: 100%;
  height: 575px;
}

.module-5-part-2 iframe {
  width: 100%;
  height: 650px;
}

@media (max-width: 1440px) {
  .h5p-iframe iframe {
    height: 580px;
  }

  .module-5-part-2 iframe {
    height: 615px;
  }
}

@media (max-width: 1024px) {
  .h5p-iframe iframe {
    height: 710px;
  }

  .module-5-part-2 iframe {
    height: 415px;
  }
}

@media (max-width: 767px) {
  .h5p-iframe iframe {
    height: 1080px;
  }

  .module-5-part-2 iframe {
    height: 1080px;
  }
}

@media (max-width: 460px) {
  .h5p-iframe iframe {
    height: 1090px;
  }

  .module-5-part-2 iframe {
    height: 1090px;
  }
}

@media (max-width: 425px) {
  .h5p-iframe iframe {
    height: 1140px;
  }

  .module-5-part-2 iframe {
    height: 240px;
  }
}

@media (min-width: 768px) {
  .col-md-1 {
    display: none;
  }
}

.progress {
  display: block;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  overflow: hidden;

  background-color: #f5f5f5;
  background-image: linear-gradient(to right, black, black);
  background-repeat: no-repeat;
  background-size: 0 100%;

  transition: background-size 0.4s ease-in-out;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 40px !important;
  line-height: 1 !important;
  opacity: 1 !important;
  color: black !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -55px !important;
}

.slick-next {
  right: -33px !important;
}

.slick-prev,
.slick-next {
  width: 38px;
  height: 39px;
}

.progress {
  background-color: #666666;
  background-image: linear-gradient(to right, #097ff2, #0a7ff2);
}

.current-slide {
  text-align: center;
  padding: 20px 0;
}

@media only screen and (max-width: 470px) {
  .module-5-part-2 {
    padding-bottom: 5%;
  }

  .slick-slide img {
    margin: auto !important;
    width: 98% !important;
  }

  .slider-parent-buttons.slick-prev,
  .slider-parent-buttons.slick-next {
    top: 124% !important;
  }

  .slider-parent-buttons.slick-prev {
    left: 40% !important;
    z-index: 1;
  }

  .slider-parent-buttons.slick-next {
    right: 40% !important;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 30px !important;
  }

  h3.h5p-content-note {
    margin-top: 40px;
  }
}

@media only screen and (max-width: 1024px) {
  .slick-slide img {
    max-width: 100% !important;
  }
}

@media only screen and (max-width: 1440px) {
  .slider-parent-buttons.slick-prev,
  .slider-parent-buttons.slick-next {
    top: 50%;
  }

  .slider-parent-buttons.slick-prev {
    left: 0;
    z-index: 1;
  }

  .slider-parent-buttons.slick-next {
    right: 0;
  }
}

.module-5-part-1 {
  h3 {
    font-size: 20px;
    color: red;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  li {
    padding: 10px;
    margin: 5px 0;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: grab;
  }

  .DropArea {
    padding: 0px !important;
  }

  .DropArea img {
    margin: 10px !important;
  }

  button {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  button:hover {
    background-color: #45a049;
  }

  button:disabled {
    background-color: #cccccc;
    cursor: not-allowed;
  }

  .droppable-section {
    width: 50%;
    display: flex;
    flex-wrap: wrap;
    background-color: rgb(211, 211, 211);
  }

  .draggable-section {
    width: 50%;
    background-color: lightgrey;
  }

  .Droppable li {
    max-width: 122px;
    width: 100% !important;
    max-height: fit-content;
  }

  .draggable-section ul {
    display: flex;
    flex-wrap: wrap;
  }

  .correct-area {
    position: relative;
  }

  .correct-area::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(29, 134, 29, 0.3);
    z-index: 1;
  }

  .incorrect-area {
    position: relative;
  }

  .incorrect-area::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 0, 0, 0.3);
    z-index: 1;
  }

  .disabled-icon {
    opacity: 0.5;
    filter: grayscale(100%);
    pointer-events: none;
  }

  @media (max-width: 500px) {
    .droppable-section,
    .draggable-section {
      justify-content: space-evenly;
      gap: 0;
    }
    .draggable-section ul {
      justify-content: space-evenly;
      gap: 0;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .droppable-section,
    .draggable-section {
      gap: 5px;
      justify-content: center;
    }
    .draggable-section ul {
      gap: 5px;
      justify-content: center;
    }
  }
  @media (max-width: 1024px) {
    .droppable-section,
    .draggable-section {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      background-color: rgb(211, 211, 211);
      flex-direction: row;
      gap: 5px;
      margin: 10px auto;
    }
    .draggable-section ul {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    }
    .h5p_content_navigate > div {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      width: 100%;
    }
    li.drop-area-image img,
    li.draggable-image img {
      height: 80px;
      width: 80px;
    }
  }
}
