/* @import url("https://fonts.googleapis.com/css2?family=Signika:wght@300;400;500;600;700&display=swap");

.module_font.module10_course {
  font-family: "Signika", sans-serif !important;
} */

.module10_course .course_recap_main_logo img {
  width: 250px;
}
.module10_course .course_recap_main_logo {
  transform: translatey(0px);
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-15px);
  }

  100% {
    transform: translatey(0px);
  }
}
.module10_course .course_purpose {
  border: 1px solid #414042;
  position: relative;
}
.module10_course .course_purpose .course_content_div {
  padding: 0px 20px;
}
.module10_course .course_purpose h1 {
  /* font-family: "Signika", sans-serif !important; */
  position: relative;
  font-weight: normal;
  font-size: 61.95px;
  line-height: 76px;
  color: #f6c215;
  text-transform: uppercase;
  background-repeat: no-repeat;
  background-image: url("../../../../Images/module10/borderimg.png");
  background-size: contain;
  margin: auto;
  height: 74px;
  width: 68%;
  margin-top: -4%;
}
.module10_course .course_purpose .module_number {
  font-weight: normal;
  font-size: 30.24px;
  line-height: 37px;
  color: #414042;
}
.module10_course .course_purpose .purpose_license_img img {
  max-width: 100%;
}

.module10_course .course_purpose .purpose_license_img {
  position: absolute;
  top: 0rem;
  /* width: 115%; */
  right: 1rem;
}

.module10_course .purpose_head {
  font-weight: 700;
  font-size: 30.24px;
  line-height: 37px;
  color: #1a4790;
  text-transform: uppercase;
}
.module10_course .course_purpose .license_purpose p {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 30px;
  color: #414042;
}
.module10_course .course_purpose .mission_div .col-12 {
  margin-bottom: 10px;
}
.module10_course .course_purpose .mission_div .mission_box {
  background: #1a4790;
  padding: 10px;
  text-align: center;
  height: 100%;
  align-items: center;
  align-self: center;
  display: flex;
  justify-content: center;
  border-radius: 20px;
}
.module10_course .course_purpose .mission_div .mission_box p {
  font-weight: 600;
  font-size: 24.19px;
  line-height: 30px;
  color: #ffffff;
  margin-bottom: 0px;
}
.module10_course ul li {
  position: relative;
  font-weight: normal;
  font-size: 24.19px;
  line-height: 30px;
  color: #414042;
  padding: 0px 0px 0px 30px;
  list-style-type: none;
  margin-bottom: 14px;
}
.module10_course ul li:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 10px;
  background: #1a4790;
  height: 12px;
  width: 12px;
  border-radius: 100px;
}
.module10_course .course_purpose .purpose_bg_img {
  position: relative;
  width: 100%;
  height: 354px;
  background-image: url("../../../../Images/module10/purposebg.png");
  background-size: cover;
}
.module10_course .transport_part {
  background: #1a4790;
  padding: 40px 20px;
}
.module10_course .transport_part .part_box img {
  width: 120px;
  height: 120px;
}
.module10_course .transport_part p {
  font-weight: normal;
  font-size: 30.24px;
  line-height: 37px;
  color: #ffffff;
}
.module10_course .transport_part .part_box_content h4 {
  font-weight: bold;
  font-size: 20.16px;
  line-height: 25px;
  color: #ffffff;
  text-transform: uppercase;
}
.module10_course .transport_part .part_box_content p {
  font-weight: normal;
  font-size: 20.16px;
  line-height: 25px;
  color: #ffffff;
}
.module10_course .transport_part .part_box_content {
  background: #0b2c66;
  border-radius: 10px;
  padding: 20px;
  height: 205px;
  margin-top: 10px;
}
.module10_course .rules_img img {
  max-width: 100%;
}
.module10_course .rules_road p {
  font-weight: 700;
  font-size: 30.24px;
  line-height: 37px;
  color: #414042;
}
.module10_course .rules_road span {
  font-weight: 700;
  font-size: 30.24px;
  line-height: 37px;
  color: #f6c315;
}
.module10_course .right_rules .border_rule {
  border-bottom: 0.5px solid #414042;
  padding: 10px 0px;
}
.module10_course .law_man {
  margin-top: -50px;
}
.module10_course .law_man img {
  max-width: 100%;
}
.module10_course .importance_driver {
  padding: 0px 20px;
}
.module10_course .skill_set {
  text-align: center;
  position: relative;
}
.module10_course .skill_set img {
  max-width: 100%;
}
.module10_course .skill_set .car_man {
  width: 218px;
  position: absolute;
  bottom: 0px;
  left: 0;
  right: 23px;
  text-align: center;
  margin: auto;
}
.module10_course .importance_driver p {
  font-weight: 700;
  font-size: 30.24px;
  line-height: 37px;
  color: #414042;
}
.module10_course .vision_img {
  text-align: center;
}
.module10_course .vision_img img {
  max-width: 100%;
}
.module10_course .attitude_driving {
  padding: 20px 20px;
  background: #414042;
  position: relative;
}
.module10_course .attitude_driving .attitude_para {
  font-weight: 700;
  font-size: 30.24px;
  line-height: 37px;
  color: #ffffff;
}
.module10_course .attitude_driving ul li {
  color: #fff;
}
.module10_course .attitude_driving ul li:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 10px;
  background: #fff;
  height: 12px;
  width: 12px;
  border-radius: 100px;
}
.module10_course .attitude_driving .white_border {
  border: 0.5px solid #ffffff;
  width: 70%;
}
.module10_course .attitude_driving h5 {
  font-weight: 700;
  font-size: 40.32px;
  line-height: 50px;
  color: #ffffff;
}
.module10_course .danger_img {
  position: absolute;
  overflow: hidden;
  width: 600px;
  right: 0px;
}
.module10_course .attitude_div {
  position: relative;
}
.module10_course .danger_img img {
  max-width: 100%;
}
.module10_course .complete_course {
  background-color: #f6c215;
  /* border: 0.5px solid #ffffff; */
  padding: 20px;
  margin: 50px 0px;
}
.module10_course .complete_course h4 {
  font-weight: 700;
  font-size: 30.24px;
  line-height: 37px;
  color: #414042;
}
.module10_course .driving_img img {
  max-width: 100%;
}
.module10_course .dmv_reporting img {
  max-width: 100%;
  padding: 70px 0;
}
.module10_course .dmv_reporting h2 {
  /* font-family: Signika; */
  font-style: normal;
  font-weight: normal;
  font-size: 30.24px;
  line-height: 37px;
  color: #414042;
}
.module10_course .dmv_reporting p {
  /* font-family: Signika; */
  font-style: normal;
  font-weight: normal;
  font-size: 24.19px;
  line-height: 31px;
  color: #414042;
}
.module10_course .next_step h2 {
  /* font-family: Signika; */
  font-style: normal;
  font-weight: normal;
  font-size: 30.24px;
  line-height: 37px;
  color: #414042;
}
.module10_course .next_step p {
  /* font-family: Signika; */
  font-style: normal;
  font-weight: normal;
  font-size: 24.19px;
  line-height: 30px;
  color: #414042;
}
.module10_course .next_step ul {
  padding: 0;
}
.module10_course .dmv_reporting h2,
.module10_course .next_step h2 {
  font-weight: bold;
}
.module10_course .dmv_reporting hr {
  border: 0.5px solid #414042;
  opacity: 0.1;
}
.module10_course .dmv_reporting {
  margin: 35px 0;
}
.module10_course ul li .strategy_action {
  font-weight: 600;
  font-size: 40.32px;
  line-height: 48px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  padding: 10px 10px 15px;
  border-radius: 10px;
  margin-top: 15px;
}
.module10_course ul li .strategy_action sub {
  bottom: 0px;
  font-weight: normal;
}
.module10_course .blue_bgcolor {
  background: #1a4790;
}
.module10_course .dark_bgcolor {
  background: #10336f;
}
.mission_div p {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 30px;
  color: #414042;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .module10_course .course_purpose h1 {
    width: 100%;
  }
  .module10_course .course_purpose .mission_div .mission_box p {
    font-size: 20.19px;
    line-height: 28px;
  }
  .module10_course .transport_part .part_box_content {
    height: 311px;
  }

  .module10_course .course_purpose .purpose_license_img {
    position: initial;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .module10_course .course_purpose {
    padding: 0px 0px;
  }
  .module10_course .course_purpose h1 {
    font-weight: bold;
    font-size: 24px;
    line-height: 31px;
    height: auto;
    width: 100%;
  }
  .module10_course .course_purpose .module_number {
    font-size: 24.24px;
    line-height: 35px;
  }
  .module10_course .purpose_head {
    font-size: 21px;
    line-height: 25px;
    margin: 15px 0;
  }
  .module10_course .course_purpose .license_purpose p {
    font-size: 18px;
    line-height: 25px;
  }
  .module10_course ul li {
    font-size: 18px;
    line-height: 25px;
  }
  .module10_course .transport_part p {
    font-size: 21px;
    line-height: 31px;
  }
  .module10_course .transport_part .part_box_content p {
    font-size: 18px;
  }
  .module10_course .part_box {
    margin-top: 15px;
  }
  .module10_course .law_man {
    margin-top: 0px;
  }
  .module10_course .importance_driver {
    padding: 0px 0px;
  }
  .module10_course .course_purpose .purpose_bg_img {
    height: 98px;
  }
  .module10_course .danger_img {
    position: inherit;
  }
  .module10_course .course_purpose .course_content_div {
    padding: 0px;
  }
  .module10_course .transport_part {
    padding: 40px 0px;
  }
  .module10_course .rules_road p {
    font-size: 21px;
    line-height: 31px;
  }
  .module10_course .importance_driver p {
    font-size: 21px;
    line-height: 31px;
  }
  .module10_course .attitude_driving .attitude_para {
    font-size: 21px;
    line-height: 31px;
  }
  .module10_course .attitude_driving {
    padding: 20px 0;
  }
  .module10_course .attitude_driving h5 {
    font-size: 24px;
    line-height: 31px;
  }
  .module10_course .complete_course h4 {
    font-size: 24px;
    line-height: 31px;
  }
  .module10_course .dmv_reporting h2 {
    font-size: 24px;
    line-height: 31px;
  }
  .module10_course .dmv_reporting p {
    font-size: 18px;
    line-height: 25px;
  }
  .module10_course .next_step h2 {
    font-size: 24px;
    line-height: 31px;
  }
  .module10_course .next_step p {
    font-size: 18px;
    line-height: 25px;
  }
  .module10_course .course_purpose .mission_div .mission_box p {
    font-size: 21px;
  }
}
