.navbar {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
}
.btnnone.btn-primary,
.btnnone.btn-primary:hover,
.btnnone.btn-primary:focus,
.btnnone.btn-primary:not(:disabled):not(.disabled).active,
.btnnone.btn-primary:not(:disabled):not(.disabled):active,
.show > .btnnone.btn-primary.dropdown-toggle {
  background: none;
  box-shadow: none;
  border: none !important;
  background-color: transparent;
  text-align: left;
  font: normal normal normal 13px/20px Source Sans Pro;
  letter-spacing: 0px;
  color: #4d4f5c;
  padding: 10px !important;
  box-shadow: none !important;
}
.btnnone.btn-primary:not(:disabled):not(.disabled).active,
.btnnone.btn-primary:not(:disabled):not(.disabled):active,
.show > .btnnone.btn-primary.dropdown-toggle {
  background: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
  color: #4d4f5c !important;
}
.btnnone.dropdown-toggle::after {
  border: none !important;
  background: url("/src/Images/header/small-down.svg");
  width: 11px;
  height: 7px;
  margin-left: 10px;
  vertical-align: 1px;
}
.leftBorder {
  position: relative;
}
.leftBorder:before {
  position: absolute;
  content: "|";
  left: -6px;
  color: #ebebf2;
  top: 13px;
  font-size: 19px;
}
.navbarNew .navbar-nav .dropdown-menu {
  position: absolute;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 0px 0px 5px 5px;
}
.navbarNew .navbar-nav .dropdown-menu .dropdown-item {
  padding: 6px 11px;
}
.navbarNew .navbar-nav .dropdown-item.active,
.navbarNew .navbar-nav .dropdown-item:active,
.navbarNew .navbar-nav .dropdown-item:focus {
  background-color: transparent;
  outline: none;
  box-shadow: none;
}
.navbarNew .navbar-nav .dropdown-menu .dropdown-item,
.navbarNew .navbar-nav .dropdown-menu .dropdown-item a {
  font: normal normal normal 14px/18px Source Sans Pro;
  letter-spacing: 0px;
  color: #0f273e;
  opacity: 1;
}
@media screen and (min-width: 768px) and (max-width: 991px) {
  .navbar-nav {
    width: auto;
    flex-direction: inherit !important;
  }
}
@media screen and (max-width: 991px) {
  .navbarNew .collapse:not(.show) {
    display: contents;
    align-items: flex-end;
    flex-grow: 0;
    flex-basis: auto;
    width: calc(100% - 52px);
  }
}

@media (min-width: 768px) {
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
}
@media screen and (max-width: 991px) {
  .navbar-toggler {
    display: none !important;
  }
  .logoNav {
    text-align: left;
    font-size: 20px;
    color: #000;
  }

  .leftBorder:before {
    display: none;
  }

  .PrivateHiddenCss-xsDown-10 {
    display: none;
  }
  .makeStyles-menuButton-4 {
    display: inline-block !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 991px) {
  .MuiAppBar-colorPrimary {
    right: -190% !important;
  }
}
@media screen and (max-width: 575px) {
  .MuiAppBar-colorPrimary {
    right: 13px !important;
  }
}
