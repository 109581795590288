.welcomeScreen .card-text.typingPara {
  font: normal normal normal 14px/18px Source Sans Pro;
  letter-spacing: 0px;
  color: #aeaeae;
  opacity: 1;
}
.welcomeScreen .card-text.typingPara1 {
  font: italic normal normal 14px/24px Source Sans Pro;
  letter-spacing: 0px;
  color: #100d01;
  opacity: 1;
}
.welcomeScreen .form-group textarea.form-control {
  height: auto;
}
