.module_font {
  font-family: "Titillium Web", sans-serif !important;
}

.module6_course {
  overflow: hidden;
}

.module6_course .module_risk .module_logo {
  width: 260px;
  overflow: hidden;
  position: absolute;
  top: 30px;
  left: 22%;
}

.module6_course .module_risk .module_logo img {
  max-width: 100%;
}

.module6_course .module_risk {
  position: relative;
  background-image: url("../../../../Images/module6/riskbg.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 600px;
  width: 100%;
  background-size: 100% 100%;
}

.module6_course .module_risk .risk_content {
  position: absolute;
  right: 30px;
  text-align: right;
  top: 14%;
}

.module6_course .module_risk .risk_content p {
  font-weight: bold;
  font-size: 37.21px;
  line-height: 45px;
  color: #f6c315;
  text-transform: uppercase;
}

.module6_course .module_risk .risk_content h1 {
  font-weight: bold;
  font-size: 81.84px;
  line-height: 80px;
  text-transform: uppercase;
  color: #ffffff;
  text-align: right;
}

.module6_course .module_risk_same {
  font-weight: bold;
  font-size: 44.35px;
  line-height: 53px;
  color: #0a2b63;
  text-transform: uppercase;
}

.border_head:before {
  position: absolute;
  content: "";
  background: #f6c315;
  height: 6px;
  width: 60px;
  top: -2px;
  left: 0px;
}

.border_head {
  display: block;
  position: relative;
  background-color: #0a2b63;
  width: 100%;
  height: 2px;
  margin-top: 4px;
}

.module6_course .module_risk_overview .border_head {
  width: 60%;
}

.module6_course ul li {
  position: relative;
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  margin-bottom: 13px;
  list-style-type: none;
  padding: 0px 0px 0px 30px;
  z-index: 1;
}


.module6_course ul li:before {
  position: absolute;
  content: "";
  background-repeat: no-repeat;
  background-size: contain;
  height: 15px;
  width: 15px;
  left: 0px;
  top: 7px;
}

.module6_course .before-star ul li:before {
  position: absolute;
  content: "✗";
  color: red;
  background-repeat: no-repeat;
  background-size: contain;
  height: 25px;
  width: 25px;
  left: 0px;
  background-image: none !important;
  top: 7px;
}

.module6_course .module_risk_overview {
  position: relative;
  margin-top: -5%;
}

.module6_course .module_risk_overview ul li:before {
  background-image: url("../../../../Images/module6/listbox.png");
}

.module6_course .module_risk_objective {
  position: relative;
  background-image: url("../../../../Images/module6/objectivebg.png");
  background-repeat: no-repeat;
  height: 820px;
  width: 100%;
  background-size: 100%;
  margin-top: -12%;
}

.module6_course .module_risk_objective .container {
  padding-top: 22%;
}

.module6_course .module_risk_objective .border_head {
  background-color: #fff;
}

.module6_course .module_risk_objective ul li:before {
  background-image: url("../../../../Images/module6/listbox1.png");
}

.module6_course .module_risk_objective_img img {
  max-width: 100%;
}

.module6_course .module_driving_affect {
  position: relative;
}

.module6_course .module_risk_overview_img {
  position: absolute;
  right: 0px;
  top: 0px;
  /* width: 500px; */
  overflow: hidden;
}

.module6_course .module_risk_overview_img img {
  max-width: 100%;
}

.module6_course .module_driving_affect_img {
  position: absolute;
  right: -5.5rem;
  top: -1rem;
  /* width: 42rem; */
  overflow: hidden;
}

.module6_course .module_driving_affect_img img {
  max-width: 100%;
}

.module6_course .module_driving_affect h2 {
  font-weight: bold;
  font-size: 36.29px;
  line-height: 44px;
  color: #0a2b63;
  text-transform: uppercase;
}

.module6_course .module_driving_affect ul li:before {
  background-image: url("../../../../Images/module6/listbox.png");
}

.module6_course .various_expression {
  position: relative;
  background: #bccada;
}

.module6_course .various_expression h2 {
  position: relative;
  font-weight: bold;
  font-size: 36.29px;
  line-height: 44px;
  color: #0a2b63;
  text-transform: uppercase;
  display: inline-block;
  padding: 0px 17px;
}

.module6_course .various_expression .various_img {
  max-width: 100%;
  width: 100px;
  vertical-align: inherit;
}

.module6_course .various_expression .expression_img {
  overflow: hidden;
  transition: all 0.4s ease-in;
}

.module6_course .various_expression .expression_img:hover {
  transform: scale(1.1);
}

.module6_course .various_expression .expression_img img {
  max-width: 100%;
}

.module6_course .various_expression .hammer_right {
  transform: rotate(-180deg);
}

.module6_course .influence_action {
  background-color: #00194c;
}

.module6_course .influence_action .influence_action_img {
  position: relative;
  overflow: hidden;
}

.module6_course .influence_action .action_content h2 {
  font-weight: bold;
  font-size: 36.29px;
  line-height: 44px;
  color: #ffffff;
  text-transform: uppercase;
}

.module6_course .influence_action .border_head {
  background-color: #fff;
}

.module6_course .influence_action ul li {
  color: #fff;
}

.module6_course .influence_action ul li:before {
  background-image: url("../../../../Images/module6/listbox1.png");
}

.module6_course .influence_action .influence_action_img img {
  max-width: 100%;
}

.module6_course .module_risk_listdiv {
  position: relative;
  background-image: url("../../../../Images/module6/listbg.jpg");
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: 100%;
}

.module6_course .attitude_img {
  max-width: 100%;
}

.module6_course .module_risk_listdiv ul li:before {
  background-image: url("../../../../Images/module6/listbox.png");
}

.module6_course .calculate_risk h4 {
  font-weight: bold;
  font-size: 36.29px;
  line-height: 44px;
  color: #0a2b63;
  text-transform: uppercase;
}

.module6_course .calculate_risk .calculate_content h6 {
  font-weight: bold;
  font-size: 24.19px;
  line-height: 29px;
  text-transform: uppercase;
  color: #0a2b63;
}

.module6_course .calculate_risk .calculate_content p {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #414042;
}

.module6_course .calculate_risk .calculate_content span {
  font-weight: bold;
  font-size: 24.19px;
  line-height: 29px;
  color: #414042;
}

.module6_course .calculate_risk .calculate_content ul li {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #414042;
}

.module6_course .calculate_risk .calculate_content ul li:before {
  background-image: url("../../../../Images/module6/listbox.png");
}

.module6_course .calculate_risk .calculate_content .drug_img {
  overflow: hidden;
}

.module6_course .calculate_risk .calculate_content .drug_img img {
  max-width: 100%;
}

.module6_course .risk_factor h5 {
  font-weight: bold;
  font-size: 24.19px;
  line-height: 29px;
  text-transform: uppercase;
  color: #0a2b63;
}

.module6_course .risk_factor_content {
  height: 100%;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: #0a2b63;
  padding: 10px;
  transition: all 0.5s ease-in-out;
}

.module6_course .risk_factor_content:hover {
  background: #f6c315;
}

.module6_course .risk_factor_content p {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #ffffff;
}

.module6_course .risk_factor ul li:before {
  background-image: url("../../../../Images/module6/listbox.png");
}

.module6_course .risk_factor_img img {
  max-width: 100%;
}

.module6_course .risk_factor .graduated_img img {
  max-width: 100%;
}

.module6_course .risk_factor h5 {
  font-weight: bold;
  font-size: 24.19px;
  line-height: 29px;
  color: #0a2b63;
  text-transform: uppercase;
}

.module6_course .risk_factor .risk_para {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #414042;
}

.module6_course .risk_factor .condition {
  font-size: 20px;
}

.module6_course .traffic_penalities p {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  margin: 30px 0;
}

.module6_course .traffic_penalities .violation_img {
  text-align: center;
  margin: 2rem 0;
}

.module6_course .violation_block {
  height: 100%;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  background: #0a2b63;
  padding: 10px;
  transition: all 0.5s ease-in-out;
}

.module6_course .violation_block:hover {
  background: #f6c315;
}

.module6_course .violation_block p {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #ffffff;
  margin: 10px 0;
}

.module6_course .restriction_para {
  font-weight: normal;
  font-size: 20.16px;
  line-height: 24px;
  color: #414042;
}

.module6_course .check_region {
  background: #414042;
  padding: 20px;
}

.module6_course .check_region .check_region_content_para {
  font-weight: normal;
  font-size: 26.21px;
  line-height: 31px;
  color: #ffffff;
}

.module6_course .check_region .check_region_content {
  font-weight: bold;
  font-size: 36.29px;
  line-height: 44px;
  color: #febe44;
}

.module6_course .check_region .check_region_img img {
  width: 100px;
}

.module6_course .check_region_img img {
  transform: translatey(0px);
  animation: float 3s ease-in-out infinite;
}

.module6_course .action_content {
  font-weight: bold;
  font-size: 36.29px;
  line-height: 44px;
  color: #ffffff;
  text-transform: uppercase;
}

.module6_course .drug_head {
  font-weight: bold;
  font-size: 36.29px;
  line-height: 44px;
  color: #0a2b63;
  text-transform: uppercase;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-15px);
  }

  100% {
    transform: translatey(0px);
  }
}

.module6_course .region_para {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #414042;
}

.module6_course .graduate_driver {
  position: relative;
}

.module6_course .graduate_driver .graduate_head_section {
  text-align: center;
  background-color: #414042;
  padding: 20px;
  margin-bottom: 20px;
}

.module6_course .graduate_driver .graduate_head_section h2 {
  font-weight: bold;
  font-size: 30.24px;
  line-height: 36px;
  color: #f6c315;
}

.module6_course .graduate_driver .graduate_head_section span {
  font-weight: 600;
  font-size: 24.19px;
  line-height: 29px;
  color: #ffffff;
  display: block;
}

.module6_course .graduate_driver h4 {
  font-weight: bold;
  font-size: 24.19px;
  line-height: 29px;
  color: #0a2b63;
}

.module6_course .graduate_driver ul li {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #414042;
  position: relative;
}

.module6_course .graduate_driver ul li:before {
  background-image: url("../../../../Images/module6/listbox.png");
}

.module6_course .graduate_driver ul li span {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #0a2b63;
  display: block;
}

.module6_course .graduate_driver .drive_img {
  position: absolute;
  width: 382px;
  overflow: hidden;
  right: 0px;
  top: 21%;
}

.module6_course .graduate_driver .drive_img img {
  max-width: 100%;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .module6_course .module_risk_objective {
    background-size: cover;
  }

  .module6_course .module_risk_objective .container {
    padding-top: 29%;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .module6_course .module_risk .module_logo {
    width: 180px;
  }

  .module6_course .module_risk {
    height: 500px;
  }

  .module6_course .module_risk .risk_content {
    top: 11%;
  }

  .module6_course .module_risk .risk_content h1 {
    font-size: 52.84px;
    line-height: 63px;
  }

  .module6_course .module_risk_overview_img {
    position: relative;
    margin: auto;
  }

  .module6_course .module_risk_objective {
    margin-top: -25%;
    background-size: cover;
  }

  .module6_course .module_risk_objective .container {
    padding-top: 39%;
  }

  .module6_course ul li {
    font-size: 20.19px;
    line-height: 24px;
  }

  .module6_course .module_driving_affect_img {
    position: relative;
    width: 500px;
    float: right;
    margin: auto;
  }

  .module6_course .module_risk_listdiv {
    background-position: center;
    background-size: cover;
  }

  .module6_course .graduate_driver .drive_img {
    width: 253px;
    top: 27%;
  }
}

@media screen and (max-width: 767px) {
  .module6_course .module_risk {
    background-position: bottom;
    height: 600px;
    background-size: contain;
    margin-bottom: 30px;
  }

  .module6_course .module_risk .module_logo {
    position: relative;
    width: 260px;
    overflow: hidden;
    top: 30px;
    left: 0;
    right: 0;
    margin: auto;
  }

  .module6_course .module_risk .risk_content {
    position: relative;
    right: 0;
    margin-top: 50px;
    text-align: center;
  }

  .module6_course .module_risk .risk_content h1 {
    font-size: 46.84px;
    line-height: 56px;
    color: #154f8b;
    text-align: center;
  }

  .module6_course .module_risk_same {
    font-size: 30.35px;
    line-height: 42px;
  }

  .module6_course ul li,
  .module6_course .calculate_risk .calculate_content ul li {
    font-size: 21.19px;
    line-height: 25px;
    margin-bottom: 18px;
  }

  .module6_course .module_risk_overview_img {
    position: relative;
    width: 400px;
  }

  .module6_course .module_risk_objective {
    height: 750px;
    background-size: cover;
    margin-top: 0px;
  }

  .module6_course .module_risk_objective .container {
    padding-top: 0px;
  }

  .module6_course .module_risk_objective h2 {
    padding-top: 30px;
  }

  .module6_course .module_driving_affect_img {
    position: relative;
  }

  .module6_course .module_driving_affect h2 {
    font-size: 30.29px;
    line-height: 44px;
  }

  .module6_course .various_expression h2 {
    font-size: 19.29px;
    line-height: 20px;
  }

  .module6_course .calculate_risk h4 {
    font-size: 30.29px;
    line-height: 40px;
  }

  .module6_course .calculate_risk .calculate_content p {
    font-size: 20.19px;
    line-height: 25px;
  }

  .module6_course .calculate_risk .calculate_content span {
    font-size: 17.19px;
    line-height: 25px;
  }

  .module6_course .drug_head {
    font-size: 30px;
  }

  .module6_course .risk_factor .risk_para {
    font-size: 20.19px;
    line-height: 25px;
  }

  .module6_course .check_region .check_region_content {
    font-size: 30.29px;
    line-height: 40px;
  }

  .module6_course .check_region .check_region_content_para {
    font-size: 21.21px;
    line-height: 28px;
  }

  .module6_course .module_risk_listdiv {
    background-position: center;
    background-size: cover;
  }

  .module6_course .graduate_driver .drive_img {
    position: relative;
  }

  .module6_course .graduate_driver .graduate_head_section h2 {
    font-size: 24.24px;
  }

  .law_traffic {
    display: none;
  }
}

.h5p-iframe-module-6 iframe {
  width: 100%;
  height: 575px;
}

.h5p-iframe-module-6-part-2 iframe {
  width: 100%;
  height: 677px;
}

@media screen and (max-width: 425px) {
  .h5p-iframe-module-6 iframe {
    height: 1170px;
  }

  .h5p-iframe-module-6-part-2 iframe {
    height: 340px;
  }
}

@media screen and (min-width: 426px) and (max-width: 460px) {
  .h5p-iframe-module-6 iframe {
    height: 1090px;
  }

  .h5p-iframe-module-6-part-2 iframe {
    height: 1090px;
  }
}

@media screen and (min-width: 461px) and (max-width: 767px) {
  .h5p-iframe-module-6 iframe {
    height: 735px;
  }

  .h5p-iframe-module-6-part-2 iframe {
    height: 735px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .h5p-iframe-module-6 iframe {
    height: 735px;
  }

  .h5p-iframe-module-6-part-2 iframe {
    height: 490px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .h5p-iframe-module-6 iframe {
    height: 490px;
  }

  .h5p-iframe-module-6-part-2 iframe {
    height: 710px;
  }
}

.h5p-module-6 {
  font-family: Arial, sans-serif;
}

.h5p-module-6 h4 {
  color: #333;
}

.options-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 10px;
}

.option-card {
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
}

.option-card:hover {
  background-color: #e0e0e0;
}

.option-card input[type="radio"] {
  display: none;
}

.option-card input[type="radio"]:checked + label {
  font-weight: bold;
}

.option-card label {
  display: block;
}

.h5p-module-6 button {
  background-color: #007bff;
  border-radius: 30px;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  margin-top: 10px;
}

.h5p-module-6 button:hover {
  background-color: #0056b3;
}

.h5p-module-6 p {
  margin-top: 10px;
}

/* Additional styles for correct and incorrect answers */
.option-div.correct {
  border-color: green;
  background-color: #90ee905c;
}

.option-div.incorrect {
  border-color: red;
  background-color: #ffb3b3;
}

/* new */
.option-div {
  padding: 10px;
  border: 2px solid grey;
  border-radius: 10px;
  margin: 12px 0;
}
.option-box {
  padding: 35px 10px 10px;
  background-color: #2d2c2c;
  display: flex;
  height: 100%;
}
.option-card {
  position: relative;
  background-color: grey;
  padding-top: 30px;
  padding: 32px 0 1px;
  text-align: center;
  border-radius: 0;
}
.h5p-module-6 span {
  position: absolute;
  left: 41%;
  top: -14px;
  background-color: grey;
  width: 40px;
  border-radius: 50%;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: 5px solid #2d2c2c;
}
.h5p-module-6 h2 {
  color: #ffa633;
}
.h5p-module-6 h3 {
  text-align: center !important;
  margin-bottom: 20px;
  font-weight: 800;
  color: white;
  margin-bottom: 0;
}
.option-card.active {
  background-color: white;
}

.content-heading {
  background-color: #2d2c2c;
  text-align: center;
  padding: 10px;
}
.h5p-module-6 p {
  margin: 18px 0;
  text-align: center;
  font-weight: 500;
  font-size: 18px;
}

.content-main-box {
  padding: 20px;
  background-color: white;
}
.cont-box {
  display: flex;
}
.cont-box hr {
  height: 4px !important;
  width: 30%;
  margin: auto;
  background-color: grey !important;
}

.h5p-content-note {
  color: red !important;
  font-size: 22px !important;
  font-weight: 600 !important;
}