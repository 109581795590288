body {
  position: relative;
}

.MuiDrawer-paperAnchorDockedLeft,
.MuiPaper-root.MuiDrawer-paper {
  border-right: 0px !important;
  background: #0f273e 0% 0% no-repeat padding-box !important;
}
.listtitle,
.listtitle .MuiTypography-body1 {
  font-family: "Source Sans Pro" !important;
  font: normal normal normal 15px/19px Source Sans Pro !important;
  letter-spacing: 0px;
  color: #ffffff;
}
.logo {
  float: left;
  width: 100%;
}
.logo h1 {
  text-align: center;
  font-size: 20px;
  color: #fff;
  margin-bottom: 0;
}
.logo img {
 height: 55px;
}
.MuiAppBar-colorPrimary {
  background: transparent !important;
  width: 30px !important;
  box-shadow: none !important;
  /* right: 13px !important; */
  top: -65px !important;
  position: absolute !important;
}
.MuiToolbar-gutters {
  padding: 0px !important;
}
.MuiToolbar-gutters .MuiSvgIcon-root {
  color: #0f273e;
  font-size: 30px;
}
.courses_dropdown button,
.courses_dropdown button:focus {
  background: none !important;
  border: none !important;
  width: 100%;
  box-shadow: none !important;
}
.courses_dropdown button:focus {
}
.courses_dropdown button li {
  padding: 0;
}
.sidebBar .dropdown-toggle::after {
  position: absolute;
  right: 15px;
  top: 13px;
  background: url("../../../Images/sidebar/down.svg");
  background-repeat: no-repeat;
  height: 8px;
  width: 15px;
  border: none;
}
.sidebBar .show.dropdown .dropdown-toggle::after {
  background: url("../../../Images/sidebar/up.svg");
  background-repeat: no-repeat;
}
.listmargin .listchild {
  margin-bottom: 25px;
  padding: 0px;
}
.listmargin .listchild .dropdown {
  width: 100%;
}

.listmargin .listchild .dropdown-menu {
  background: #000000 0% 0% no-repeat padding-box;
  width: 100%;
  position: relative !important;
  transform: inherit !important;
}
.listmargin .listchild .dropdown-menu .dropdown-item,
.listmargin .listchild .dropdown-menu .dropdown-item:hover {
  font: normal normal normal 15px/19px Source Sans Pro;
  letter-spacing: 0px;
  color: #ffffff !important;
  background-color: transparent !important;
  padding: 10px;
  padding-left: 29% !important;
  text-decoration: none !important;
}
a:hover {
  text-decoration: none !important;
}
.listchild .btn-primary:not(:disabled):not(.disabled).active,
.listchild .btn-primary:not(:disabled):not(.disabled):active,
.listchild .show > .btn-primary.dropdown-toggle {
  background: transparent !important;
  border-color: transparent !important;
  box-shadow: none !important;
}
@media screen and (min-width: 992px) {
  .MuiAppBar-colorPrimary {
    display: none !important;
  }
}

@media screen and (max-width: 767px) {
  .logo {
    display: none !important;
  }
}
