.avoid_skid iframe {
  height: 677px;
  width: 100%;
}

.module_font {
  font-family: "Titillium Web", sans-serif !important;
}

.module4_course {
  overflow: hidden;
}

.module4_course .same_heading {
  font-weight: bold;
  font-size: 35.29px;
  line-height: 44px;
  color: #10336f;
  text-transform: uppercase;
}

.module4_course .driver_skill {
  position: relative;
  background-image: url("../../../../Images/module4/skillbg.png");
  background-repeat: no-repeat;
  background-position: center;
  height: 800px;
  width: 100%;
  background-size: 100% 80%;
}

.module4_course .driver_skill .driver_skill_content {
  position: absolute;
  width: 450px;
  left: 0;
  right: 0;
  margin: auto;
  top: 17%;
}

.module4_course .driver_skill .driver_skill_content h1 {
  font-weight: bold;
  font-size: 66.52px;
  line-height: 62px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center;
  margin: 0px auto 12px;
}

.module4_course .driver_skill .driver_skill_content h1 span {
  font-weight: bold;
  font-size: 57.31px;
  line-height: 69px;
  color: #f6c315;
  display: block;
  text-transform: uppercase;
}

.module4_course .driver_skill .driver_skill_content p {
  font-weight: bold;
  font-size: 36.49px;
  line-height: 44px;
  color: #ffffff;
  border: 1px solid #ffffff;
  width: 220px;
  padding: 4px 12px;
  text-align: center;
  text-transform: uppercase;
  margin: 0px auto;
}

.module4_course .driver_skill .module_logo {
  position: absolute;
  bottom: 18%;
  width: 250px;
  left: 5%;
}

.module4_course .driver_skill .module_logo img {
  max-width: 100%;
}

.module4_course .ribbon_position {
  position: relative;
  margin: -9% 0px 20px;
}

.module4_course .ribbon_main {
  position: absolute;
  top: -10%;
  left: 0;
  right: 0;
  margin: auto;
  width: 37%;
  text-align: center;
}

.module4_course .ribbon {
  position: relative;
  background: #f6c315;
}

.module4_course .ribbon:before {
  border-color: #f6c315 #f6c315 #f6c315 transparent;
}

.module4_course .ribbon:after {
  border-color: #f6c315 transparent #f6c315 #f6c315;
}

.module4_course .ribbon:before,
.module4_course .ribbon:after {
  content: "";
  position: absolute;
  height: 0;
  width: 0;
  border-style: solid;
  border-width: 0;
  outline: none;
}

.module4_course .ribbon_bg:before,
.module4_course .ribbon_bg:after {
  top: 0px;
  z-index: 10;
  height: 44px;
}

.module4_course .ribbon_bg:before {
  border-color: #f6c315 #f6c315 #f6c315 transparent;
  left: -25px;
  border-width: 22px;
}

.module4_course .ribbon_bg:after {
  border-color: #f6c315 transparent #f6c315 #f6c315;
  right: -25px;
  border-width: 22px;
}

.module4_course .ribbon_bg h2 {
  font-weight: bold;
  font-size: 36.29px;
  line-height: 44px;
  color: #0b2c64;
  text-transform: uppercase;
}

.module4_course .strategy_content {
  position: relative;
}

.module4_course .strategy_content p {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #414042;
}

.module4_course .strategy_content .strategy_buttons .btn.btn_blue {
  cursor: default;
  background: #1a4790;
  font-weight: bold;
  font-size: 28.22px;
  line-height: 34px;
  color: #ffffff;
  text-transform: uppercase;
  width: 300px;
  margin: 0px 10px;
  height: 60px;
  padding: 0px;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
}

.module4_course .strategy_content .strategy_buttons .btn.btn_blue:hover {
  background: #f6c315;
}

.module4_course .strategy_content .strategy_buttons .btn.btn_blue span {
  font-size: 40.32px;
  line-height: 48px;
}

.module4_course
  .strategy_content
  .strategy_buttons
  .btn_dark_blue.btn.btn_blue {
  background: #10336f;
}

.module4_course
  .strategy_content
  .strategy_buttons
  .btn_dark_blue.btn.btn_blue:hover {
  background: #f6c315;
}

.module4_course .strategy_content .strategy_buttons {
  text-align: center;
}

.module4_course .vehicle_search {
  position: relative;
}

.module4_course .vehicle_search .search_car_img {
  position: absolute;
  width: 428px;
  overflow: hidden;
  z-index: 9;
  left: 2%;
  bottom: 0%;
}

.module4_course .vehicle_search .search_car_img img {
  max-width: 100%;
}

.module4_course .vehicle_search .search_content {
  position: relative;
  background-image: url("../../../../Images/module4/searchbg.png");
  background-repeat: no-repeat;
  padding: 26px 24px;
  height: 450px;
  margin-left: 8%;
  background-size: 100% 100%;
}

.module4_course .vehicle_search .search_content h3 {
  font-weight: bold;
  font-size: 36.29px;
  line-height: 44px;
  color: #f6c315;
  margin-bottom: 13px;
}

.module4_course .vehicle_search .search_content ul li {
  position: relative;
  list-style-type: none;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 27px;
  color: #ffffff;
  padding: 0px 30px 0px 26px;
  margin-bottom: 13px;
}

.module4_course .vehicle_search .search_content ul li:before {
  position: absolute;
  content: "";
  left: 5px;
  top: 10px;
  border-radius: 100px;
  height: 11px;
  width: 11px;
  border: 1px solid #f6c315;
  background: #f6c315;
}

.module4_course .factor_search {
  position: relative;
}

/* .module4_course .factor_search .factor_search_content h3 {
  font-weight: bold;
  font-size: 35.29px;
  line-height: 44px;
  color: #10336f;
  text-transform: uppercase;
} */
.module4_course .factor_search .factor_search_content span {
  font-weight: bold;
  font-size: 24.19px;
  line-height: 29px;
  color: #58595b;
}

.module4_course .factor_search .factor_search_content p {
  font-weight: normal;
  font-size: 20.16px;
  line-height: 24px;
  color: #58595b;
}

.module4_course .factor_search .factor_content_detail {
  background: #0b2c66;
  text-align: center;
  padding: 15px 10px;
  height: 4.5rem;
  align-items: center;
  display: flex;
  transition: all 0.5s ease-in-out;
  border-radius: 10px;
}

.module4_course .factor_search .factor_content .col-12 {
  margin-bottom: 10px;
}

.module4_course .factor_search .factor_content_detail:hover {
  background: #1a4790;
}

.module4_course .factor_search .factor_content_detail p {
  font-weight: normal;
  font-size: 20.16px;
  line-height: 24px;
  color: #ffffff;
  width: 100%;
}

.module4_course .factor_search .factor_search_img {
  position: absolute;
  right: 0px;
  width: 367px;
  overflow: hidden;
  top: 22px;
}

.module4_course .factor_search .factor_search_img img {
  max-width: 100%;
}

.module4_course .drive_evaluate .hazard_watch .col-12 {
  padding: 10px 13px;
}

.module4_course .drive_evaluate .hazard_watch .hazard_watch_content {
  background-color: #0b2c66;
  border-radius: 10px;
  text-align: center;
  padding: 15px 18px;
  height: 100%;
}

.module4_course .drive_evaluate .hazard_watch .row {
  padding: 0px 10px 20px;
}

.module4_course .drive_evaluate .hazard_watch h3 {
  font-weight: bold;
  font-size: 25px;
  color: #f6c315;
  line-height: 30px;
  text-transform: uppercase;
  text-align: center !important;
}

.module4_course .drive_evaluate .hazard_watch h4 {
  font-size: 22px;
  line-height: 30px;
  color: #ffffff;
}

.module4_course .drive_evaluate {
  position: relative;
}

.module4_course .drive_evaluate h3 {
  font-weight: bold;
  font-size: 36.29px;
  line-height: 44px;
  color: #f6c315;
  text-transform: uppercase;
}

.module4_course .drive_evaluate .drive_evaluate_img {
  position: absolute;
  right: 10px;
  width: 360px;
  overflow: hidden;
  top: 12%;
  z-index: 1;
}

.module4_course .drive_evaluate .drive_evaluate_img img {
  max-width: 100%;
}

.module4_course .drive_evaluate ul li {
  position: relative;
  list-style-type: none;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 27px;
  color: #58595b;
  padding: 0px 30px 0px 26px;
  margin-bottom: 7px;
}

.module4_course .drive_evaluate ul li:before {
  position: absolute;
  content: "";
  left: 5px;
  top: 8px;
  border-radius: 100px;
  height: 11px;
  width: 11px;
  border: 1px solid #1a4790;
  background: #1a4790;
}

.module4_course .module_execute {
  position: relative;
  background-image: url("../../../../Images/module4/executebg.png");
  background-repeat: no-repeat;
  padding: 20px 24px;
  /* height: 350px; */
  margin-left: 1%;
  width: 100%;
  background-size: 100% 100%;
}

.module4_course .module_execute .module_execute_content h3 {
  font-weight: bold;
  font-size: 36.29px;
  line-height: 38px;
  color: #f6c315;
  text-transform: uppercase;
}

.module4_course .module_execute .module_execute_content h3 span {
  font-size: 24.19px;
  line-height: 29px;
  opacity: 1;
  color: #f6c315;
}

.module4_course .module_execute .module_execute_content p {
  font-weight: normal;
  font-size: 22.17px;
  line-height: 27px;
  color: #ffffff;
}

.module4_course .module_execute ul li {
  position: relative;
  list-style-type: none;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 27px;
  color: #ffffff;
  padding: 0px 0px 0px 26px;
  margin-bottom: 7px;
}

.module4_course .module_execute ul li:before {
  position: absolute;
  content: "";
  left: 5px;
  top: 8px;
  border-radius: 100px;
  height: 11px;
  width: 11px;
  border: 1px solid #f6c315;
  background: #f6c315;
}

.module4_course .module_execute .module_execute_img {
  position: absolute;
  width: 470px;
  overflow: hidden;
  z-index: 9;
  left: -4%;
  bottom: 0%;
}

.module4_course .module_execute .module_execute_img img {
  max-width: 100%;
}

.module4_course .module_plan {
  position: relative;
  margin-top: 80px !important;
}

.module4_course .module_plan .example_strategy p {
  font-weight: normal;
  font-size: 22.17px;
  line-height: 27px;
  color: #58595b;
}

.module4_course .module_plan .hydro_content_left span {
  font-weight: bold;
  font-size: 22.17px;
  line-height: 27px;
  color: #58595b;
}

.module4_course .module_plan .hydro_content_left p {
  font-weight: normal;
  font-size: 22.17px;
  line-height: 27px;
  color: #58595b;
}

.module4_course .module_plan .hydro_img img {
  max-width: 100%;
}

.module4_course .module_plan .prevent_content {
  background: #0b2c66;
  border-radius: 10px;
  padding: 15px 15px;
  align-items: center;
  height: 100%;
  display: flex;
}

.module4_course .module_plan .prevent_content p {
  font-weight: normal;
  font-size: 20.16px;
  line-height: 24px;
  color: #ffffff;
}

.module4_course .example_strategy p {
  font-weight: normal;
  font-size: 22.17px;
  line-height: 27px;
  color: #58595b;
}

.module4_course .module_logo {
  transform: translatey(0px);
  animation: float 3s ease-in-out infinite;
}

.module4_course .avoid_skid {
  position: relative;
  padding-top: 10px;
}

.module4_course .avoid_skid .ribbon_main {
  width: 70%;
  position: relative;
}

.module4_course .avoid_skid .skid_car {
  text-align: center;
  position: relative;
  bottom: 4rem;
}

.module4_course .avoid_skid .front_wheel_skid .front_wheel_img,
.module4_course .avoid_skid .rear_wheel_skid .rear_skid {
  text-align: center;
  margin: 3rem 0;
}

.module4_course .avoid_skid .rear_wheel_skid .row {
  align-items: center;
}

.module4_course .avoid_skid ul li {
  position: relative;
  list-style-type: none;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 27px;
  color: #000000;
  padding: 0px 30px 0px 26px;
  margin-bottom: 1.5rem;
}

.module4_course .avoid_skid .rear_wheel_skid p {
  position: relative;
  list-style-type: none;
  font-weight: normal;
  font-size: 22.17px;
  line-height: 27px;
  color: #000000;
  padding: 0px 30px 0px 26px;
  margin-bottom: 2rem;
}

.module4_course .avoid_skid ul li:before {
  position: absolute;
  content: "";
  left: 5px;
  top: 8px;
  border-radius: 100px;
  height: 11px;
  width: 11px;
  border: 1px solid #1a4790;
  background: #1a4790;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-15px);
  }

  100% {
    transform: translatey(0px);
  }
}

.skids_para {
  font-weight: normal;
  font-size: 22.17px;
  line-height: 27px;
  color: #58595b;
  margin: 20px 0px;
}

@media screen and (max-width: 425px) {
  .avoid_skid iframe {
    height: 250px;
    width: 100%;
  }
}

@media screen and (min-width: 426px) and (max-width: 768px) {
  .avoid_skid iframe {
    height: 440px;
    width: 100%;
  }
}

@media screen and (min-width: 769px) and (max-width: 1199px) {
  .avoid_skid iframe {
    height: 500px;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  /* .avoid_skid iframe {
    height: 500px;
    width: 100%;
  } */

  .module4_course .driver_skill {
    background-position: bottom;
    height: 580px;
    background-size: cover;
  }

  .module4_course .driver_skill .driver_skill_content {
    position: relative;
    left: 0;
    right: 0;
    top: 21px;
    padding-top: 8px;
    width: 100%;
  }

  .module4_course .driver_skill .driver_skill_content h1 {
    font-size: 42.52px;
    line-height: 52px;
  }

  .module4_course .driver_skill .driver_skill_content h1 span {
    font-size: 51.31px;
    line-height: 61px;
  }

  .module4_course .driver_skill .driver_skill_content p {
    font-size: 32.49px;
    line-height: 42px;
    width: 220px;
    padding: 4px 12px;
  }

  .module4_course .driver_skill .module_logo {
    bottom: 2%;
    width: 220px;
    left: 0;
    margin: auto;
    right: 0;
  }

  .module4_course .avoid_skid .ribbon_main {
    width: 90%;
  }

  .module4_course .ribbon_main {
    width: 83%;
  }

  .module4_course .ribbon_bg h2 {
    font-size: 15.29px;
    line-height: 44px;
  }

  .module4_course .ribbon_position {
    margin: -5% 0px 20px;
  }

  .module4_course .strategy_content p {
    font-size: 20.19px;
    line-height: 26px;
    margin: 10px 0px;
  }

  .module4_course .strategy_content .strategy_buttons .btn.btn_blue {
    width: 100%;
    margin: 6px 10px;
    font-size: 28.22px;
    line-height: 29px;
  }

  .module4_course .strategy_content .strategy_buttons .btn.btn_blue span {
    line-height: 10px;
  }

  .module4_course .vehicle_search .search_car_img {
    position: relative;
    width: auto;
  }

  .module4_course .vehicle_search .search_content {
    background: none;
    height: auto;
    margin: 10px 0px;
    padding: 0px;
  }

  .module4_course .vehicle_search .search_content ul li {
    color: #58595b;
    font-size: 20.17px;
    line-height: 28px;
  }

  .module4_course .factor_search .factor_search_img,
  .module4_course .drive_evaluate .drive_evaluate_img,
  .module4_course .module_execute .module_execute_img {
    position: relative;
    width: auto;
    top: auto;
  }

  .module4_course .same_heading {
    font-size: 30.29px;
    line-height: 40px;
  }

  .module4_course .drive_evaluate ul li {
    font-size: 19.17px;
    line-height: 27px;
    padding: 0px 30px 3px 26px;
  }

  .module4_course .module_execute {
    background-image: none;
    height: auto;
    margin: 0px;
  }

  .module4_course .module_execute .module_execute_content p {
    color: #58595b;
    font-size: 20.17px;
    line-height: 28px;
  }

  .module4_course .module_execute ul li {
    color: #58595b;
    font-size: 20.17px;
    line-height: 28px;
  }

  .module4_course video {
    height: 200px;
  }

  .module4_course .module_plan .ribbon_main {
    position: relative;
  }

  .module4_course .module_plan .hydro_content_left p {
    font-size: 20.17px;
    line-height: 27px;
  }

  .module4_course .module_plan .hydro_margin .col-12 {
    margin-bottom: 10px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .module4_course .driver_skill {
    background-position: bottom;
    height: 600px;
    background-size: 100% 90%;
  }

  .module4_course .driver_skill .driver_skill_content {
    top: 14%;
  }

  .module4_course .driver_skill .module_logo {
    bottom: 9%;
    width: 150px;
    left: 4%;
  }

  .module4_course .ribbon_position {
    margin: -3% 0px 20px;
  }

  .module4_course .ribbon_main {
    top: -10%;
    width: 45%;
  }

  .module4_course .avoid_skid .ribbon_main {
    width: 80%;
  }

  .module4_course .ribbon_bg h2 {
    font-size: 28.29px;
    line-height: 44px;
  }

  .module4_course .strategy_content .strategy_buttons .btn.btn_blue {
    width: 197px;
    height: 70px;
  }

  .module4_course .module_plan .hydro_margin .col-12 {
    margin-bottom: 10px;
  }

  .module4_course .factor_search .factor_search_img {
    width: 251px;
    bottom: 50px;
  }

  .module4_course .vehicle_search .search_car_img {
    width: 340px;
    left: 0%;
    top: auto;
    bottom: 0px;
  }

  .module4_course .drive_evaluate .drive_evaluate_img {
    position: relative;
    margin: auto;
  }

  .module4_course .vehicle_search .search_content {
    height: 646px;
    background-size: 100% 100%;
  }

  .module4_course .module_execute {
    background-size: 100% 100%;
    height: 500px;
  }

  .module4_course .module_execute .module_execute_img {
    width: 354px;
    left: -7%;
    top: auto;
    bottom: 0;
  }

  .module_plan .ribbon_main {
    position: relative;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .module4_course .strategy_content .strategy_buttons .btn.btn_blue {
    width: 277px;
    height: 71px;
  }

  .module4_course .factor_search .factor_search_img {
    width: 325px;
    top: 56px;
  }

  .module4_course .same_heading {
    font-size: 21.29px;
    line-height: 34px;
  }

  .module4_course .drive_evaluate .drive_evaluate_img {
    right: 8px;
    width: 250px;
    top: 38%;
  }

  .module4_course .module_execute {
    padding: 20px 20px 0px 24px;
    height: 275px;
  }

  .module4_course .module_execute .module_execute_img {
    width: 365px;
  }

  .module4_course .module_execute .module_execute_content h3 {
    font-size: 31.29px;
    line-height: 35px;
  }

  .module4_course .module_execute .module_execute_content h3 span {
    font-size: 20.19px;
    line-height: 29px;
  }

  .module4_course .module_execute .module_execute_content p {
    font-size: 19.17px;
    line-height: 24px;
  }

  .module4_course .module_execute ul li {
    font-size: 16.17px;
    line-height: 27px;
    padding: 0px 0px 0px 26px;
    margin-bottom: 7px;
  }

  .module4_course .vehicle_search .search_car_img {
    width: 366px;
    left: 1%;
    top: 22%;
  }

  .module4_course .vehicle_search .search_content {
    padding: 21px 16px;
    height: 396px;
  }

  .module4_course .vehicle_search .search_content ul li {
    font-size: 18.17px;
    line-height: 24px;
    padding: 0px 0px 0px 26px;
    margin-bottom: 13px;
  }

  .module_plan .ribbon_main {
    position: relative;
  }
}

.progress {
  display: block;
  width: 100%;
  height: 10px;
  border-radius: 10px;
  overflow: hidden;

  background-color: #f5f5f5;
  background-image: linear-gradient(to right, black, black);
  background-repeat: no-repeat;
  background-size: 0 100%;

  transition: background-size 0.4s ease-in-out;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.inner-slider-div {
  position: relative;
}

.inner-slider {
  position: absolute;
  width: 80%;
  top: 250px;
  left: 50%;
  transform: translate(-50%, -50%);
}

.slide-heading {
  background-color: #ecc416;
  padding: 20px;
  margin: auto !important;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.slide-content {
  align-items: center;
  justify-content: center;
  height: 100%;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 40px !important;
  line-height: 1 !important;
  opacity: 1 !important;
  color: black !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -55px !important;
}

.slick-next {
  right: -33px !important;
}

.slick-prev,
.slick-next {
  width: 38px;
  height: 39px;
}

.progress {
  background-color: #666666;
  background-image: linear-gradient(to right, #097ff2, #0a7ff2);
}

.slick-track {
  display: flex !important;
}

.slide-content.row .col {
  display: flex;
  justify-content: center;
  align-items: center;
}

.slide-content-small-img {
  border: #ecc416 solid 40px;
}

.slide-content-larg-img {
  border: #ecc416 solid 25px;
}

.slide-ul {
  padding-left: 25px;
}

.slick-list {
  position: relative;
  display: flex;
  overflow: hidden;
  margin: 0;
  padding: 0;
}

.inner-slider-div {
  position: relative;
  width: 90%;
  margin: auto;
}

.inner-slide-title {
  width: 100% !important;
  margin: 0 imo !important;
}

.inner-slide-title h2 {
  width: 100% !important;
  margin: 30px;
}

.inner-slide {
  width: 100%;
  display: flex;
  justify-content: center;
}

.inner-slider-main {
  box-shadow: 2px 2px 10px grey;
  padding: 10px 40px;
  width: 85%;
  margin: 20px auto 0;
}

.inner-slide-image {
  display: flex;
  justify-content: center;
}

.current-slide {
  text-align: center;
  padding: 20px 0;
}

.note {
  position: absolute !important;
}

.h5p-content-note {
  color: red;
  font-size: 22px;
  font-weight: 600;
}

@media only screen and (max-width: 470px) {
  .inner-slide-image.col img {
    width: 180px !important;
  }

  .avoid_skid_h5p .slick-slide img {
    display: block;
    width: 80% !important;
  }

  .inner-slide-image ul {
    padding-left: 0;
  }

  ul {
    padding: 5px;
    width: 100%;
  }

  .slide-heading h1 {
    line-height: 90%;
  }

  .inner-slider-main {
    padding: 0 41px;
    box-shadow: none !important;
    width: 100% !important;
  }

  .coursesection h3 {
    font-size: 14px !important;
    margin-bottom: 15px;
  }

  .parent-buttons.slick-prev,
  .parent-buttons.slick-next {
    top: 96% !important;
  }

  .parent-buttons.slick-prev {
    left: 45% !important;
    z-index: 1;
  }

  .parent-buttons.slick-next {
    right: 45% !important;
  }

  .current-slide {
    font-size: 14px;
    position: relative;
    top: -40px;
    left: 0;
  }

  .slick-prev:before,
  .slick-next:before {
    font-size: 30px !important;
  }

  .inner-slide-content.row {
    flex-direction: column;
  }

  .inner-slide-title h2 {
    width: 100% !important;
    margin: 0 30px;
  }

  .slick-prev {
    left: -42px !important;
  }
}

@media only screen and (max-width: 1024px) {
  .slide-heading {
    padding: 10px;
  }

  .slide-heading h1 {
    font-size: 24px;
  }

  .inner-slider-main {
    box-shadow: 2px 2px 10px grey;
    width: 95%;
  }

  .coursesection h3 {
    position: relative;
    font-size: 18px;
  }

  .slide-content-small-img {
    outline: #ecc416 solid 0px;
  }

  .slide-content-larg-img {
    border: #ecc416 solid 0px;
  }

  .slide-content.row {
    padding: 10px 0;
    flex-direction: column;
  }
  .slide-content {
    align-items: center;
    justify-content: flex-start;
  }

  .slide-ul {
    padding-left: 30px;
    margin-bottom: 0 !important;
  }

  .module4_course .avoid_skid ul li {
    margin-bottom: 2px;
  }

  .avoid_skid_h5p .slick-slide img {
    max-width: 250px !important;
    object-fit: contain;
    margin-left: 6px;
  }
  .SafetyTechnologySlider_h5p .slick-slide img {
    max-width: 100% !important;
  }
  .slider-parent-buttons.slick-prev,
  .slider-parent-buttons.slick-next {
    top: 110%;
  }
  .slider-parent-buttons.slick-prev {
    left: 43% !important;
    z-index: 1;
  }
  .slider-parent-buttons.slick-next {
    right: 43% !important;
    z-index: 1;
  }
}

@media only screen and (max-width: 1440px) {
  .parent-buttons.slick-prev,
  .parent-buttons.slick-next {
    top: 93%;
  }

  .slick-prev {
    left: 0 !important;
    z-index: 1;
  }

  .slick-next {
    right: 10px !important;
    z-index: 1;
  }
}

.slide-ul li img {
  max-height: 204px;
}

@media only screen and (max-width: 1440px) {
  .parent-buttons.slick-prev,
  .parent-buttons.slick-next {
    top: 108%;
  }

  .avoid_skid_h5p .slick-prev {
    left: 40% !important;
    z-index: 1;
  }

  .avoid_skid_h5p .slick-next {
    right: 40% !important;
    z-index: 1;
  }
}

.flex-list.row {
 align-items: center;
}
.flex-list.row img{
  margin: auto;
 }

@media only screen and (max-width: 768px) {
  .flex-list.row {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    gap: 0;
  }
  .flex-list.row img{
    height: 200px;
    margin-bottom: 30px !important;
   }
}

.avoid_skid_h5p .slick-initialized .slick-slide {
  display: flex !important;
  height: auto !important;
}
.avoid_skid_h5p .slick-track:before, .slick-track:after {
  display: flex !important;
  content: '' !important;
}
.avoid_skid_h5p .outer-slide {
  width: 100% !important;
  display: flex !important;
  height: 100% !important;
  flex-direction: column !important;
}
.slide-content.diff{
  display: flex;
  justify-content: start;
  height: auto;
  align-items: flex-start;
}