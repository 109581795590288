.welcomeScreen .card-text.typingPara {
  font: normal normal normal 14px/18px Source Sans Pro;
  letter-spacing: 0px;
  color: #aeaeae;
  opacity: 1;
}
.welcomeScreen .card-text.typingPara1 {
  font: italic normal normal 14px/24px Source Sans Pro;
  letter-spacing: 0px;
  color: #100d01;
  opacity: 1;
}
.welcomeScreen .form-group textarea.form-control {
  height: auto;
}


.previewquiz h1 {
  font: normal normal bold 40px/44px Source Sans Pro;
  letter-spacing: 0.08px;
  color: #091c2d;
}

.page-item {
  background-color: #F8F8F8;
  font-size: normal normal normal 20px/25px Source Sans Pro;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
}
.page-options {
  margin-top: 40px;
}
.quiz-number {
  background-color: #4BA0F0;
  color: white;
  padding: 10px;
  font-weight: 600;
  font-size: 16px;
  /* max-width: 200px !important; */
}

.form-group-custom{
  max-width: fit-content;
  padding: 15px;
  background-color: #DEEFFF;
}
.label-text {
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
}
.welcomeScreen .card.card_box_type{
  box-shadow: none;
  border:none;
}

@media screen and (max-width: 576px) {


}