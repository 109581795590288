/* Pagination links */
.pagination a {
  color: black!important;
  margin: 0px !important;
  padding: 8px 16px!important;
  text-decoration: none!important;
  transition: background-color .3s!important;
  text-align: right;
}
.content{
  width: 100%;
  height: 500px;
  overflow-y: scroll;
}

.page_right_list a{
  border-color: #091c2d !important;
  border: 1px solid #e8e9ec;
  border-radius: 5px;
  font: normal normal normal 13px/30px Source Sans Pro;
  letter-spacing: 0px;
  padding: 0px 10px !important;
  margin: 5px !important;
}
.page_right_list{
  justify-content: flex-end;
}
.page_right_list a:hover{
  border-color: #091c2d !important;
  background: #091c2d 0% 0% no-repeat padding-box !important;
  color: #fff !important;
}

/* Style the active/current link */
.pagination a.active {
  background-color: dodgerblue!important;
  color: white!important;
}

/* Add a grey background color on mouse-over */
.pagination a:hover:not(.active) {background-color: #ddd;}

.coupon_box h3{
text-align: left;
font: normal normal normal 16px/20px Source Sans Pro;
letter-spacing: 0px;
color: #000000;
}
.add_usermodal.coupon_box .modal_title{
text-align: left;
font: normal normal normal 18px/23px Source Sans Pro;
letter-spacing: 0px;
color: #091C2D;
}
.add_usermodal.coupon_box .eyeImage {
position: absolute;
right: 15px;
top: 40px;
cursor: pointer;
}
.date_box1 .react-datepicker-wrapper{
display: block;
}
.date_box1 .react-datepicker__input-container input{
height:50px;
}
.add_usermodal label{
text-align: left;
font: normal normal normal 16px/20px Source Sans Pro;
letter-spacing: 0px;
color: #000000;
}
.add_usermodal .form-control::-webkit-input-placeholder{
font-size: 13px;
}
.react-date-picker__calendar{
  z-index: 999;
}