.dashboardSec .card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  border-radius: 4px;
}
.tableScroll{
  max-height: 190px;
  overflow-y: scroll;
}
.form-control.dark_font_color{
  color: #212529;
  opacity: 1;
  width:120px;
} 

.chart_color{
  border: 3px solid#8078ff!important;
}
.dashboardSec .HeadDropdown{
  justify-content: flex-end;
}



.date_picker .rdrInputRanges {
  display: none;
}
/* .date_picker_card .rdrDateRangePickerWrapper {
  margin-left: -116px;
  border: 1px solid #e2e7ec;
  border-right: none;
}  */
.date_picker .rdrCalendarWrapper.rdrDateRangeWrapper {
  width: 222px;
  font-size: 10px;
}
.date_picker .rdrDefinedRangesWrapper {
  width: 92px;
  font-size: 11px;
}
.date_picker .rdrDayToday .rdrDayNumber span:after{
  bottom: 2px;
}
.latest_info_head{
  display: flex;
  justify-content: space-between;
}

.latest_info_head img{
  height: 27px;
  margin-right: 20px;
}
.rdrMonthAndYearWrapper {
  padding: 10px 5px;
}
.rdrNextPrevButton{
  margin:0px;
}

@media screen and (max-width:576px){
  .dashboardSec .HeadDropdown {
    width: 60%;
    justify-content: flex-end;
  }
  .dashboardSec .form-control.dark_font_color{
    width:90px;
  }
  .dashboardSec .ViewAlltag{
    padding: 50px 0px 0px;
    font-size: 14px;
  }
}
@media screen and (min-width:768px) and (max-width:991px){
  .dashboardSec li.list-group-item.latestInfo{
    padding: 0px 10px;
  }
  .dashboardSec li.list-group-item .iconData .infoIcon{
    background-size: contain;
    background-repeat: no-repeat;
  }
  .dashboardSec li.list-group-item .iconData{
    text-align: left;
  }
  .form-control.dark_font_color {
    width: 115px;
    height: 38px;
  }
  .dashboardSec .card{
    overflow: hidden;
  }
  .date_picker .rdrDefinedRangesWrapper {
    width: 66px;
  }
  .rdrStaticRangeLabel {
    padding: 10px 6px;
  }
}

.icon_in_progress{
  border: 3px solid#0068b9 !important ;
}
.icon_expired{
  border: 3px solid#bb8023 !important ;
}
.icon_completed{
  border: 3px solid#47a76e !important ;
}