.module8_course .module_drugs {
  position: relative;
  background-image: url("../../../../Images/module8/bannerbg.jpg");
  background-position: center;
  background-size: 100% 80%;
  background-repeat: no-repeat;
  height: 700px;
}

.module8_course .module_drugs .module_logo {
  position: absolute;
  right: 10%;
  top: 12%;
  width: 260px;
}

.module8_course .module_drugs .module_logo img {
  max-width: 100%;
}

.module8_course .module_drugs .drugs_content {
  position: absolute;
  left: 4%;
  top: 14%;
}

.module8_course .module_drugs .drugs_content p {
  font-weight: bold;
  font-size: 37.21px;
  line-height: 45px;
  color: #f6c315;
  text-transform: uppercase;
}

.module8_course .module_drugs .drugs_content h1 {
  font-weight: bold;
  font-size: 81.84px;
  line-height: 85px;
  color: #ffffff;
  text-transform: uppercase;
}

.module8_course .same_alcohol_head {
  font-weight: bold;
  font-size: 30.29px;
  /* line-height: 44px; */
  text-transform: uppercase;
  color: #ffffff;
  background: #1a4790;
  height: 100%;
  padding: 1px 17px;
  position: relative;
  width: 40%;
  margin-top: -2%;
}

.module8_course .same_alcohol_head:after {
  background: #1a4790;
  content: "";
  height: 100%;
  position: absolute;
  right: -18px;
  top: 0;
  transform: skew(25deg);
  width: 35px;
  z-index: -5;
}

.module8_course .overview_content {
  position: relative;
  margin-top: -10%;
  padding: 0px 20px;
}

.module8_course .overview_content:after {
  position: absolute;
  content: "";
  border-left: 1px solid #414042;
  border-bottom: 1px solid #414042;
  height: 100%;
  width: 100%;
  top: 20px;
  left: 10px;
  z-index: 0;
}

.module8_course .overview_content:before {
  position: absolute;
  content: "";
  border-top: 1px solid #414042;
  border-right: 1px solid #414042;
  height: 100%;
  width: 100%;
  top: 20px;
  left: 10px;
  z-index: 0;
}

.module8_course .overview_content p {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #414042;
}

.module8_course .overview_content p span {
  font-weight: bold;
}

.module8_course .alcohol_drugs {
  margin: 10% 0px;
  position: relative;
  border: 1px solid #414042;
  padding: 0px 20px;
}

.module8_course .alcohol_drugs .same_alcohol_head {
  width: 80%;
}

.module8_course .alcohol_drugs h6 {
  font-weight: bold;
  font-size: 24.19px;
  line-height: 29px;
  color: #1a4790;
  text-transform: uppercase;
}

.module8_course .alcohol_drugs p {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #414042;
}

.module8_course .alcohol_drugs .alcohol_div .alcohol_img img {
  max-width: 100%;
}

.module8_course .alcohol_drugs .drug_img {
  position: absolute;
  bottom: -30%;
  width: 155px;
  right: 0px;
}

.module8_course .border_div_bottom {
  border: 1px dashed #414042;
  width: 84%;
  margin: 10px 20px 20px 20px;
}

.module8_course .alcohol_drugs .drug_img img {
  max-width: 100%;
}

.module8_course ul li {
  position: relative;
  list-style-type: none;
  padding: 0px 0px 0px 20px;
  margin-bottom: 12px;
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #414042;
}

.module8_course ul li:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 10px;
  background: #606161;
  height: 11px;
  width: 11px;
  border-radius: 0px;
}

.module8_course .drive_alcohol ul li {
  width: 100%;
}

.module8_course .drive_alcohol ul .overlap_content {
  width: 71%;
}

.module8_course .drive_alcohol_img {
  position: absolute;
  width: 300px;
  overflow: hidden;
  right: 0px;
  bottom: 20px;
}

.module8_course .drive_alcohol_img img {
  max-width: 100%;
}

.module8_course .drug_use {
  position: relative;
  border: 1px solid #414042;
  padding: 0px 20px;
}

.module8_course .drug_use .same_alcohol_head {
  width: 90%;
}

.module8_course .drug_use .same_alcohol_head:after {
  /* transform: skew(-26deg); */
}

.module8_course .drug_use .same_alcohol_head:before {
  /* background: #1a4790;
  content: "";
  height: 50px;
  position: absolute;
  left: -17px;
  top: 0;
  transform: skew(34deg);
  width: 32px; */
}

.module8_course .drug_use .physical_img {
  width: 400px;
  float: right;
}

.module8_course .drug_use .physical_para {
  font-weight: bold;
  font-size: 24.19px;
  line-height: 29px;
  text-transform: uppercase;
  color: #1a4790;
}

.module8_course .drug_use .drug_effect {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #58595b;
}

.module8_course .drug_use .note_drug {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #ffffff;
  background: #414042;
  margin-left: -7%;
  padding: 18px;
}

.module8_course .drug_use .physical_img img {
  max-width: 100%;
}

.module8_course .person_example {
  position: relative;
  border: 1px solid #414042;
  padding: 0px 20px;
  margin: 10% 0px;
}

.module8_course .person_example .note_drug {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #ffffff;
  background: #414042;
  padding: 18px;
}

.module8_course .person_example h2 {
  font-weight: bold;
  font-size: 30.24px;
  line-height: 36px;
  color: #fff;
  text-transform: uppercase;
  padding: 1px 17px;
  position: relative;
  margin: -3% 0px 1%;
  background: #1a4790;
  width: 80%;
  height: 100%;
}

.module8_course .person_example h2:after {
  background: #1a4790;
  content: "";
  height: 100%;
  position: absolute;
  right: -18px;
  top: 0;
  transform: skew(25deg);
  width: 35px;
  z-index: -5;
}

.module8_course .person_example table thead th,
.module8_course .person_example table tr td {
  background: #1a4790;
  font-weight: bold;
  font-size: 24.19px;
  line-height: 29px;
  text-transform: uppercase;
  color: #ffffff !important;
}

.module8_course .person_example table {
  border-collapse: separate;
  border-spacing: 10px 1em;
}

.module8_course .person_example table .data_normal {
  font-weight: normal;
  width: 280px;
}

.module8_course .person_example table tr {
  border: none !important;
}

.module8_course .person_example table .odd_color {
  background: #06275d;
}

.module8_course .vehicle_undersection {
  position: relative;
  border: 1px solid #414042;
  padding: 0px 20px;
}

.module8_course .vehicle_undersection .same_vehicle_head {
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #ffffff;
  text-transform: uppercase;
  background: #1a4790;
  height: 66px;
  padding: 3px 17px;
  position: relative;
  width: 600px;
  margin-top: -3%;
}

.module8_course .vehicle_undersection .same_vehicle_head:after {
  background: #1a4790;
  content: "";
  height: 66px;
  position: absolute;
  right: -16px;
  top: 0;
  transform: skew(25deg);
  width: 33px;
}

.module8_course .vehicle_undersection h6 {
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #1a4790;
  text-transform: uppercase;
}

.module8_course .vehicle_undersection .vehiclesection {
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
}

.module8_course .vehicle_undersection .dra_para {
  font-weight: normal;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
}

.module8_course .vehicle_undersection .suspension_img img {
  max-width: 100%;
}

.module8_course .person_example .pound_img {
  position: relative;
}

.module8_course .person_example .pound_img img {
  max-width: 100%;
}

.module8_course .person_example .pound_img .image1 {
  width: 75%;
}

.module8_course .person_example .pound_img .image2 {
  width: 505px;
  position: absolute;
  right: 0px;
  top: -62%;
}

.module8_course .zero_tolerance {
  position: relative;
  border: 1px solid #414042;
  padding: 0px 20px;
  margin: 10% 0px;
}

.module8_course .zero_tolerance h2 {
  color: #fff;
  width: 500px;
}

.module8_course .zero_tolerance h4 {
  font-style: normal;
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #1a4790;
  text-transform: uppercase;
}

.module8_course .zero_tolerance p {
  font-weight: normal;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
}

.module8_course .zero_tolerance .law_img img {
  max-width: 100%;
}

.module8_course .zero_tolerance .law_note {
  background: #414042;
  font-weight: normal;
  font-size: 23.18px;
  line-height: 28px;
  color: #ffffff;
  padding: 13px;
  width: 93%;
}

.chemical-image {
  max-width: 100%;
}

.module8_course .leandra_law h4 {
  text-transform: uppercase;
  background: #1a4790;
  height: 46px;
  position: relative;
  width: 661px;
  /* margin-top: -3%; */
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #ffffff;
  padding: 8px 14px;
}

.module8_course .leandra_law h4:after {
  background: #1a4790;
  content: "";
  height: 46px;
  position: absolute;
  right: -28px;
  top: 0;
  transform: skew(25deg);
  width: 77px;
}

.module8_course .leandra_law span {
  font-weight: bold;
  font-size: 23.18px;
  line-height: 35px;
  color: #414042;
}

.module8_course .leandra_law h5 {
  font-weight: bold;
  font-size: 30.91px;
  line-height: 37px;
  color: #1a4790;
}

.module8_course .leandra_law span.detail {
  font-weight: normal;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
  margin-bottom: 12px;
}

.module8_course .leandra_law {
  position: relative;
  border: 1px solid #414042;
  padding: 0px 20px;
  margin: 10% 0px;
}

.module8_course .leandra_law h2 {
  width: 400px;
  color: #fff;
}

.module8_course .leandra_law p {
  font-weight: normal;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
  margin-bottom: 12px;
}

.module8_course .class_law {
  position: relative;
  border: 1px solid #414042;
  margin: 10% 0px;
}

.module8_course .vehicle_law {
  /* background: #dadcd9; */
  padding: 50px 20px 0px;
  position: relative;
  border: 1px solid #414042;
}

.module8_course .nys_vehicle .vehicle_traffic {
  font-weight: bold;
  font-size: 30.91px;
  line-height: 37px;
  color: #ffc642;
  text-transform: uppercase;
  background: #1a4790;
  height: 99px;
  padding: 1px 17px;
  position: relative;
  width: 661px;
  margin-top: -10%;
}

.module8_course .nys_vehicle .vehicle_traffic:after {
  background: #1a4790;
  content: "";
  height: 99px;
  position: absolute;
  right: -28px;
  top: 0;
  transform: skew(25deg);
  width: 77px;
  z-index: -1;
}

.module8_course .nys_vehicle .vehicle_traffic span {
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #ffffff;
  text-transform: uppercase;
}

.module8_course .nys_vehicle h4.risk_head {
  text-transform: uppercase;
  background: #1a4790;
  height: 46px;
  position: relative;
  width: 700px;
  margin-top: 3%;
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #ffffff;
  padding: 8px 14px;
}

.module8_course .nys_vehicle h4.risk_head:after {
  background: #1a4790;
  content: "";
  height: 46px;
  position: absolute;
  right: -28px;
  top: 0;
  transform: skew(25deg);
  width: 77px;
}

.module8_course .nys_vehicle h4.risk_head_4 {
  text-transform: uppercase;
  background: #1a4790;
  height: 46px;
  position: relative;
  width: 700px;
  margin-top: 3%;
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #ffffff;
  padding: 8px 14px;
}

.module8_course .nys_vehicle h4.risk_head_4:after {
  background: #1a4790;
  content: "";
  height: 46px;
  position: absolute;
  right: -28px;
  top: 0;
  transform: skew(25deg);
  width: 77px;
}

.module8_course .nys_vehicle p {
  font-weight: normal;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
}

.module8_course .nys_vehicle .drug_class h4 {
  font-weight: bold;
  font-size: 30.91px;
  line-height: 37px;
  color: #1a4790;
}

.module8_course .nys_vehicle .drug_class h5 {
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
  text-align: center;
}

.module8_course .nys_vehicle span.detail {
  font-weight: normal;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
  margin-bottom: 12px;
}

.module8_course .nys_vehicle table th {
  padding: 10px;
  font-size: 23.18px;
  line-height: 28px;
  font-weight: bold;
  text-align: center;
}

.module8_course .traffic_law {
  background: #dadcd9;
  padding: 50px 20px 0px;
}

.module8_course .traffic_law h2.high_risks_head {
  padding: 4px 15px;
  font-weight: bold;
  font-size: 30.91px;
  line-height: 37px;
  color: #fff;
  text-transform: uppercase;
  background: #1a4790;
  height: 50px;
  position: relative;
  width: 661px;
  margin-top: -8%;
}

.module8_course .traffic_law h2.high_risks_head:after {
  background: #1a4790;
  content: "";
  height: 50px;
  position: absolute;
  right: -10px;
  top: 0;
  transform: skew(25deg);
  width: 23px;
  z-index: -5;
}

.module8_course .traffic_law .same_traffic_head {
  font-weight: bold;
  font-size: 30.91px;
  line-height: 37px;
  color: #ffc642;
  text-transform: uppercase;
  background: #1a4790;
  height: 99px;
  padding: 1px 17px;
  position: relative;
  width: 661px;
  margin-top: -10%;
}

.module8_course .traffic_law .same_traffic_head:after {
  background: #1a4790;
  content: "";
  height: 99px;
  position: absolute;
  right: -28px;
  top: 0;
  transform: skew(25deg);
  width: 77px;
  z-index: -1;
}

.module8_course .traffic_law .same_traffic_head span {
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #ffffff;
  text-transform: uppercase;
}

.module8_course .traffic_law span.detail {
  font-weight: normal;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
  margin-bottom: 12px;
}

.module8_course .traffic_law table th {
  padding: 10px;
  font-size: 23.18px;
  line-height: 28px;
  font-weight: bold;
  text-align: left;
  background: #ffffff;
}

.module8_course .drug_class {
  padding: 0px 20px;
}

.module8_course .traffic_law .main_para {
  font-weight: normal;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
}

.module8_course .traffic_law h4 {
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #ffffff;
  text-transform: uppercase;
}

.module8_course .traffic_law .risk_drug img {
  max-width: 100%;
}

.module8_course .traffic_law .definition_drug h5 {
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  text-transform: uppercase;
  color: #414042;
}

.module8_course .traffic_law .definition_drug p {
  font-weight: normal;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
}

.module8_course .traffic_law .tpyes_drug h6 {
  font-weight: bold;
  font-size: 30.91px;
  line-height: 37px;
  color: #1a4790;
  text-transform: uppercase;
}

.module8_course .traffic_law .tpyes_drug h6 {
  font-weight: bold;
  font-size: 30.91px;
  line-height: 37px;
  color: #1a4790;
  margin: 0px 0px 50px 0px;
}

.module8_course .traffic_law .tpyes_drug p {
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #ffffff;
  text-transform: uppercase;
  margin-bottom: 0px;
}

.module8_course .traffic_law .drug_content_div {
  position: absolute;
  width: 237px;
  padding: 10px;
  text-align: center;
  bottom: -19px;
}

.module8_course .traffic_law .tpyes_drug .drug_even {
  background: #606161;
}

.module8_course .traffic_law .tpyes_drug .drug_odd {
  background: #404141;
}

.module8_course .drug_class h4 {
  font-weight: bold;
  font-size: 30.91px;
  line-height: 37px;
  color: #1a4790;
}

.module8_course .drug_class h5 {
  font-weight: bold;
  font-size: 28.98px;
  line-height: 35px;
  color: #414042;
}

.module8_course .drug_class table tbody tr td {
  font-size: 23px;
  width: 50%;
}

.module8_course .drug_class table thead tr th.title {
  text-decoration: underline;
}

.module8_course .drug_class table tbody tr td p {
  text-decoration: underline;
}

.module8_course .drug_class h5.title {
  font-weight: bold;
  font-size: 26.91px;
  line-height: 37px;
  color: #1a4790;
}

.module8_course .drug_class span.detail {
  font-weight: normal;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
  margin-bottom: 12px;
}

.module8_course .drug_class p {
  font-weight: normal;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
}

.module8_course .drug_use .same_alcohol_head {
  font-size: 29px;
}

.module8_course .provision_law {
  position: relative;
  border: 1px solid #414042;
  padding: 0px 20px;
  margin: 10% 0px;
}

.module8_course .provision_law h2 {
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #fff;
  width: 80%;
  padding: 9px 14px;
  height: 100%;
}

.module8_course .provision_law p {
  font-weight: normal;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
}

.module8_course .provision_law h5 {
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #1a4790;
  text-transform: uppercase;
}

.module8_course .provision_law .odd_color {
  background-color: #dadcd9;
  padding: 10px 25px;
}

.module8_course .provision_law .even_color {
  background-color: #dadcd940;
  padding: 10px 25px;
}

.module8_course .provision_law .definition_drug h6 {
  font-weight: bold;
  font-size: 22.18px;
  line-height: 28px;
  text-transform: uppercase;
  color: #414042;
}

.module8_course .provision_law .definition_drug p {
  font-size: 20px;
}

.module8_course table {
  width: 100%;
  text-align: center;
}

.module8_course table thead tr {
  border: 1px solid black;
}

.module8_course table thead th {
  border-right: 1px solid black !important;
  vertical-align: top;
}

.module8_course table th {
  padding: 10px;
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.module8_course table tbody tr {
  border: 1px solid black;
}

.module8_course table tbody td {
  border-right: 1px solid black !important;
}

.module8_course table td {
  padding: 10px;
  font-size: 16px;
  text-align: center;
  color: black !important;
}

.module8_course .provision_law .provision_law_img img {
  max-width: 100%;
}

.module8_course .law_effect {
  position: relative;
  border: 1px solid #414042;
  padding: 0px 20px;
  margin: 10% 0px;
}

.module8_course .law_effect h6 {
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #1a4790;
}

.module8_course .law_effect .same_alcohol_head {
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #ffffff;
  width: 550px;
  height: 64px;
  padding: 4px 15px;
}

.module8_course .law_effect .same_alcohol_head:after {
  height: 64px;
  right: -16px;
  width: 32px;
}

.module8_course .law_effect ul li {
  font-weight: normal;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
}

.module8_course .law_effect ul li span {
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
}

.module8_course .law_effect .effect_para {
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
}

.module8_course .law_effect .law_effect_img img {
  max-width: 100%;
}

.module8_course .law_effect .law_effect_img {
  width: 120%;
  position: relative;
  right: 5rem;
  bottom: 1rem;
}

.module8_course .traffic_law h4.risk_head {
  text-transform: uppercase;
  background: #1a4790;
  height: 46px;
  position: relative;
  width: 661px;
  margin-top: -3%;
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #ffffff;
  padding: 8px 14px;
}

.module8_course .traffic_law h4.risk_head:after {
  background: #1a4790;
  content: "";
  height: 46px;
  position: absolute;
  right: -28px;
  top: 0;
  transform: skew(25deg);
  width: 77px;
}

.module8_course .penalties_div {
  border: 1px solid #414042;
  height: 254px;
}

.module8_course .penalties_div h4 {
  font-weight: bold;
  font-size: 23.18px;
  line-height: 28px;
  color: #414042;
  text-align: center;
  background: #fff;
  width: 63%;
  margin: -6% auto 0px;
}

.module8_course .penalties_div table {
  margin-bottom: 0px;
}

.module8_course .penalties_div table tbody td {
  padding: 10px 15px;
  font-weight: normal;
  font-size: 24.61px;
  line-height: 30px;
  color: #ffffff !important;
  text-align: left;
}

.module8_course .penalties_div table tbody .penalty_one {
  background: #808285;
}

.module8_course .penalties_div table tbody .penalty_two {
  background: #414042;
}

.module8_course .penalties_div .table tr {
  border-bottom: 0px;
}

.module8_course .penalties_div .table {
  border-collapse: separate;
  border-spacing: 0px 6px;
}

.module8_course .drug_class .table_title h5 {
  color: #212529;
  font-size: 21px;
  text-align: center;
  border: 1px solid black;
  text-decoration: underline;
  padding: 8px;
  margin-bottom: 40px;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .module8_course .module_drugs .drugs_content h1 {
    font-size: 60.84px;
    line-height: 73px;
  }

  .module8_course .alcohol_drugs .drug_img {
    bottom: -10%;
    width: 121px;
  }

  .module8_course .drive_alcohol_img {
    width: 303px;
  }

  .module8_course .penalties_div {
    height: 316px;
    margin: 50px 0px;
  }

  .module8_course .traffic_law .drug_content_div {
    width: 100%;
    height: 70px;
  }

  .module8_course .traffic_law .tpyes_drug p {
    font-size: 21.18px;
  }

  .module8_course .nys_vehicle .vehicle_traffic {
    height: 100%;
    width: 92%;
    margin-top: -20%;
  }

  .module8_course .nys_vehicle .vehicle_traffic:after {
    height: 100%;
    right: -36px;
  }

  .module8_course .nys_vehicle h4.risk_head {
    width: 550px;
  }

  .module8_course .nys_vehicle h4.risk_head_4 {
    height: 100%;
    width: 90%;
    padding: 16px;
  }

  .module8_course .nys_vehicle h4.risk_head_4:after {
    height: 100%;
  }

  .module8_course .leandra_law h4 {
    width: 550px;
  }

  .module8_course .provision_law h2 {
    height: 100%;
  }

  .module8_course .same_alcohol_head:after {
    height: 100%;
    width: 50px;
    right: -25px;
  }

  .module8_course .traffic_law .same_traffic_head {
    height: 150px;
    width: 550px;
    margin-top: -15%;
  }

  .module8_course .traffic_law .same_traffic_head:after {
    height: 150px;
    right: -36px;
  }

  .module8_course .traffic_law h2.high_risks_head {
    width: 550px;
    height: 80px;
    margin-top: -10%;
  }

  .module8_course .traffic_law h2.high_risks_head:after {
    height: 80px;
    width: 50px;
    right: -25px;
  }

  .module8_course .drug_use .same_alcohol_head {
    height: 100%;
  }

  .module8_course .drug_use .same_alcohol_head::after {
    width: 100%;
  }

  .module8_course .person_example h2:after {
    height: 110px;
    right: -25px;
    width: 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .module8_course .drug_class table {
    width: 100%;
  }

  .module8_course .module_drugs .drugs_content h1 {
    font-size: 50.84px;
    line-height: 65px;
  }

  .module8_course .module_drugs {
    height: 550px;
  }

  .module8_course .alcohol_drugs .same_alcohol_head {
    width: 90%;
  }

  .module8_course .alcohol_drugs .alcohol_div .alcohol_img img {
    max-width: 73%;
    float: right;
  }

  .module8_course .alcohol_drugs .drug_img {
    bottom: -2%;
  }

  .module8_course .alcohol_drugs .drug_img img {
    max-width: 73%;
    float: right;
  }

  .module8_course .traffic_law .drug_content_div {
    width: 150px;
    padding: 10px 5px;
  }

  .module8_course .traffic_law .tpyes_drug p {
    font-size: 15.18px;
    margin-bottom: 0px;
  }

  .module8_course .traffic_law h4.risk_head {
    width: 80%;
  }

  .module8_course .traffic_law .same_traffic_head {
    width: 91%;
  }

  .module8_course .person_example .pound_img .image2 {
    width: 331px;
  }

  .module8_course .law_effect {
    margin: 16% 0px;
  }

  .module8_course .law_effect ul li,
  .module8_course ul li,
  .module8_course .leandra_law p,
  .module8_course .traffic_law .main_para {
    font-size: 20.18px;
    line-height: 25px;
  }

  .module8_course .penalties_div h4 {
    font-size: 18.18px;
    width: 82%;
  }

  .module8_course .person_example h2 {
    font-size: 23.24px;
    line-height: 30px;
  }

  .module8_course .drive_alcohol_img {
    position: relative;
    margin: auto;
  }

  .module8_course .drive_alcohol ul li {
    width: 100%;
  }

  .module8_course .drive_alcohol .overlap_content {
    width: 100% !important;
  }

  .module8_course .drug_use .same_alcohol_head {
    background-image: url("../../../../Images/module8/borderside.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: transparent;
    height: auto;
  }

  .module8_course .drug_use .same_alcohol_head:after,
  .module8_course .drug_use .same_alcohol_head:before {
    display: none;
  }

  .module8_course .drug_use .physical_img {
    width: auto;
  }

  .module8_course .penalties_div {
    height: auto;
  }

  .module8_course .person_example h2:after {
    height: 100%;
    width: 50px;
    right: -25px;
  }

  .module8_course .nys_vehicle .vehicle_traffic {
    height: 100%;
    width: 80%;
    margin-top: -15%;
  }

  .module8_course .nys_vehicle .vehicle_traffic:after {
    height: 100%;
    right: -36px;
  }

  .module8_course .nys_vehicle h4.risk_head {
    width: 550px;
  }

  .module8_course .nys_vehicle h4.risk_head_4 {
    height: 100%;
    width: 80%;
    padding: 16px;
  }

  .module8_course .nys_vehicle h4.risk_head_4:after {
    height: 100%;
    width: 50px;
    right: -20px;
  }

  .module8_course .leandra_law h4 {
    width: 550px;
  }

  .module8_course .provision_law h2 {
    height: 100%;
  }

  .module8_course .same_alcohol_head:after {
    height: 100%;
    width: 50px;
    right: -25px;
  }

  .module8_course .traffic_law .same_traffic_head {
    height: 150px;
    width: 550px;
    margin-top: -15%;
  }

  .module8_course .traffic_law .same_traffic_head:after {
    height: 150px;
    right: -36px;
  }

  .module8_course .traffic_law h2.high_risks_head {
    width: 550px;
    height: 80px;
    margin-top: -10%;
  }

  .module8_course .traffic_law h2.high_risks_head:after {
    height: 80px;
    width: 50px;
    right: -25px;
  }
}

@media screen and (max-width: 767px) {
  .module8_course .alcohol_drugs,
  .module8_course .drug_use,
  .module8_course .person_example,
  .module8_course .law_effect,
  .module8_course .zero_tolerance,
  .module8_course .provision_law {
    padding: 0px;
  }

  .module8_course .module_drugs .drugs_content p {
    font-size: 29.21px;
  }

  .module8_course .module_drugs {
    background-position: center;
    background-size: 100% 75%;
    height: 350px;
  }

  .module8_course .module_drugs .module_logo {
    right: 5%;
    top: 14%;
    width: 130px;
  }

  .module8_course .module_drugs .drugs_content h1 {
    font-size: 21px;
    line-height: 28px;
  }

  .module8_course .alcohol_drugs .same_alcohol_head {
    width: 85%;
    height: 100%;
    font-size: 23px;
    line-height: 33px;
    margin: -4% 5px 0px;
    background-image: url("../../../../Images/module8/borderbg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: transparent;
  }

  .module8_course .alcohol_drugs .same_alcohol_head:after {
    display: none;
  }

  .module8_course .alcohol_drugs p {
    font-size: 22.19px;
  }

  .module8_course .same_alcohol_head:after {
    height: 100%;
    right: -17px;
    width: 35px;
    z-index: -5;
  }

  .alcohol_img {
    text-align: center;
  }

  .module8_course .alcohol_drugs .alcohol_div .alcohol_img img {
    max-width: 70%;
  }

  .module8_course .alcohol_drugs .drug_img {
    position: relative;
    width: 155px;
    margin: auto;
  }

  .module8_course .drive_alcohol ul li {
    width: 100%;
  }

  .module8_course .drive_alcohol ul .overlap_content {
    width: 100%;
  }

  .module8_course .drug_use .physical_img {
    width: auto;
    margin-bottom: 20px;
  }

  .module8_course .person_example h2 {
    font-size: 20.24px;
    line-height: 28px;
  }

  .module8_course .zero_tolerance h2 {
    width: 100%;
  }

  .module8_course .drug_class h5 {
    font-size: 21.98px;
    line-height: 32px;
  }

  .module8_course ul li,
  .module8_course .law_effect ul li {
    font-size: 20.19px;
    line-height: 25px;
  }

  .module8_course .traffic_law {
    padding: 50px 0px;
  }

  .module8_course .traffic_law .same_traffic_head {
    width: 100%;
  }

  .module8_course .drive_alcohol_img {
    position: relative;
    width: auto;
    right: 0px;
  }

  .module8_course .drug_use .same_alcohol_head {
    font-size: 21px;
    /* line-height: 31px; */
    height: 100%;
    width: 90%;
    text-align: left;
    padding: 8px;
    background-image: url("../../../../Images/module8/borderbg.png");
    /* background-image: url("../../../../Images/module8/borderside.png"); */
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: transparent;
  }

  .module8_course .drug_use .same_alcohol_head:before {
    display: none;
  }

  .module8_course .drug_use .same_alcohol_head:after {
    display: none;
  }

  .module8_course .drug_use .note_drug {
    margin: 0px;
  }

  .module8_course .drug_use .physical_para {
    font-size: 20.19px;
    line-height: 23px;
  }

  .module8_course .alcohol_drugs h6 {
    font-size: 20.19px;
    line-height: 25px;
  }

  .module8_course .person_example .pound_img .image2 {
    width: 270px;
  }

  .module8_course .vehicle_undersection .same_vehicle_head {
    width: 100%;
    font-size: 23px;
    line-height: 23px;
    height: 100%;
    padding: 10px 20px;
    margin-top: -5%;
    background-image: url("../../../../Images/module8/borderbg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: transparent;
  }

  .module8_course .vehicle_undersection {
    padding: 0;
  }

  .module8_course .vehicle_undersection .same_vehicle_head:after {
    display: none;
  }

  .module8_course .overview_content p,
  .module8_course .vehicle_undersection .vehiclesection,
  .module8_course .zero_tolerance p {
    font-size: 20.19px;
    line-height: 26px;
  }

  .module8_course .zero_tolerance h2 {
    height: 100%;
    font-size: 24px;
    line-height: 33px;
    background-image: url("../../../../Images/module8/borderbg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: transparent;
  }

  .module8_course .zero_tolerance h2:after {
    display: none;
  }

  .module8_course .zero_tolerance .law_note {
    position: relative;
    bottom: 0px;
    width: 100%;
  }

  .module8_course .law_effect .same_alcohol_head {
    width: 100%;
  }

  .module8_course .law_effect .same_alcohol_head {
    width: 100%;
    font-size: 20px;
    line-height: 28px;
    height: auto;
    background-image: url("../../../../Images/module8/borderbg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: transparent;
  }

  .module8_course .law_effect .same_alcohol_head:after {
    display: none;
  }

  .module8_course .penalties_div h4 {
    font-size: 17.18px;
    line-height: 26px;
    width: 67%;
  }

  .module8_course .penalties_div {
    margin-top: 50px;
    height: auto;
  }

  .module8_course .leandra_law h2,
  .module8_course .provision_law h2 {
    width: 100%;
    font-size: 23px;
    line-height: 23px;
    padding: 1px 14px;
  }

  .module8_course .leandra_law h2,
  .module8_course .provision_law h2 {
    background-image: url("../../../../Images/module8/borderbg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: transparent;
    height: 35px;
    padding: 5px 12px;
  }

  .module8_course .traffic_law .same_traffic_head {
    background-image: url("../../../../Images/module8/borderbg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: transparent;
    font-size: 21px;
    height: auto;
  }

  .module8_course .leandra_law p {
    font-size: 20.18px;
    line-height: 25px;
  }

  .module8_course .leandra_law {
    padding: 0px;
  }

  .module8_course .traffic_law .same_traffic_head:after,
  .module8_course .traffic_law h4.risk_head:after,
  .module8_course .leandra_law h2:after,
  .module8_course .provision_law h2:after {
    display: none;
  }

  .module8_course .traffic_law h4.risk_head {
    width: 100%;
    background-image: url("../../../../Images/module8/borderbg.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: transparent;
    font-size: 20px;
    height: 60px;
    padding: 2px 13px;
  }

  .module8_course .traffic_law .drug_content_div {
    position: relative;
    width: 100%;
    bottom: 0px;
    margin-bottom: 12px;
    padding: 6px 5px;
  }

  .module8_course .traffic_law .tpyes_drug p {
    font-size: 16.18px;
    line-height: 25px;
  }

  .module8_course .drug_class {
    padding: 0px 15px;
  }

  .module8_course .traffic_law .tpyes_drug h6 {
    margin: 30px 0px 20px 0px;
  }

  .module8_course .person_example h2:after {
    height: 100%;
    width: 50px;
    right: -25px;
  }

  .module8_course .nys_vehicle .vehicle_traffic {
    height: 100%;
    width: 75%;
    margin-top: -20%;
  }

  .module8_course .nys_vehicle .vehicle_traffic:after {
    height: 100%;
    right: -36px;
  }

  .module8_course .nys_vehicle h4.risk_head {
    width: 350px;
  }

  .module8_course .nys_vehicle h4.risk_head_4 {
    height: 100%;
    width: 80%;
    padding: 12px;
  }

  .module8_course .nys_vehicle h4.risk_head_4:after {
    height: 100%;
    width: 50px;
    right: -25px;
  }

  .module8_course .leandra_law h4 {
    width: 360px;
  }

  .module8_course .provision_law h2 {
    height: 70px;
  }

  .module8_course .same_alcohol_head:after {
    height: 100%;
  }

  .module8_course .traffic_law .same_traffic_head {
    height: 170px;
    width: 380px;
    margin-top: -15%;
  }

  .module8_course .traffic_law .same_traffic_head:after {
    height: 170px;
    right: -36px;
  }

  .module8_course .traffic_law h2.high_risks_head {
    width: 350px;
    height: 80px;
    margin-top: -15%;
  }

  .module8_course .traffic_law h2.high_risks_head:after {
    height: 80px;
    width: 50px;
    right: -25px;
  }
}

@media screen and (max-width: 576px) {
  .module8_course .person_example h2:after {
    height: 100%;
    width: 60%;
    right: -35px;
  }

  .module8_course .person_example {
    padding: 0px 15px;
    height: 100%;
  }

  .module8_course .nys_vehicle .vehicle_traffic {
    height: 100%;
    width: 70%;
    margin-top: -30%;
    font-size: 26px;
    line-height: 28px;
    margin-bottom: 10%;
  }

  .module8_course .nys_vehicle .vehicle_traffic:after {
    height: 100%;
    right: -45px;
    width: 100px;
  }

  .module8_course .vehicle_law {
    padding: 50px 0 0;
  }

  .module8_course .nys_vehicle .vehicle_traffic span {
    font-size: 20px;
  }

  .module8_course .nys_vehicle h4.risk_head {
    width: 80%;
    height: 100%;
    padding: 15px 10px;
  }

  .module8_course .nys_vehicle h4.risk_head:after {
    height: 100%;
  }

  .module8_course .nys_vehicle h4.risk_head_4 {
    height: 100%;
    width: 80%;
    padding: 12px;
  }

  .module8_course .nys_vehicle h4.risk_head_4:after {
    height: 100%;
    width: 65px;
    right: -32px;
  }

  .module8_course .leandra_law h4 {
    width: 225px;
    height: 100px;
  }

  .module8_course .leandra_law h4:after {
    height: 100px;
  }

  .module8_course .provision_law h2 {
    height: 100%;
  }

  .module8_course .same_alcohol_head:after {
    height: 100%;
  }

  .module8_course .traffic_law .same_traffic_head {
    height: 200px !important;
    width: 300px;
    margin-top: -25%;
    font-size: 26px !important;
  }

  .module8_course .traffic_law .same_traffic_head span {
    font-size: 20px;
  }

  .module8_course .traffic_law h2.high_risks_head {
    width: 70%;
    height: 100%;
    margin-top: -20%;
    font-size: 23px;
  }

  .module8_course .traffic_law h2.high_risks_head:after {
    height: 100%;
    width: 60px;
    right: -30px;
  }

  .module8_course .same_alcohol_head {
    width: 100%;
  }

  .module8_course .person_example table thead th,
  .module8_course .person_example table tr td {
    font-size: 12.19px;
    line-height: 16px;
  }

  .module8_course .penalties_div table tbody td {
    font-size: 18.61px;
    line-height: 27px;
  }

  .module8_course .traffic_law .same_traffic_head {
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-color: transparent;
    font-size: 21px;
    height: 200px;
  }

  .module8_course .traffic_law .tpyes_drug p {
    font-size: 14.18px;
    line-height: 25px;
  }

  .module8_course .traffic_law .main_para {
    font-size: 20.18px;
    line-height: 25px;
  }

  .module8_course .zero_tolerance h2 {
    height: 100%;
  }

  .module8_course .person_example .pound_img .image2 {
    width: 200px;
  }
}

.h5p-iframe-module-8 iframe {
  width: 100%;
  height: 830px;
}

@media screen and (max-width: 425px) {
  .h5p-iframe-module-8 iframe {
    height: 990px;
  }
  .module9_page1 .safe_tech_deve h3 {
    line-height: 15px;
    margin-top: 40px;
  }
}

@media screen and (min-width: 426px) and (max-width: 460px) {
  .h5p-iframe-module-8 iframe {
    height: 1090px;
  }
}

@media screen and (min-width: 461px) and (max-width: 767px) {
  .h5p-iframe-module-8 iframe {
    height: 735px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  .h5p-iframe-module-8 iframe {
    height: 760px;
  }
}

@media screen and (min-width: 1025px) and (max-width: 1440px) {
  .h5p-iframe-module-8 iframe {
    height: 845px;
  }
}

.para-content {
  cursor: auto;
  padding: 0.5em 0;
  margin: 1em;
  line-height: 1em;
  color: #1a73d9;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
}
.press-btn {
  display: block;
  line-height: 1em;
  cursor: pointer;
  font-size: 16px;
  border: none;
  background-color: #1a73d9;
  color: #fff;
  border-radius: 2em;
  padding: 0.5em 1em;
  margin: 1em auto;
  box-sizing: border-box;
  max-width: 90%;
}

.h5p-content-note {
  color: red;
  font-size: 22px;
  font-weight: 600;
}

table#h5p_content_navigate img {
  display: flex;
  width: 100%;
  object-fit: contain;
}

.module8_course .drug_class table tbody tr td p {
  text-align: center;
}

.title-text-wrapper {
  padding: 10px !important;
  font-size: 20px !important;
  overflow: hidden !important;
  font-weight: bold !important;
  text-overflow: ellipsis !important;
  text-align: left !important;
}

.face-char-img {
  cursor: pointer !important;
}

.face-char-img.clicked {
  border: 2px solid green;
}
#h5p_content_navigate .col-md-3.col-sm-6.col-12 {
  display: flex;
  flex-direction: column;
}

.module8_course {
  .example-scenario-1 {
    width: 100%;
  }
  .example-scenario-2 {
    width: 100%;
  }
  .example-scenario-3 {
    width: 100%;
  }
  .example-scenario-4 {
    width: 100%;
  }
  .example-scenario-5 {
    width: 100%;
  }
  .example-scenario-6 {
    width: 100%;
  }
  .example-scenario-7 {
    width: 100%;
  }
  .example-scenario-8 {
    height: 95%;
  }

  .example-scenario-8-div {
    display: flex;
    justify-content: center;
  }
}
