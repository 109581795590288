/* quiz list file */
.quizListPara p {
  text-align: left;
  font: normal normal normal 12px/20px Source Sans Pro;
  letter-spacing: 0px;
  color: #4d4f5c;
  opacity: 0.6;
  margin-bottom: 0;
}
/* end quiz list file */

.active.classActive {    color: #2ef100 !important;}
.noDataChart .apexcharts-pie-area.apexcharts-donut-slice-0 {fill: #cdcdcd !important; width: 267px; height: 267px;}
.noDataChart  .apexcharts-datalabels text {font-size: 0 !important; position: relative;}
.noDataChart  .apexcharts-inner.apexcharts-graphical circle {fill: #e2e2e2 !important; position: relative;}
/* .noDataChart  .apexcharts-pie {content: ''; position: absolute; border: 50px solid red; border-radius: 100%; z-index: 999;} */
.zeroDisplay {width: auto; height: 20px; position: absolute; z-index: 99; bottom: 26px; font-size: 16px; font-weight: bold; color: #fff;}
@media screen and (max-width:767px) {
  .width_data{
    width: auto !important;
  }
  .has_mob_search{
    width:100%;
  }
}