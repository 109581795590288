.mainSection.marginmainTop .breadcrumbBg {
  top: 73px;
}
.mainSection.marginmainTop {
  top: 100px;
  position: relative;
}
.allCourses .card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 0px 11px #00000029;
  border: 1px solid #e9e9f0;
  border-radius: 6px;
  margin-bottom: 15px;
}
.allCourses .card .embed-responsive iframe {
  border-radius: 0px;
}
.allCourses .card .orderId {
  font: normal normal normal 10px/25px Source Sans Pro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.6;
  float: right;
}
.allCourses .card .card-title {
  text-align: left;
  font: normal normal bold 17px/25px Source Sans Pro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}
.allCourses .card .btn.btnSign,
.allCourses .card .btn.btnSign:hover {
  font: normal normal 600 20px/24px Source Sans Pro;
  letter-spacing: 1px;
  color: #ffffff;
  padding: 6px 12px;
}
.expiryDate {
  font: normal normal normal 16px/22px Source Sans Pro;
  letter-spacing: 0px;
  color: #ff0000;
  opacity: 0.69;
  float: right;
}
.progressBar p {
  text-align: left;
  font: normal normal 600 12px/25px Source Sans Pro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.6;
}
.progressBar span {
  float: right;
  font: normal normal 600 12px/25px Source Sans Pro;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.6;
}
.progressBar .progress {
  background-color: #cecece;
  border-radius: 11px;
}
.progressBar .progress-bar {
  border-radius: 100px;
}
.progressBar .bg-success {
  background-color: #5aa800 !important;
  height: 100% !important;
}
@media screen and (max-width: 1200px){
  .expiryDate{
    font: normal normal normal 13px/22px Source Sans Pro;
  }
  .allCourses .card
  .btn.btnSign{
    font: normal normal 600 18px/24px Source Sans Pro;
  }
}
@media screen and (max-width: 767px) {
  .allCourses .card .btn.btnSign {
    padding: 8px 19px !important;
    font: normal normal 600 20px/24px Source Sans Pro;
    letter-spacing: 1px;
  }
  .expiryDate{
    font: normal normal normal 16px/22px Source Sans Pro;
  }

}

@media screen and (max-width: 576px) {
  .mainSection .breadcrumbBg {
    top: 50px !important;
  }
  .mainSection.marginmainTop {
    top: 100px !important;
  }

  .expiryDate{
    font: normal normal normal 14px/22px Source Sans Pro;
  }

}


/*course successfull modal*/
.course_complete.thankyou_modal .modal-content .modal-title{
  text-align: left;
  font: normal normal 600 25px/32px Source Sans Pro;
  letter-spacing: 0px;
  color: #000000;
}
.course_complete.thankyou_modal label{
  text-align: left;
  font: normal normal 600 17px/22px Source Sans Pro;
  letter-spacing: 0px;
  color: #000000;
}
.btnSign.btn_modal{
  padding:11px 30px !important;
}