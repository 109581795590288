/*new module1*/
.module_intro {
  font-family: "Titillium Web", sans-serif !important;
}

.module1_course .module_intro {
  position: relative;
  background-image: url("../../../../Images/module/introbg.jpg");
  background-repeat: no-repeat;
  width: 100%;
  padding: 26% 0px 2%;
  height: 100%;
  background-position: top;
}

.module1_course .border_div {
  border-bottom: 1px solid #231f20;
  z-index: 99;
  width: 65%;
  margin-left: 6%;
}

.module1_course .intro_img img {
  max-width: 100%;
  width: 450px;
}

.module1_course .intro_content {
  padding: 0px;
}

.module1_course .intro_content h1 {
  font-weight: 600;
  font-size: 64.32px;
  line-height: 77px;
  color: #414042;
  font-family: "Titillium Web", sans-serif !important;
}

.module1_course .intro_content h1 span {
  display: block;
  text-transform: uppercase;
  background: #1a4790;
  font-weight: bold;
  font-size: 72.28px;
  line-height: 87px;
  color: #f6c315;
  padding: 10px 17px;
  width: 543px;
  font-family: "Titillium Web", sans-serif !important;
}

.module1_course .intro_content h4 {
  font-size: 30.24px;
  line-height: 36px;
  font-weight: bold;
  color: #414042;
  font-family: "Titillium Web", sans-serif !important;
}

.common_head {
  background: #1a4790;
  box-sizing: border-box;
  color: #f6c315;
  font-size: 31.27px;
  line-height: 41px;
  font-weight: bold;
  height: 44px;
  padding: 1px 11px;
  position: relative;
  width: 264px;
  text-transform: uppercase;
}

.common_head:after {
  background: #fff;
  content: "";
  height: 56px;
  position: absolute;
  right: -13px;
  top: 0;
  -webkit-transform: skew(-26deg);
  -moz-transform: skew(-26deg);
  transform: skew(-26deg);
  width: 27px;
}

.module_purpose .common_head {
  width: 498px;
  height: 86px;
}

.module_purpose .common_head:after {
  height: 86px;
  width: 43px;
  right: -21px;
}

.module1_course .purpose_img img {
  max-width: 100%;
  width: 600px;
}

.module1_course .module_list li {
  position: relative;
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #414042;
  list-style-type: none;
  padding: 0px 17px;
  margin-bottom: 10px;
}

.module1_course .module_list li:before {
  position: absolute;
  content: ".";
  color: #1a4790;
  font-size: 50px;
  left: 0px;
  top: -13px;
}

.module1_course .module_purpose p {
  font-weight: 600;
  font-size: 30.24px;
  line-height: 36px;
  color: #414042;
}

.module1_course .module_requirement {
  background-color: #f6c315;
}

.module1_course .module_requirement h2 {
  font-weight: bold;
  font-size: 34.27px;
  line-height: 41px;
  color: #1a4790;
  text-transform: uppercase;
  padding: 10px;
}

.module1_course .law_div {
  padding: 10px 0px 0px;
  margin: 10px 0px;
}

.module1_course .law_div_img img {
  width: 50px;
}

.module1_course .law_div_content p {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #414042;
}

.module1_course .law_div_border {
  border-right: 0.5px solid #414042;
}

.module1_course .module_valid .common_head {
  width: 667px;
}

.module1_course .module_valid_img img {
  width: 50px;
}

.module1_course .module_valid_detail p {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #414042;
  margin: 0px;
  padding: 13px 0px;
}

.module1_course .module_vehicle_law {
  padding: 10px 0px;
  border-bottom: 0.5px solid #414042;
}

.module1_course .module_vehicle_law .vehicle_traffic h3 {
  font-weight: 300;
  font-size: 80.63px;
  line-height: 97px;
  color: #f6c315;
  text-transform: uppercase;
}

.module1_course .module_vehicle_law .vehicle_traffic img {
  max-width: 100%;
}

.module1_course .module_vehicle_law h2 {
  width: 498px;
  height: 86px;
}

.module1_course .module_vehicle_law h2:after {
  height: 86px;
  right: -24px;
  width: 52px;
}

.module1_course .module_vehicle_law p {
  font-weight: 600;
  font-size: 30.24px;
  line-height: 36px;
  color: #414042;
}

.module1_course .module_goals .common_head {
  width: 667px;
}

.module1_course .module_learn_rules {
  position: relative;
  background-color: #1a4790;
  /* background-image: url("../../../../Images/module/learn_rule.png"); */
  padding: 15px;
  /* background-size: 34% 100%;
    background-repeat: no-repeat;
    background-position: right top; */
}

.module1_course .module_learn_rules .module_list li {
  color: #fff;
}

.module1_course .module_learn_rules .module_list li:before {
  color: #f6c315;
}

.module1_course .module_learn_rules h3 {
  font-weight: bold;
  font-size: 34.27px;
  line-height: 41px;
  color: #f6c315;
  text-transform: uppercase;
}

.module1_course .module_learn_rules p {
  font-weight: 600;
  font-size: 30.24px;
  line-height: 36px;
  color: #ffffff;
}

.module1_course .module_pdf img {
  width: 70px;
  margin-bottom: 15px;
}

.module1_course .module_learn_rules h5 {
  font-weight: bold;
  font-size: 34.27px;
  line-height: 41px;
  color: #f6c315;
  text-transform: uppercase;
}

.module1_course .module_learn_img {
  position: absolute;
  right: 0px;
}

.module1_course .module_learn_rules .module_learn_img img {
  /* max-width: 100%;
  width: 520px; */
  margin: -30px 0px;
  float: right;
}

.module1_course .module_learn_rules .module_learn_border {
  border-right: 0.5px solid #ffffff;
}

.module1_course .module_learn_rules .module_learn_head .accordion {
  background: transparent;
  padding: 0px;
}

.module1_course .module_learn_rules .module_learn_head .card {
  background: transparent;
  padding: 0px;
  border: none;
  box-shadow: none;
}

.module1_course .module_learn_rules .module_learn_head .card-header {
  background: transparent;
  padding: 0px;
}
.module1_course .module_learn_rules .module_learn_head .card .button-down {
  top: 0px;
  width: 8%;
  height: 2.3rem;
}
.module1_course .module_learn_rules .module_learn_head .card .button-up {
  top: 0px;
  width: 8%;
  height: 2.3rem;
}

.module1_course .module_learn_rules .module_learn_head .card .button-up::after {
  top: 16px !important;
  left: 12px;
}

.module1_course
  .module_learn_rules
  .module_learn_head
  .card
  .button-down::after {
  top: 12px !important;
  left: 12px;
}

.module1_course .module_learn_rules .module_learn_head .card .button-up::after,
.module1_course
  .module_learn_rules
  .module_learn_head
  .card
  .button-down::after {
  width: 10px;
  height: 10px;
}

.module1_course .module_learn_rules .module_learn_head .card-body {
  padding: 0px !important;
}

.module1_course .module_learn_rules .module_learn_head h4 {
  font-weight: bold;
  font-size: 34.27px;
  line-height: 41px;
  color: #f6c315;
}

.module1_course .module_learn_rules .module_learn_head h6 {
  font-weight: 600;
  font-size: 30.24px;
  line-height: 36px !important;
  color: #ffffff;
}

.module1_course .module_learn_rules .module_learn_head p {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #ffffff;
}

.module1_course .module_probation {
  position: relative;
  background-color: #f6c315;
  height: 500px;
  margin-bottom: 20px;
}

.module1_course .module_probation h2 {
  font-weight: bold;
  font-size: 34.27px;
  line-height: 41px;
  color: #1a4790;
}

.module1_course .module_probation img {
  max-width: 100%;
  width: 510px;
}

.module1_course .module_probation_img {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

.module1_course .module_probation_img img {
  max-width: 100%;
  float: right;
  margin: 8px 0px 0px;
  -webkit-box-shadow: 0 10px 6px -6px #777;
  -moz-box-shadow: 0 10px 6px -6px #777;
  box-shadow: 0 10px 6px -6px #777;
}

.module1_course .module_probation .module_list li {
  margin-bottom: 7px;
}

.module1_course .module_suspension .common_head {
  width: 100%;
  height: 93px;
  padding: 4px 17px;
  /* height: 56px; */
}

.module1_course .module_suspension .common_head:before {
  background: #fff;
  content: "";
  height: 93px;
  position: absolute;
  left: -33px;
  top: 0;
  transform: skew(34deg);
  width: 70px;
}

.module1_course .module_suspension .common_head:after {
  transform: skew(-38deg);
  width: 71px;
  height: 93px;
  right: -37px;
}

.module1_course .module_suspension_img {
  overflow: hidden;
}

.module1_course .module_suspension_img img {
  max-width: 100%;
}

.module1_course .module_suspension {
  border-bottom: 0.5px solid #414042;
  padding-bottom: 10px;
}

.module1_course .module_subject {
  position: relative;
  height: 350px;
}

.module1_course .module_subject .module_list li {
  margin-bottom: 15px;
  font-weight: 600;
  font-size: 30.24px;
  line-height: 36px;
}

.module1_course .module_subject .common_head {
  width: 760px;
  height: 83px;
}

.module1_course .module_subject .common_head:after {
  height: 83px;
  width: 45px;
  right: -22px;
}

.module1_course .module_subject_img {
  position: absolute;
  right: 0px;
  top: 60px;
}

.module1_course .module_subject_img img {
  max-width: 100%;
  width: 350px;
}

.module1_course .module_logo img {
  width: 200px;
  max-width: 100%;
}

/*Background Image*/

.module1_course .module_suspension_img {
  animation: fadeIn 2s infinite alternate;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
}

/*bell*/

.module1_course .law_bell {
  width: 2rem;
  animation: bellshake 4s ease infinite;
  backface-visibility: hidden;
  transform-origin: top right;
}

@keyframes bellshake {
  0% {
    transform: rotate(0);
  }

  15% {
    transform: rotate(5deg);
  }

  30% {
    transform: rotate(-5deg);
  }

  45% {
    transform: rotate(4deg);
  }

  60% {
    transform: rotate(-4deg);
  }

  75% {
    transform: rotate(2deg);
  }

  85% {
    transform: rotate(-2deg);
  }

  92% {
    transform: rotate(1deg);
  }

  100% {
    transform: rotate(0);
  }
}

.module1_course .pdf_img {
  transform: translatey(0px);
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-15px);
  }

  100% {
    transform: translatey(0px);
  }
}

/*certificate*/

.module1_course .law_traffic {
  transform: translatey(0px);
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-15px);
  }

  100% {
    transform: translatey(0px);
  }
}

/*calendar*/
.module1_course .module_valid_img.calendar {
  animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  -moz-animation: bounce 2s infinite;
  -o-animation: bounce 2s infinite;
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-20px);
  }

  60% {
    -webkit-transform: translateY(-15px);
  }
}

@-moz-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -moz-transform: translateY(0);
  }

  40% {
    -moz-transform: translateY(-20px);
  }

  60% {
    -moz-transform: translateY(-15px);
  }
}

@-o-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -o-transform: translateY(0);
  }

  40% {
    -o-transform: translateY(-20px);
  }

  60% {
    -o-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-20px);
  }

  60% {
    transform: translateY(-15px);
  }
}

/* .intro_img {
  transition: all ease 1s !important;
  -webkit-animation: 1s ease-out 2s 1 slideInFrombottom4;
  animation: 1s ease-out 2s 1 slideInFrombottom4;
  position: absolute;
    bottom: 0px;
}


@keyframes slideInFrombottom4 {
  0% {
    bottom: -300px;
  }
  100% {
    bottom: 0;
  }
} */

@media screen and (min-width: 1024px) and (max-width: 1200px) {
  .module1_course .intro_content h1 {
    font-size: 52.32px;
  }
  .module1_course .intro_content h1 span {
    width: 100%;
    font-size: 58.28px;
    line-height: 70px;
  }
  .module_purpose .common_head {
    width: 469px;
  }
  .module1_course .module_learn_rules .module_learn_img img {
    width: 443px;
  }
  .module1_course .module_probation {
    height: auto;
  }
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
  .module1_course .module_probation img {
    width: 370px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .module1_course .module_intro {
    padding: 31% 0px 2%;
  }

  .module1_course .module_learn_rules .module_learn_img img {
    width: 350px;
  }

  .module1_course .module_suspension {
    overflow: hidden;
  }

  .module1_course .module_subject_img img {
    width: 246px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .module1_course .intro_content h1 {
    font-size: 39.32px;
    line-height: 49px;
  }

  .module1_course .intro_content h1 span {
    width: 100%;
    font-size: 43.28px;
    line-height: 53px;
  }

  .module1_course .module_vehicle_law .vehicle_traffic h3 {
    font-size: 59.63px;
    line-height: 75px;
  }

  .module1_course .module_vehicle_law h2 {
    width: 343px;
    height: 134px;
  }

  .module1_course .module_vehicle_law h2 :after {
    height: 134px;
  }

  .module1_course .module_vehicle_law h2:after {
    height: 135px;
    right: -34px;
    width: 66px;
  }
  .module1_course .module_probation {
    height: auto;
  }

  .module1_course .module_suspension .common_head {
    height: 136px;
  }

  .module1_course .module_suspension .common_head:before {
    left: -47px;
    width: 93px;
    height: 148px;
  }

  .module1_course .module_suspension .common_head:after {
    right: -51px;
    width: 103px;
    height: 148px;
  }
  .module1_course .module_subject {
    height: auto;
  }
  .module1_course .module_subject .common_head {
    width: 688px;
    height: 88px;
  }
  .module1_course .module_subject .common_head:after {
    height: 88px;
  }
  .module1_course .module_subject_img {
    top: 150px;
  }
}

@media screen and (max-width: 767px) {
  .module1_course .intro_content h1 {
    font-size: 39.32px;
    line-height: 50px;
  }

  .module1_course .law_div_border {
    border-right: none;
    border-bottom: 0.5px solid #414042;
    margin-bottom: 10px;
  }

  .module1_course .intro_content h1 span {
    width: 100%;
    font-size: 44.28px;
    line-height: 54px;
  }

  .module1_course .common_head {
    font-size: 20px;
    line-height: 32px;
  }

  .module1_course .module_purpose p {
    font-size: 23.24px;
    line-height: 34px;
  }

  .module1_course .module_purpose .common_head {
    width: 270px;
    height: 77px;
    padding: 4px 11px;
  }

  .module1_course .module_requirement h2 {
    font-size: 25.27px;
    line-height: 35px;
    padding: 0px;
  }

  .module1_course .law_div_content p {
    font-size: 16.19px;
    line-height: 25px;
  }

  .module1_course .module_list li {
    font-size: 16.19px;
    line-height: 25px;
  }

  .module1_course .module_valid .common_head {
    width: 270px;
    height: 78px;
  }

  .module1_course .module_valid .common_head:after {
    height: 80px;
    right: -24px;
    width: 44px;
  }

  .module1_course .module_valid_detail p {
    font-size: 16.19px;
    line-height: 25px;
  }

  .module1_course .module_vehicle_law h2 {
    width: 269px;
    height: 75px;
  }

  .module1_course .module_vehicle_law p {
    font-size: 16.24px;
    line-height: 27px;
  }

  .module1_course .module_vehicle_law .vehicle_traffic h3 {
    font-size: 26px;
    line-height: 43px;
  }

  .module1_course .module_goals .common_head {
    width: 270px;
    height: 73px;
  }

  .module1_course .module_goals .common_head:after {
    height: 73px;
    right: -23px;
    width: 43px;
  }

  .module1_course .module_learn_rules {
    padding: 0px;
  }

  .module1_course .module_learn_rules h3 {
    font-size: 25.27px;
    line-height: 35px;
  }

  .module1_course .module_learn_rules p {
    font-size: 17.24px;
    line-height: 27px;
  }

  .module1_course .module_learn_rules h5,
  .module1_course .module_learn_rules .module_learn_head h4 {
    font-size: 25.27px;
    line-height: 35px;
  }

  .module1_course .module_learn_rules .module_learn_head h6 {
    font-size: 19.24px;
    line-height: 27px;
  }

  .module1_course .module_learn_img,
  .module1_course .module_probation_img {
    position: relative;
  }

  .module1_course .module_subject_img {
    position: relative;
    top: 0px;
  }

  .module1_course .module_learn_rules .module_learn_img img {
    margin: 0px;
  }

  .module1_course .module_learn_rules .module_learn_border {
    border-right: none;
    border-bottom: 0.5px solid #ffffff;
  }

  .module1_course .module_probation {
    height: auto;
  }

  .module1_course .module_subject {
    height: auto;
    overflow: hidden;
  }

  .module1_course .module_subject .common_head {
    width: 297px;
    height: 111px;
    font-size: 19px;
    line-height: 35px;
  }

  .module1_course .module_subject .common_head:after {
    height: 111px;
    right: -28px;
    width: 55px;
  }

  .module1_course .module_suspension {
    overflow: hidden;
  }

  .module1_course .module_suspension .common_head:before {
    display: none;
  }

  .module1_course .module_suspension .common_head {
    height: 147px;
  }

  .module1_course .module_suspension .common_head:after {
    transform: skew(-28deg);
    width: 81px;
    height: 147px;
    right: -42px;
  }

  .module1_course .module_probation h2 {
    font-size: 25.27px;
    line-height: 35px;
  }

  .module1_course .module_suspension_img {
    width: 150px;
    text-align: center;
    margin: 0px auto 10px;
  }

  .module1_course .module_subject .module_list li {
    font-size: 16.19px;
    line-height: 25px;
  }
}
