.maintableSection{
    top:80px;
    position: relative;
}
.licensing_text{
    text-align: left;
font: normal normal bold 22px/32px Source Sans Pro;
letter-spacing: 0px;
color: #000000;
opacity: 1;
margin: 0;
position: relative;
margin-bottom: 18px;
/* margin-top: 40px; */
}
.pretextheading{
    margin-top: 40px;
}
.licensing_text:after{
    position: absolute;
    width: 60px;
    height: 2px;
    content: '';
    left: 0;
    background: #001D92;
    bottom: -18px;
}
.table_of_content{
    text-align: left;
    font: normal normal 600 16px/32px Source Sans Pro;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    background: #E9E9F0 0% 0% no-repeat padding-box;
    border-radius: 2px;
    padding: 12px 25px;
    margin: 0;

}
.accordion_divcontainer .accordion{
    padding: 0 20px;
    background: #FBFBFB 0% 0% no-repeat padding-box;
    border-radius: 2px;
}
.accordion_divcontainer .accordion>.card>.card-header{
    background: #FBFBFB 0% 0% no-repeat padding-box;
    text-align: left;
    font: normal normal normal 16px/20px Source Sans Pro;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding-left: 0;
    padding-right: 0;
}
.accordion_divcontainer .card{
    border: none;
    border-bottom: 1px solid #EEEEEE!important;
}
.accordion_divcontainer .form-check{
    display: inline-block;
}
.accordion_divcontainer .card-body{
    background: #FBFBFB 0% 0% no-repeat padding-box;
    border-top: 1px solid #EEEEEE!important;
    /* width: 93%; */
    /* margin: 0 auto; */
    padding-top: 0;
    padding-bottom: 0!important;
    
}
.accordion_divcontainer .collapse.show{
    background: #FBFBFB 0% 0% no-repeat padding-box;
}
.accordion_divcontainer input.form-check-input.position-static{
    margin-right: 13px;
}
.Transaction_text{
    text-align: left;
font: normal normal normal 13px/32px Source Sans Pro;
letter-spacing: 0px;
color: #000000;
opacity: 0.6;
margin: 0;
}
.Expiry_text{
    text-align: left;
font: normal normal normal 15px/32px Source Sans Pro;
letter-spacing: 0px;
color: #FF0000;
opacity: 0.69;
margin: 0;
}
.card_licensing_text{
    padding: 0 14px 20px 14px;
}
.licensing_container{
    background: #FFFFFF 0% 0% no-repeat padding-box!important;
border: 1px solid #E9E9F0!important;
border-radius: 6px!important;
box-shadow: none;
}
.livechat_img{
    margin-right: 10px;
}
.licensing_container .progress{
    height: 10px;
}
.licensing_container p{
    margin: 0;
}
.Accordiontextcontainer p{
    background: none!important;
    padding: 5px 25px;
    border-top: 1px solid #E9E9F0!important;
    text-align: left;
    font: normal normal normal 14px/18px Source Sans Pro;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    padding: 12px!important;
}
.Accordiontextcontainer p:first-child{
    border-top: 0px solid #E9E9F0!important;
}
.licensingimg{
    margin-top: 10px;
}


.raccordion_righttext{
    position: absolute;
    right: 40px;
}

.button-down {
    border-radius: 50%;
    transition: all 0.2s linear;
    position: absolute;
    width: 5%;
    cursor: pointer;
    /* z-index: 999999999999999999 !important; */
    float: right;
    right: 0;
    top: 8px;
    background: #F6C315;
    padding: 18px;
  }
  
  .button-up {
    border-radius: 50%;
    transition: all 0.2s linear;
    position: absolute;
    width: 5%;
    cursor: pointer;
    /* z-index: 999999999999999999 !important; */
    float: right;
    right: 0;
    top: 8px;
    background: #F6C315;
    padding: 18px;
  }
  .button-down::after {
    content: "";
    position: absolute;
    left: 13px;
    /* z-index: 11; */
    display: block;
    width: 11px;
    height: 11px;
    border-top: 2px solid #ffffff;
    border-left: 2px solid #ffffff;
    top: 11px;
    transform: rotate(225deg);
    right: 0;
  }

  .button-up::after {
    content: "";
    position: absolute;
    left: 13px;
    z-index: 11;
    display: block;
    width: 11px;
    height: 11px;
    border-top: 2px solid #ffffff;
    border-left: 2px solid #ffffff;
    top: 15px;
    transform: rotate(406deg);
    right: 0;
  }
  .accordion_divcontainer h3{
      margin-bottom: 0;
  }
.accordion_divcontainer .button-down::after{
    left: 50px;
}
.accordion_divcontainer .button-up::after{
    left: 50px;
}
.accordian_maincontainer{
    margin-bottom: 0!important;
}
.accordion_divcontainer label{
    text-align: left!important;
font: normal normal normal 16px/20px Source Sans Pro!important;
letter-spacing: 0px!important;
color: #000000!important;
opacity: 1!important;
padding-left: 4px!important;
}
.accordion_divcontainer .timepara p{
    border-top: 1px solid #EEEEEE!important;
    margin: 0;
    padding: 12px;
}
.accordion_divcontainer .timepara p:first-child{
    border-top: 0px solid #EEEEEE!important;
}
.chat_box_text p{
    text-align: left;
    font: normal normal normal 15px/23px Source Sans Pro;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
}

@media only screen and (max-width: 767px) {
    .accordion_divcontainer .button-up::after {
      left: 17px !important;
    }
    .accordion_divcontainer .button-down::after{
        left: 17px;
    }
  }