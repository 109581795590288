.topBar {
  display: block;
  width: 100%;
  background:#779bcc 0% 0% no-repeat padding-box;
  /* background: #255eab 0% 0% no-repeat padding-box; */
  /* box-shadow: 0px 3px 6px #00000029; */
  opacity: 1;
  position: fixed;
  top: 0px;
  z-index: 99;
}
.topBar .Logo h1 {
  font: normal normal bold 27px/32px Source Sans Pro;
  letter-spacing: 5.4px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 0;
}
.Logo img {
  height: 55px;
}
.topBar .rightList li {
  display: inline-flex;
  width: auto;
  padding: 0px 16px;
}
.topBar .rightList li a span {
  font: normal normal normal 16px/32px Source Sans Pro;
  letter-spacing: 0px;
  color: #ffffff;
}
.topBar .btn.btnSign,
.topBar .btn.btnSign:hover {
  font: normal normal normal 16px/32px Source Sans Pro;
  letter-spacing: 0px;
  color: #ffffff;
  background: #ffca05 0% 0% no-repeat padding-box;
  border-radius: 2px;
  border-color: #ffca05;
  padding: 3px 38px;
}
.topBar .dropdown-item a{
  display: block;
  width: 100%;
}
.topBar .dropdown-item.active a, .topBar .dropdown-item:active a{
  color: #fff;
}
.topBar .dropdown-item.active, .topBar .dropdown-item:active {
  background-color: #3674c7;
}

@media screen and (max-width: 767px) {
  .topBar .rightList li {
    padding: 0px 10px;
  }
  .d-row-flex.topRow {
    width: auto !important;
  }
}
