td.inprogress{
    color: #FCA32F!important;
}
td.declined{
    color: #6D6D6D!important;
}
.filter_form{
    position: relative;
}
.width_data{
    width: 200px;
    display: block;
    word-break: break-word;
  }
.filter_modal{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 10px #00000029;
    border: 0.5px solid #F6F6F6;
    border-radius: 1px;
    width: 400px;
    height: 240px;
    padding: 20px 18px;
    text-align: left!important;
    position: absolute;
    right: 0;
    top: 46px;
    z-index: 99;
}
.filter_modal select{
    outline: none;
    box-shadow: none;
}
.select_input {
    position: relative;
  }
.select_input select:focus {
  box-shadow: none;
  border: 1px solid #cbcbcb;
}
.filter_modal label{
    font: normal normal normal 15px/17px Source Sans Pro;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
}
.select_input select{
    font: normal normal normal 12px/15px Source Sans Pro;
    letter-spacing: 0px;
    color: #000000;
    opacity: 0.5;
    -webkit-appearance: none!important;
    -moz-appearance: none!important;
    appearance: none!important;
    background-image: url(../../../Images/arrow_dropdown.svg)!important;
    background-repeat: no-repeat!important;
    background-position: 95% 51%!important;
    height:45px;
}
.select_input input{
    font: normal normal normal 12px/15px Source Sans Pro!important;
    letter-spacing: 0px!important;
    color: #000000!important;
    opacity: 0.5!important;
    -webkit-appearance: none!important;
    -moz-appearance: none!important;
    appearance: none!important;
}
.select_input input::placeholder{
    color: #000000!important;
    opacity: 0.5!important;
}
.filter_modal .form-group{
    margin-bottom: 8px;
}
.save_btn{
    margin-top: 10px;
    width: 136px;
    height: 39px;
}
.download_css{
    cursor: pointer;
}
.react-datepicker__input-container input{
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}
.cancel_btn.btn.btn-secondary{
    font:normal normal normal 14px/17px Source Sans Pro;
}
.input_date_width_table input{
    width:100px;
}
.calendar_img{
    max-width: 100%;
    width: 30px;
}
.filter_modal_user .react-datepicker-wrapper{
    width:100%;
}
@media screen and (max-width:576px){
    .filter_modal{
        min-width: 290px;
        width: auto;
        left: auto;
        height: 250px;
    }
    .right_picker .react-datepicker-popper[data-placement^="bottom"]{
        left: auto !important;
        right: 0;
    }
}