.module0_course.module_font {
  font-family: 'Signika', sans-serif !important;
}
.module0_course {
    overflow: hidden;
}
.module0_course .navigate_img{
    position: relative;
}
.module0_course .navigate_img .logo_module{
    position: absolute;
    /* content: ""; */
    top: 19px;
    left: 0px;
    right: 0px;
    margin: auto;
    width: 320px;
}
.module0_course .law_note {
    background: #414042;
    font-weight: normal;
    font-size: 23.18px;
    line-height: 28px;
    color: #ffffff;
    padding: 13px;
    width: 93%;
  }
.module0_course .navigate_img img{
    max-width: 100%;
}
.module0_course .navigate_img h1{
    font-size: 28.0853px;
    line-height: 33px;
    color: #FFFFFF;
    font-weight: 700;
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    top: 17%;
    text-align: center;
    background: #303233;
    width: 85%;
    padding: 11px 18px 15px;
}
.module0_course .navigate_img h1 span{
    display: block;
    font-weight: bold;
    font-size: 40.1219px;
    line-height: 47px;
    color: #FFFFFF;
}
.module0_course .main_content h2{
    font-weight: bold;
    font-size: 31px;
    line-height: 38px;
    text-transform: uppercase;
    color: #FFFFFF;
    background-image: url("../../../../Images/module0/bg1.png");
    background-repeat: no-repeat;
    padding: 4px 14px;
    background-size: auto;
}
.module0_course p{
    font-weight: normal;
    font-size: 25px;
    line-height: 31px;
    color: #414042; 
}
.module0_course .course_img img{
    max-width: 100%;
}
.module0_course h2.navigation_ins{
    background-image: url("../../../../Images/module0/bg2.png"); 
}
.module0_course .audio_video h4{
    font-weight: bold;
    font-size: 32.292px;
    line-height: 40px;
    color: #414042;
    text-transform: uppercase;
    background-image: url("../../../../Images/module0/audio.png"); 
    background-repeat: no-repeat;
    background-size: contain;
    padding: 3px 0px 0px 5%;
    margin-left: -10px;
    height:47px;
}
.module0_course .audio_video .left_space{
    padding: 3px 0px 0px 12%;
}
.module0_course .audio_video .auto_player{
    background-image: url("../../../../Images/module0/sample.png"); 
    background-repeat: no-repeat;
    padding: 70px;
    text-align: center;
    margin: 5px 0px 35px 0px;
    background-size: cover;
}
.module0_course .audio_video .auto_player img{
   height: 74px;
}
.module0_course .audio_video .video_img{
    position: relative;
    z-index: 9;
}
.module0_course .audio_video .video_img img{
    max-width: 100%;
}
.module0_course .audio_video .dropdown_img{
    height: 374px;
    align-items: center;
    display: flex;
    background-image: url("../../../../Images/module0/sample.png");
    background-repeat: no-repeat;
    background-size: cover;
    margin: 27% -96px 0px -68px;
    z-index: 1;
}
.module0_course .audio_video .dropdown_img img{
    width: 400px;
    margin: auto;
}
.module0_course .yellow_btn_module{
    background: #ffca05;
    border-radius: 2px;
    padding: 10px;
    font-weight:500;
    font-size: 25px;
    line-height: 30px;
    color: #FFFFFF;
    text-transform: uppercase;
    border-color: transparent;
    width: 60%;
    height: 74px;
}
.module0_course .exercise_img img{
    max-width: 100%;
}
.module0_course .arrow_nav{
    height: 50px;
    width: 50px;
}
.module0_course .tools_menu h5{
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    color: #1A4790;
}
.module0_course .tools_menu .tool_content h6{
    font-weight: bold;
    font-size: 25px;
    line-height: 31px;
    color: #1A4790;
}
.module0_course .tool_content .tool_user_detail{
    background: #1A4790;
    border-radius: 20px;
    position: relative;
    min-height: 200px;
    margin: 70px 0px 0px;
    text-align: center;
}
.module0_course .tool_content .tool_user_name{
    background: #F6C315;
    border-radius: 100px;
    font-weight: bold;
    font-size: 61.0855px;
    line-height: 75px;
    color: #FFFFFF;
    width: 136px;
    height: 136px;
    margin: auto;
    text-align: center;
    box-shadow: 0px 0px 0px 12px #fff;
    position: absolute;
    left: 0;
    right: 0;
    top: -80px;
    padding: 30px 0px;
}
.module0_course .tool_content ul {
    margin: 10px 20px 0px;
    padding: 90px 20px 50px;
}
.module0_course .tool_content ul li{
    list-style-type: none;
    font-weight: bold;
    font-size: 25px;
    line-height: 31px;
    color: #FFFFFF;
    margin-bottom: 10px;
    padding:10px;
    border-radius: 100px;
}
.module0_course .tool_content ul li:nth-child(odd){
    background: #0F306B;
}
.module0_course .tool_content ul li:nth-child(even){
    background: #00194C;
}
.module0_course .learn_material_div {
    position: relative;
    padding:100px 0px;
    background-image: url("../../../../Images/module0/sample.png");
    background-repeat: no-repeat;
    height: 250px;
    width: 100%;
    background-size: cover;
}
.module0_course .learn_material_div button{
    background: #F6C315;
    border-color: transparent;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    color: #FFFFFF;
    border-radius: 100px;
    padding: 8px 20px;
}
.module0_course .identity_method{
    position: relative;
    background: #1A4790;
    padding: 10px 10px;
}
.module0_course .identity_method h3{
    font-weight: bold;
    font-size: 31px;
    line-height: 38px;
    color: #414042;
    background-image: url("../../../../Images/module0/bg3.png");
    background-repeat: no-repeat;
    background-size: contain;
    text-transform: uppercase;
    margin-top: -28px;
    padding: 5px 10px;
}
.module0_course .identity_method h4{
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    color: #414042;
    background-image: url("../../../../Images/module0/bg4.png");
    background-repeat: no-repeat;
    background-size: 56% 100%;
    text-transform: uppercase;
    padding: 3px 4%;
}
.module0_course .border_img{
    max-width: 100%;
}

.module0_course .identity_method .identity_para{
    font-weight: bold;
    font-size: 25px;
    line-height: 31px;
    color: #FFFFFF;
}
.module0_course .identity_method .security_para{
    font-weight: normal;
    font-size: 25px;
    line-height: 31px;
    color: #FFFFFF;
}
.module0_course .identity_method .car_img img{
    max-width: 100%;
}
.module0_course .validate_span{
    display: block;
    font-weight: bold;
    font-size: 24.1905px;
    line-height: 30px;
    color: #414042;
}
.module0_course .typing_demo{
    border: 2px solid #F6C315;
    padding:20px;
    margin:20px 0px;
    background-image: url("../../../../Images/module0/sample.png"); 
    background-repeat: no-repeat;
    background-size: cover;
}
.module0_course .typing_demo p{
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    color: #414042;
    /* background-color: white; */
}
.module0_course .typing_textarea{
    border: 1px solid #414042;
    height: 110px;
    width:80%;
}
.module0_course .typing_demo span{
    background: #F6C315;
    font-weight: normal;
    font-size: 20px;
    line-height: 25px;
    color: #414042;
    width:80%;
    margin:15px 0px;
    text-align: center;
    padding:10px 0px;
}
.module0_course .process li{
    position: relative;
    font-weight: normal;
    font-size: 24.1905px;
    line-height: 30px;
    color: #414042;
    margin-bottom: 10px;
    list-style-type: none;
    padding:0px 0px 0px 33px;
}
.module0_course .process li:before{
    position: absolute;
    content: "";
    left:0px;
    top:4px;
    background-image: url("../../../../Images/module0/icon1.png");
    background-repeat: no-repeat;
    height: 19px;
    width:19px;
}
.module0_course .process_bg{
    position: relative;
    background-image: url("../../../../Images/module0/process.png");
    height: 400px;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 0px 25px;
}
.module0_course .process_bg ul{
    width:45%;
}
.module0_course .border_img{
    width:90%;
    margin:65px auto 30px;
}
.module0_course .sample_para_type p{
    font-weight: normal;
    font-size: 24.1905px;
    line-height: 30px;
    color: #414042;
}
.module0_course .sample_para_type .text-bold{
    font-weight: bold;
}
.module0_course .sample_para_type .next_click{
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    color: #414042;
    text-align: center;
}
.module0_course .readyto img{
    max-width:100%;
    position: relative;
}
.border_img_ready{
    position: absolute;
    bottom: 70px;
    width: 90%;
    margin: auto;
    left: 0;
    right: 0;
}

.learningMoreBtn {
    background-color: #f3f3f3 !important;
    min-width: 50%;
    border-radius: 5px !important;
    box-shadow: 0px 0px 2.5px grey;
    display: flex !important;
    justify-content: center;
    align-items: center;
    margin: auto;
}
.learningMoreBtn span{
    font-size: 25px !important;
    color: #255eab !important;
    opacity: 1 !important;
}

.learningMoreBtn img{
    width: 40px;
}

@media screen and (min-width:768px) and (max-width:1023px){
    .module0_course .navigate_img .logo_module{
        width:200px !important;
    }
    .module0_course .course_img{
        height: 282px;
        display: inline-flex;
        align-items: center;
        align-self: center;
    }
    .module0_course .border_width_mob img{
        max-width: 100%;
    }
    .module0_course .audio_video .left_space {
        padding: 3px 0px 0px 10%;
    }
    .module0_course .audio_video h4{
        background-size: 95% 100%;
        font-size: 23.292px;
        height: 44px;
        padding: 3px 0px 0px 7%;
    }
    .module0_course .audio_video .dropdown_img img{
        width: 200px;
    }
    .module0_course .tool_content .tool_user_name{
        height: 100px;
        width: 100px;
        top: -50px;
        line-height: 45px;
        font-size: 55px;
    }
    .module0_course h2.navigation_ins{
        background-size: 100% 100%;
    }
    .module0_course .identity_method h4{
        background-size: 83% 100%;
        padding: 3px 7%;
    }
    .module0_course .border_img{
        width: 100%;
    }
    .module0_course .process_bg{
        height: 480px;
        width: 100%;
        background-size: contain;
        background-position: bottom;
    }
    .module0_course .process_bg ul{
        width: 52%;
    }
    .module0_course .process_bg{
        height: 500px;
        background-position: bottom;
    }
}

@media screen and (min-width:577px) and (max-width:767px){
    .module0_course .identity_method h3{
        height:auto !important;
    }
}


@media screen and (max-width:767px){
    .module0_course .navigate_img .logo_module{
        width:100px;
        height:auto;
    }
    .module0_course .navigate_img img{
        height: 386px;
        width: 100%;
        object-fit: fill;
    }
    .module0_course .navigate_img h1{
        font-size: 17.0853px;
        line-height: 20px;
        padding: 8px 18px 6px;
        top: 17%;
    }
    .module0_course .navigate_img h1 span{
        font-size: 20.1219px;
        line-height: 27px;
    }
    .module0_course .main_content h2{
        padding: 4px 9px;
        font-size: 18px;
        line-height: 35px;
        background-size: 100% 100%;
        height: 42px;
    }
    .module0_course p{
        font-size: 18px;
        line-height: 28px;
    }
    .module0_course .border_width_mob img{
        max-width: 100%;
    }
    .module0_course .audio_video h4{
        font-size: 22.292px;
        line-height: 31px;
        background-size: 100% 100%;
        padding: 3px 0px 0px 9% !important;
        height: 32px;
    }
    .module0_course .audio_video .auto_player img{
        height: 50px;
        max-width: 100%;
    }
    .module0_course .audio_video .dropdown_img{
        height: 230px;
        margin: 0px;
    }
    .module0_course .audio_video .dropdown_img img{
        max-width: 100%;
        width: 200px;
    }
    .module0_course .audio_video .auto_player{
        padding: 50px 0px;
        margin: 5px 0px 35px 0px;
    }
    .module0_course .yellow_btn_module{
        font-size: 27px;
        line-height: 37px;
        width: 100%;
        height: 45px;
    }
    .module0_course .tools_menu h5{
        font-size: 24px;
        line-height: 32px;
    }
    .module0_course .tool_content ul{
        margin: 10px 0px 0px;
    }
    .module0_course .tool_content .tool_user_name{
        font-size: 56.0855px;
        line-height: 70px;
        width: 110px;
        height: 110px;
        top: -65px;
        padding: 21px 0px;
    }
    .module0_course .learn_material_div button{
        font-size: 24px;
        line-height: 37px;
    }
    .module0_course .identity_method h3{
        font-size: 21px;
        line-height: 27px;
        background-size: 100% 100%;
        padding: 9px 10px;
        height: 73px;
    }
    .module0_course .identity_method .identity_para{
        font-size: 21px;
        line-height: 27px;
    }
    .module0_course .identity_method h4 {
        font-size: 23px;
        line-height: 28px;
        background-size: 100% 90%;
        padding: 3px 7%;
    }
    .module0_course .identity_method .security_para{
        font-size: 18px;
        line-height: 28px;
    }
    .module0_course .border_img{
        width:100%;
    }
    .module0_course .process_text{
        font-size: 18.292px;
    }
    .module0_course .process li{
        font-size: 20.1905px;
        line-height: 28px;
    }
    .module0_course .process_bg ul{
        width: 100%;
    }
    .module0_course .process_bg{
        background-size: contain;
        background-position: bottom;
        height: auto;
    }
    .module0_course .sample_para_type p{
        font-size: 18.1905px;
        line-height: 27px;
    }
    .module0_course .sample_para_type .next_click{
        font-size: 20px;
        line-height: 30px;
    }
    .module0_course .readyto img{
        height: 400px;
        width: 100%;
    }
    .module0_course .readyto .border_img_ready{
        bottom: 40px;
    }
    .module0_course .readyto .border_img_ready img{
        height: auto;
    }
}


.noani{
    animation: none !important;
    transition: none !important;
}

/* all the chaneges are updated that are mention in the document. */