.box {
  /* border: 1px black solid; */
  position: relative;
  top: 55px;
}

.box iframe {
  width: 100%;
  height: 825px;
}

.module2_course .driving_section {
  position: relative;
  background-image: url("../../../../Images/module2/bg.png");
  background-repeat: no-repeat;
  height: 700px;
  background-position: bottom;
}

.module2_course .driving_section .drive_img {
  max-width: 100%;
  width: 750px;
  position: absolute;
  right: 13%;
  bottom: -15%;
  z-index: 0;
}

.module2_course .module_content_head_position {
  position: absolute;
  right: 5%;
  width: 352px;
  top: 15%;
  z-index: 0;
}

.module2_course .driving_section .student_logo {
  max-width: 100%;
  width: 150px;
  position: absolute;
  bottom: 25%;
  left: 16%;
  z-index: 9;
}

.module2_course .driving_section .main_logo {
  position: absolute;
  bottom: 4%;
  left: 5%;
}

.module2_course .driving_section .main_logo img {
  max-width: 100%;
  width: 250px;
}

.module2_course .driving_section h1 {
  font-weight: 800;
  font-size: 42.23px;
  line-height: 53px;
  color: #ffffff;
  text-transform: uppercase;
  padding-left: 25px;
}

.module2_course .driving_section h1 span {
  color: #f6c315;
  font-weight: 800;
}

.module2_course .driving_section p {
  font-weight: 800;
  font-size: 30.24px;
  line-height: 36px;
  color: #0a2b63;
  background: #ffffff;
  padding: 10px 0px 10px 33px;
  text-transform: uppercase;
}

.module2_course .module_drive_content {
  border: 1px solid #58595b;
  position: relative;
}

.module2_course .module_drive_content .module_drive_head {
  background: #0a2b63;
  border: 4px solid #ffffff;
  display: inline-block;
  position: absolute;
  left: 0;
  right: 0;
  margin: auto;
  text-align: center;
  width: 63%;
  top: -30px;
  border-radius: 15px;
  height: 63px;
}

.module2_course .module_drive_content .module_drive_head h3 {
  font-weight: 700;
  font-size: 44.35px;
  line-height: 53px;
  color: #ffffff;
  text-transform: uppercase;
  text-align: center !important;
}

.module2_course .module_drive_list li {
  position: relative;
  list-style-type: none;
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #414042;
  margin-bottom: 11px;
  padding: 0px 30px 0px 20px;
}

.module2_course .module_drive_list li:before {
  position: absolute;
  content: ".";
  color: #1a4790;
  font-size: 50px;
  left: 2px;
  top: -13px;
}

.module2_course .module_bg_overview {
  background-image: url("../../../../Images/module2/Group.png");
  background-repeat: no-repeat;
  height: 680px;
  background-position: bottom;
  width: 100%;
  background-size: 95% 70%;
}

.module2_course .overview_section_module3 {
  position: relative;
  background: #1a4790f2;
  height: 649px;
}

.module2_course .car_shop img {
  max-width: 100%;
  width: 455px;
}

.module2_course .module_overview_list li {
  position: relative;
  list-style-type: none;
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #fff;
  margin-bottom: 11px;
  padding: 0px 30px 0px 20px;
}

.module2_course .module_overview_list li:before {
  position: absolute;
  content: ".";
  color: #f6c315;
  font-size: 50px;
  left: 2px;
  top: -13px;
}

.module2_course .overview_tab .btn.btn_overviews {
  cursor: default;
  background: #0b2c66;
  font-weight: 600;
  font-size: 18.16px;
  line-height: 24px;
  color: #ffffff;
  margin: 10px 6px 10px;
  padding: 12px 2px;
  width: 150px;
}

.module2_course .module_drive_social .para_top {
  font-weight: normal;
  font-size: 30.24px;
  line-height: 36px;
  color: #414042;
}

.module2_course .module_drive_social .school_drive {
  text-align: right;
  width: 320px;
  float: right;
}

.module2_course .module_drive_social .school_drive img {
  max-width: 100%;
}

.module_drive_social .drive_rules {
  font-weight: 700;
  font-size: 24.19px;
  line-height: 29px;
  color: #414042;
}

.module2_course
  .module_drive_social
  .drive_rules_apply
  .drive_rules_apply_content {
  background: #414042;
  padding: 19px 16px 20px;
  border-radius: 20px;
  text-align: center;
  height: 100%;
  transition: all 0.5s ease-in-out;
}

.module2_course
  .module_drive_social
  .drive_rules_apply
  .drive_rules_apply_content:hover {
  background: #0a2b63;
}

.module2_course
  .module_drive_social
  .drive_rules_apply
  .drive_rules_apply_content
  h5 {
  font-weight: 600;
  font-size: 20.16px;
  line-height: 24px;
  color: #ffffff;
}

.module2_course
  .module_drive_social
  .drive_rules_apply
  .drive_rules_apply_content
  p {
  font-weight: normal;
  font-size: 16.13px;
  line-height: 19px;
  color: #ffffff;
  margin: 0px;
}

.module2_course
  .module_drive_social
  .module_drive_content
  .module_drive_head
  h3 {
  color: #f6c315;
  font-size: 34.27px;
  line-height: 41px;
}

.module_parts .module_drive_content {
  border: none;
}

.module_parts .module_drive_content .module_drive_head {
  top: -10%;
}

.module2_course .module_parts {
  background: #1a4790;
}

.module2_course .module_parts .module_drive_content .module_drive_head {
  background: #1a4790;
}

.module2_course .module_drive_social .module_drive_content .module_drive_head,
.module2_course .module_parts .module_drive_content .module_drive_head,
.module2_course .module_safe_driver .module_drive_content .module_drive_head {
  width: 92%;
  height: 51px;
  background: #1a4790;
  border-radius: 100px;
}

.module2_course .module_parts .module_drive_content:after {
  position: absolute;
  content: "";
  /* border: 1px solid #58595b; */
  width: 100%;
  height: 13px;
  top: 100%;
  z-index: -1;
}

.module2_course .module_parts .module_drive_content .module_drive_head h3 {
  color: #f6c315;
  font-size: 34.27px;
  line-height: 41px;
}

.module2_course .module_parts_content {
  text-align: center;
  background: #0b2c66;
  padding: 10px 10px;
  border-radius: 20px;
  height: 155px;
  margin: 10px 0px;
  transition: all 0.5s ease-in-out;
}

.module2_course .module_parts_content:hover {
  background: #1e1e1f;
  box-shadow: 0px 0px 10px 0px #191717;
}

.module2_course
  .module_safe_driver
  .module_drive_content
  .module_drive_head
  h3 {
  color: #f6c315;
  font-size: 34.27px;
  line-height: 41px;
}

.module2_course .module_parts_box_img {
  height: 85px;
  width: 85px;
  background: #f6c315;
  overflow: hidden;
  border-radius: 100px;
  text-align: center;
  margin: auto;
  padding: 12px;
}

.module2_course .module_parts_box_img img {
  max-width: 100%;
}

.module2_course .module_parts_box h5 {
  font-weight: 600;
  font-size: 20.16px;
  line-height: 24px;
  color: #ffffff;
  margin: 4px 0px 8px;
}

.module2_course .module_parts_box p {
  font-weight: normal;
  font-size: 20.16px;
  line-height: 24px;
  color: #ffffff;
  margin: 6px;
}

.module2_course .module_control_part .module_control_part_img {
  overflow: hidden;
}

.module2_course .module_control_part .module_control_part_img img {
  max-width: 100%;
}

.module2_course .module_control_part .module_control_part_content h4 {
  font-weight: 300;
  font-size: 30.24px;
  line-height: 36px;
  color: #f6c315;
}

.module2_course .module_control_part .module_control_part_content ul li {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #414042;
  position: relative;
  margin-bottom: 11px;
  padding: 0px 30px 0px 20px;
  list-style-type: none;
}

.module2_course .module_control_part .module_control_part_content ul li:before {
  position: absolute;
  content: ".";
  color: #1a4790;
  font-size: 50px;
  left: 2px;
  top: -13px;
}

.module2_course .module_control_part.module_drive_content {
  padding: 15px;
}

.module2_course .module_safe_driver_content {
  padding: 15px 20px;
}

.module2_course .module_safe_driver_content h3 {
  font-weight: 300;
  font-size: 30.24px;
  line-height: 36px;
  color: #f6c315;
  top: 0;
  left: -16px;
}

.module2_course .module_safe_driver_img.text-center {
  position: relative;
}

.module2_course .module_safety_image {
  width: 243px;
  overflow: hidden;
  margin: auto;
  position: absolute;
  bottom: -21%;
  left: -21px;
  right: 0;
}

.module2_course .module_safety_image img {
  max-width: 100%;
}

/* .module2_course .module_safe_driver .module_drive_content {
  border-bottom: none;
} */

.module2_course .school_drive {
  margin-top: 10px;
}

.module2_course .school_drive img {
  max-width: 100%;
}

.module2_course .module_safe_driver_content .school_drive img {
  max-width: 100%;
}

.module2_course .module_safe_driver_content .driver_characteristics h6 {
  font-weight: bold;
  font-size: 24.19px;
  line-height: 29px;
  color: #414042;
  margin-bottom: 3px;
}

.module2_course .module_safe_driver_content .driver_characteristics p {
  font-weight: normal;
  font-size: 24.19px;
  line-height: 29px;
  color: #414042;
}

.module_safe_driver_img img {
  max-width: 100%;
}

.module2_course .main_logo {
  transform: translatey(0px);
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-15px);
  }

  100% {
    transform: translatey(0px);
  }
}

.safe_driver_para {
  font-weight: normal;
  font-size: 20.24px;
  line-height: 30px;
  color: #414042;
  margin: 50px 21px 20px;
}

@media screen and (max-width: 425px) {
  .box {
    position: relative;
    top: 60px;
  }

  .box iframe {
    width: 100%;
    height: 450px;
  }
}

@media screen and (min-width: 426px) and (max-width: 767px) {
  .box iframe {
    width: 100%;
    height: 550px;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1300px) {
  .module2_course .module_bg_overview {
    background-size: 100% 66%;
  }

  .driving_section .student_logo {
    bottom: 30%;
    left: 10%;
  }

  .driving_section .drive_img {
    width: 781px;
    right: 10%;
    bottom: -11%;
  }

  .module2_course .module_parts_content {
    height: 190px;
  }
}

@media screen and (max-width: 767px) {
  .module2_course .module_content_head_position {
    right: auto;
    width: 95%;
    margin: 0 auto;
    top: 3%;
    left: 5%;
  }

  .module2_course .driving_section h1 {
    padding-left: 0px;
    font-size: 24.23px;
    line-height: 35px;
  }

  .module2_course .driving_section p {
    padding: 4px 14px 4px 6px;
    display: inline-block;
    font-size: 20.24px;
    line-height: 25px;
  }

  .module2_course .driving_section .student_logo {
    width: 109px;
    top: 31%;
    left: 2%;
  }

  .module2_course .driving_section .drive_img {
    width: 703px;
    right: 1%;
    bottom: 21%;
  }

  .module2_course .driving_section .main_logo {
    bottom: 17%;
    left: 2%;
  }

  .module2_course .driving_section .main_logo img {
    width: 200px;
  }

  .module2_course .module_drive_content .module_drive_head h3 {
    font-size: 30.35px;
    line-height: 42px;
  }

  .module2_course .module_drive_list li {
    font-size: 15.19px;
    line-height: 26px;
    margin-bottom: 4px;
  }

  .module2_course .module_drive_content.module_objective {
    margin-top: -27%;
  }

  .module2_course .module_drive_content .module_drive_head {
    width: 89%;
    height: 50px;
    top: -25px;
  }

  .module2_course .module_overview_list li {
    padding: 0px 10px 0px 20px;
    font-size: 16.19px;
    line-height: 26px;
  }

  .module2_course .module_bg_overview,
  .module2_course .overview_section_module3 {
    height: auto;
  }

  .module2_course .overview_tab .btn.btn_overviews {
    font-size: 14px;
    margin: 15px auto;
    padding: 7px 0px;
    width: 90%;
    display: block;
  }

  .module2_course .module_drive_social .module_drive_content .module_drive_head,
  .module2_course .module_parts .module_drive_content .module_drive_head {
    width: 97%;
    height: 67px;
    top: -44px;
    padding: 0px 8px;
  }

  .module_drive_social .drive_rules {
    font-size: 16.19px;
    line-height: 25px;
  }

  .module2_course
    .module_drive_social
    .drive_rules_apply
    .drive_rules_apply_content
    h5 {
    font-size: 16.16px;
    line-height: 21px;
  }

  .module2_course
    .module_drive_social
    .module_drive_content
    .module_drive_head
    h3,
  .module2_course .module_parts .module_drive_content .module_drive_head h3 {
    font-size: 15px;
    line-height: 23px;
    padding-top: 5px;
  }

  .module2_course .module_drive_social .para_top {
    font-size: 16.24px;
    line-height: 26px;
  }

  .module2_course .module_drive_social .drive_rules_apply .col-12 {
    margin-bottom: 10px;
  }

  .module2_course
    .module_drive_social
    .drive_rules_apply
    .drive_rules_apply_content {
    margin: 0px 12px;
  }

  .module2_course .module_parts_box h5,
  .module2_course .module_parts_box p {
    font-size: 16.16px;
    line-height: 21px;
  }

  .module2_course .module_control_part .module_control_part_content h4 {
    font-size: 23.24px;
    line-height: 33px;
  }

  .module2_course .module_control_part .module_control_part_content ul li {
    font-size: 16.19px;
    line-height: 26px;
    margin-bottom: 7px;
  }

  .module2_course .module_safe_driver_content .driver_characteristics p {
    font-size: 17.19px;
    line-height: 27px;
  }

  .module2_course .module_safe_driver_content .driver_characteristics h6 {
    font-size: 21.19px;
    line-height: 25px;
  }

  .module2_course .module_control_part .module_control_part_img {
    width: auto;
    overflow: hidden;
  }

  .module2_course .module_safety_image {
    bottom: -27%;
    width: 129px;
  }

  .module2_course .module_safe_driver .module_drive_content .module_drive_head {
    width: 92%;
    height: 82px;
    background: #1a4790;
    border-radius: 100px;
  }

  .module2_course
    .module_safe_driver
    .module_drive_content
    .module_drive_head
    h3 {
    color: #f6c315;
    font-size: 21px;
    line-height: 25px;
    padding: 9px;
  }

  .module2_course .module_drive_social .school_drive {
    width: 100%;
    float: none;
  }

  .safe_driver_para {
    font-size: 18px;
    line-height: 30px;
    margin: 70px 21px 20px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .box iframe {
    width: 100%;
    height: 650px;
  }

  .module2_course .driving_section {
    height: 600px;
  }

  .module2_course .module_content_head_position {
    right: 5%;
    top: 13%;
    width: 255px;
  }

  .module2_course .driving_section h1 {
    font-size: 28.23px;
    line-height: 38px;
    padding-left: 25px;
  }

  .module2_course .driving_section p {
    font-size: 27.24px;
    line-height: 36px;
    padding: 10px 0px 10px 51px;
  }

  .module2_course .driving_section .student_logo {
    width: 110px;
    left: 9%;
    bottom: 37%;
  }

  .module2_course .module_drive_list li {
    font-size: 18.19px;
    line-height: 29px;
  }

  .module2_course .module_overview_list li {
    font-size: 18.19px;
    line-height: 29px;
  }

  .module2_course .driving_section .drive_img {
    width: 540px;
    right: 11%;
    bottom: 3%;
  }

  .module2_course .module_drive_content .module_drive_head {
    width: 77%;
  }

  .module2_course .overview_tab .btn.btn_overviews {
    margin: -7px 2px 10px;
    padding: 12px 0px;
    width: 95px;
    font-size: 12px;
  }

  .module2_course .module_bg_overview {
    height: 750px;
  }

  .module2_course .overview_section_module3 {
    height: 730px;
  }

  .module2_course .module_drive_social .module_drive_content .module_drive_head,
  .module2_course .module_parts .module_drive_content .module_drive_head {
    width: 94%;
  }

  .module2_course
    .module_drive_social
    .drive_rules_apply
    .drive_rules_apply_content {
    padding: 19px 9px 20px;
  }

  .module2_course .module_parts_content {
    height: 171px;
  }

  .module2_course .module_control_part .module_control_part_img {
    width: auto;
  }

  .module2_course .module_control_part .module_control_part_content h4 {
    font-size: 18px;
  }

  .module2_course
    .module_drive_social
    .module_drive_content
    .module_drive_head
    h3 {
    font-size: 25.27px;
    line-height: 35px;
  }

  .module2_course .module_parts .module_drive_content .module_drive_head h3 {
    font-size: 25.27px;
    line-height: 35px;
  }

  .module2_course .module_parts .module_drive_content .module_drive_head {
    height: 80px;
  }

  .module2_course .module_drive_social .school_drive {
    width: auto;
  }

  .module2_course .module_drive_social .para_top {
    font-size: 22.24px;
    line-height: 31px;
  }

  .module2_course .module_parts_box {
    margin-top: 20px;
  }

  .module2_course .module_parts_box p,
  .module2_course .module_parts_box h5 {
    font-size: 16.16px;
    line-height: 23px;
  }

  .module2_course .module_control_part .module_control_part_content ul li {
    font-size: 18.19px;
    line-height: 24px;
  }

  .module2_course .module_safe_driver_content .driver_characteristics p {
    font-size: 20.19px;
    line-height: 24px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .box iframe {
    width: 100%;
    height: 650px;
  }

  .module2_course .module_drive_social .module_drive_content .module_drive_head,
  .module2_course .module_parts .module_drive_content .module_drive_head {
    width: 90%;
  }

  .module2_course .module_parts .module_drive_content .module_drive_head {
    height: 90px;
  }

  .module2_course .module_parts .module_parts_box {
    margin-top: 50px;
  }

  .module2_course .driving_section {
    height: 596px;
  }

  .module2_course .module_parts_content {
    height: 180px;
  }

  .module2_course .driving_section h1 {
    font-size: 25.23px;
    line-height: 36px;
  }

  .module2_course .module_content_head_position {
    right: 7%;
    width: 250px;
    top: 9%;
  }

  .module2_course .driving_section .drive_img {
    width: 640px;
    right: 10%;
    bottom: -5%;
  }

  .module2_course .driving_section .student_logo {
    width: 130px;
    bottom: 35%;
    left: 15%;
  }

  .module2_course .overview_tab .btn.btn_overviews {
    font-size: 15.16px;
    width: 125px;
  }

  .module2_course .module_control_part .module_control_part_img {
    width: auto;
  }

  .module2_course .overview_section_module3 {
    height: 730px;
  }

  .module2_course .module_bg_overview {
    height: 750px;
  }
}

/* new changes */

.radial-chart {
  max-height: 570px;
}

.radial-chart h3 {
  top: 50px;
  left: 25px;
  font-size: large;
  font-weight: bold;
}

.swal-icon--custom {
  width: 80px;
  height: 80px;
}

.svg-div svg{
  top: -115px !important;
}

.module2_course .module_safe_driver_content img {
  position: absolute;
  top: 60%;
  left: 37%;
  width: 250px;
}

.h5p-content-note {
  color: red;
  font-size: 22px;
  font-weight: 600;
}